<template>
  <svg
    id="CustomIconOpenPayment"
    data-name="IconOpenPayment"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.07 16.05"
  >
    <g id="Grupo_1306" data-name="Grupo 1306">
      <path
        id="payments_FILL0_wght400_GRAD0_opsz48"
        data-name="payments FILL0 wght400 GRAD0 opsz48"
        class="cls-1"
        d="m12.54,9.53c-.8.01-1.57-.3-2.13-.88-.57-.56-.89-1.33-.88-2.13-.01-.8.3-1.57.88-2.13.56-.57,1.33-.89,2.13-.88.8-.01,1.57.3,2.13.88.57.56.89,1.33.88,2.13.01.8-.3,1.57-.88,2.13-.56.57-1.33.89-2.13.88Zm-8.02,3.51c-.4,0-.78-.15-1.06-.44-.29-.28-.45-.66-.44-1.06V1.5c0-.4.15-.78.44-1.06C3.73.15,4.11,0,4.51,0h16.05c.4,0,.78.15,1.06.44.29.28.45.66.44,1.06v10.03c0,.4-.15.78-.44,1.06-.28.29-.66.45-1.06.44H4.51Zm2.51-1.5h11.03c-.01-.67.25-1.31.73-1.78.47-.48,1.11-.74,1.78-.73v-5.02c-.67.01-1.31-.25-1.78-.73-.48-.47-.74-1.11-.73-1.78H7.02c.01.67-.25,1.31-.73,1.78-.47.48-1.11.74-1.78.73v5.02c.67-.01,1.31.25,1.78.73.48.47.74,1.11.73,1.78h0Zm12.04,4.51H1.5c-.4,0-.78-.15-1.06-.44C.15,15.33,0,14.95,0,14.54V3.01h1.5v11.53h17.56v1.51Zm-14.54-4.51h0Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconOpenPayment",
};
</script>

<style scoped>
#CustomIconOpenPayment {
  fill: currentColor;
}
.cls-1 {
  stroke-width: 0px;
}
</style>

<template>
  <svg
    version="1.1"
    id="CustomIconBalance"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 19.8 18.8"
    style="enable-background: new 0 0 19.8 18.8"
    xml:space="preserve"
  >
    <path
      id="balance_FILL0_wght400_GRAD0_opsz24"
      class="st39"
      d="M0,18.8v-2h8.9V5.8C8.5,5.6,8.1,5.4,7.8,5.1C7.5,4.8,7.2,4.4,7.1,4H4
	l3,6.9c0,0.8-0.4,1.6-1,2.1c-0.7,0.6-1.6,0.9-2.4,0.9c-0.9,0-1.8-0.3-2.4-0.9c-0.6-0.5-1-1.3-1-2.1L3,4H1V2h6.1
	c0.2-0.6,0.6-1.1,1.1-1.4C8.7,0.2,9.3,0,9.9,0c0.6,0,1.2,0.2,1.7,0.6c0.5,0.4,0.9,0.8,1.1,1.4h6.1v2h-2l3,6.9c0,0.8-0.4,1.6-1,2.1
	c-0.7,0.6-1.6,0.9-2.4,0.9c-0.9,0-1.8-0.3-2.4-0.9c-0.6-0.5-1-1.3-1-2.1l3-6.9h-3.1c-0.1,0.4-0.4,0.8-0.7,1.1
	c-0.3,0.3-0.7,0.5-1.1,0.7v11.1h8.9v2L0,18.8z M14.5,10.9h3.7l-1.9-4.3L14.5,10.9z M1.6,10.9h3.7L3.5,6.6L1.6,10.9z M9.9,4
	c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C10.4,2.1,10.2,2,9.9,2C9.6,2,9.4,2.1,9.2,2.3
	C9,2.5,8.9,2.7,8.9,3c0,0.3,0.1,0.5,0.3,0.7C9.4,3.9,9.6,4,9.9,4L9.9,4z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBalance",
};
</script>

<style scoped>
.st0 {
  fill: #4d4d4d;
}
.st1 {
  fill: #ffffff;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #4ac1e0;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00ccff;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #155dff;
}
.st5 {
  fill: #ff0000;
}
.st6 {
  fill: #00ff00;
}
.st7 {
  fill: #155dff;
}
.st8 {
  fill: #aa0c18;
  stroke: #aa0c18;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #00e2e2;
  stroke: #00e2e2;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #006837;
  stroke: #006837;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #fce906;
  stroke: #fce906;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st12 {
  fill: #ff0000;
  stroke: #ff0000;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #00cc3e;
}
.st14 {
  fill: #fce906;
}
.st15 {
  fill: #880088;
}
.st16 {
  fill: #00e2e2;
}
.st17 {
  fill: #aa0c18;
}
.st18 {
  fill: #f7931e;
}
.st19 {
  fill: #006837;
}
.st20 {
  fill: #808080;
}
.st21 {
  fill: #f2f2f2;
}
.st22 {
  fill: #707070;
}
.st23 {
  fill: #fbecdd;
}
.st24 {
  fill: #b04d12;
}
.st25 {
  fill: #fffaec;
}
.st26 {
  fill: #eac767;
}
.st27 {
  fill: #e9fdf2;
}
.st28 {
  fill: #1b6d49;
}
.st29 {
  fill: #d1ffe5;
}
.st30 {
  fill: #42e69e;
}
.st31 {
  fill: #ffdedc;
}
.st32 {
  fill: #e60c00;
}
.st33 {
  fill: #fff1f1;
}
.st34 {
  fill: #a72821;
}
.st35 {
  fill: #ff7e7e;
}
.st36 {
  fill: #59d0f8;
}
.st37 {
  fill: #0066ff;
}
.st38 {
  fill: #004665;
}
.st39 {
  fill: #ff5f00;
}
.st40 {
  fill: none;
  stroke: #e6e6e6;
  stroke-miterlimit: 10;
}
</style>

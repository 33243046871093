<template>
  <svg
    version="1.1"
    id="CustomIconRefile"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.1 21.6"
  >
    <path
      id="redo_FILL0_wght400_GRAD0_opsz24"
      class="st2"
      d="M8.5,21.6c-2.2,0-4.4-0.8-6-2.3C0.9,17.9,0,15.9,0,13.7 C0,11.5,0.9,9.5,2.5,8c1.6-1.5,3.8-2.3,6-2.3h9.1L13.9,2l2-2l7.2,7.2l-7.2,7.2l-2-2l3.8-3.8H8.5c-1.4,0-2.9,0.5-3.9,1.4 c-1.1,0.9-1.7,2.2-1.7,3.6c0,1.4,0.6,2.7,1.7,3.6c1.1,1,2.5,1.5,3.9,1.4h10.2v2.9L8.5,21.6z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRefile",
};
</script>

<style scoped>
#CustomIconRefile {
  fill: currentColor;
}
</style>

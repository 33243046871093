import axios from "@/plugins/axios";

const state = () => ({
  items: [],
  billingCompanies: [],
  roles: [],
  typeUser: [],
  memberships: [],
  socialNetworks: [],
  currentUser: {},
  selectedUser: undefined,
  listUsers: [],
  pivoteRequest: null,
});

const mutations = {
  setUsers(state, users) {
    state.items = users;
  },

  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = [];
    if (billingCompanies.length > 0) {
      billingCompanies.map((bill) => {
        state.billingCompanies.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },

  setRoles(state, rols) {
    state.roles = [];
    rols.data.map((role) => {
      if (role.name !== "Health Professional" && role.name !== "Patient") {
        state.roles.push({
          id: role.id,
          name: role.name,
        });
      }
    });
  },
  setTypeUser(state, type) {
    state.typeUser = type;
  },

  setsocialNetworks(state, socialNetworks) {
    state.socialNetworks = socialNetworks;
  },

  setSelectedUser(state, selectedUser) {
    state.selectedUser = selectedUser;
  },

  setListUsers(state, listUsers) {
    state.listUsers = listUsers;
  },

  setCurrentUser(state, user) {
    state.currentUser = user;
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  setSelectedUser({ commit }, selectedUser) {
    commit("setSelectedUser", selectedUser);
  },

  getUserBySsn(_, ssnUser) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/${ssnUser}/get-by-ssn`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getUserSearch(_, email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/search?email=${email}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getUsers(_, data) {
    let query = data.query ? data.query : "";
    const url = `/user/get-all-server?query=${query}&itemsPerPage=${data.itemsPerPage}&sortDesc=${data.sortDesc}&page=${data.page}&sortBy=${data.sortBy}${data.searchOn}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getUserById({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/${userId}`)
        .then((result) => {
          commit("setCurrentUser", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company")
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getSocialNetwork({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/social-networks/get-list")
        .then((result) => {
          commit("setsocialNetworks", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getRoles({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = data ? `/roles?billing_company_id=${data}` : "/roles";

      axios
        .get(url)
        .then((result) => {
          commit("setRoles", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getUserTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/types/")
        .then((result) => {
          resolve(result.data);
          commit("setTypeUser", result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/user/get-list?billing_company_id=${data.billing_company_id}&authorization=${data.authorization}`
        )
        .then((result) => {
          commit("setListUsers", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  saveUser(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateUser(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/user/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateSocialMedias(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/social-medias/${data.user_id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getAuditsByUserId(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/audit-all-by-user/", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getAuditById(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/audit-one/", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getHistoryById(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/${data.entity}/${data.id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  updateStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/${data.id}/change-status`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  assignRole(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/permission/assign-role-user/${data.userId}/${data.roleId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  removeRole(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/permission/remove-role-user/${data.userId}/${data.roleId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  searchSSNHealth(_, ssn) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/${ssn}/get-by-ssn`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  searchSSN(_, ssn) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/${ssn}/get-by-ssn`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <svg
    version="1.1"
    id="CustomIconTracking"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 14.5 14.8"
    style="enable-background: new 0 0 14.5 14.8"
    xml:space="preserve"
  >
    <g>
      <polygon
        class="st0"
        points="13.6,8.1 9.2,12.5 7,10.3 6.2,11.1 9.2,14.1 14.4,8.9 	"
      />
      <path
        class="st0"
        d="M1.1,2.6h1.1v1.7h8.9V2.6h1.1v3.8h1.1V2.6c0-0.3-0.1-0.6-0.3-0.8s-0.5-0.3-0.8-0.3H8.7C8.4,0.6,7.6,0,6.7,0
		S5,0.6,4.7,1.5H1.1c-0.3,0-0.6,0.1-0.8,0.3S0,2.3,0,2.6v11.1c0,0.3,0.1,0.6,0.3,0.8s0.5,0.3,0.8,0.3h4.6v-1.1H1.1V2.6z M6.7,1.1
		C6.7,1.1,6.7,1.1,6.7,1.1C6.7,1.1,6.7,1.1,6.7,1.1c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1s-1-0.4-1-1S6.2,1.1,6.7,1.1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTracking",
};
</script>

<style scoped>
#CustomIconTracking {
  fill: currentColor;
}
</style>

export default {
  // Dar formato desde un input
  formatInput(event) {
    /* eslint-disable */
    if (event.target.value.length === 14) {
      return (event.target.value = event.target.value.slice(
        0,
        event.target.value.length - 1
      ));
    }

    if (event.target.value.length < 14) {
      let text = event.target.value;
      var reem = text
        .toString()
        .toString()
        .replace(/[^a-zA-Z0-9]/g, "");
      if (reem.length < 3 && event.key == "Backspace") {
        if (reem.length == 2) {
          reem = "0" + reem;
        }
      } else {
        if (reem.substring(0, 1) == "0") {
          let subNum = reem.substring(1, reem.length);
          reem = subNum;
          if (subNum.length > 4 && subNum.substring(0, 2) == "00") {
            let subNumber = subNum.substring(2, subNum.length);
            reem = subNumber;
          }
        }
      }

      var coma =
        reem.substring(0, reem.length - 2) +
        "," +
        reem.substring(reem.length - 2, reem.length);
      event.target.value = coma.toString().replace(/\d(?=(\d{3})+\,)/g, "$&.");

      if (event.target.value != null) {
        for (let i = 0; i < event.target.value.length; i++) {
          if (event.target.value.indexOf(",,") != -1) {
            event.target.value = event.target.value.split(",,").join(",");
          }
        }
      }

      if (event.target.value.length < 4) {
        event.target.value = "0,00";
      }

      return event.target.value;
    }
  },

  // Dar formato desde un input --> (USA format) "$100,000,000.22"
  formatInputUsa(event) {
    const text = event.target.value;
    const sign = text.indexOf("-");
    const typedPointOrComma =
      text.charAt(text.length - 1) === "." ||
      text.charAt(text.length - 1) === ",";

    let reem = text.replace(/[^0-9]/g, "");
    reem =
      (sign !== -1 || typedPointOrComma) && reem.length === 3
        ? "0" + reem
        : sign !== -1 && reem.length === 2
        ? "00" + reem
        : sign !== -1 && reem.length === 1
        ? "000" + reem
        : reem;

    const isPositive =
      (sign === 0 && text.charAt(text.length - 1) === "-") ||
      reem === "0000" ||
      reem === "000" ||
      reem === "00" ||
      reem === "0";

    if (reem.length < 3 && event.key == "Backspace") {
      if (reem.length == 2) reem = "0" + reem;
    } else {
      if (reem.substring(0, 1) == "0") {
        let subNum = reem.substring(1, reem.length);
        reem = subNum;
        if (subNum.length > 4 && subNum.substring(0, 2) == "00") {
          let subNumber = subNum.substring(2, subNum.length);
          reem = subNumber;
        }
      }
    }

    let point =
      reem.substring(0, reem.length - 2) +
      "." +
      reem.substring(reem.length - 2, reem.length);
    event.target.value = point.replace(/\d(?=(\d{3})+\.)/g, "$&,");

    if (event.target.value != null) {
      for (let i = 0; i < event.target.value.length; i++) {
        if (event.target.value.indexOf("..") != -1) {
          event.target.value = event.target.value.split("..").join(".");
        }
      }
    }

    if (event.target.value.length < 4) event.target.value = "0.00";

    if (sign !== -1 && !isPositive) return "-$" + event.target.value;
    else return "$" + event.target.value;
  },

  formatInputUsaValue(value) {
    let text = value;
    if (text.length === 1)
      text = `$0.00${text}`
    const sign = text.indexOf('-');
    const typedPointOrComma = 
      text.charAt(text.length - 1) === '.' || 
      text.charAt(text.length - 1) === ',';

    let reem = text.replace(/[^0-9]/g, "");
    reem = (sign !== -1 || typedPointOrComma) && reem.length === 3 
      ? '0' + reem 
      : sign !== -1 && reem.length === 2 
        ? '00' + reem
        : sign !== -1 && reem.length === 1 
          ? '000' + reem
          : reem;

    const isPositive = 
      (sign === 0 && text.charAt(text.length - 1) === '-');
    
    if (reem.length < 3) 
      reem = '0' + reem;
    else {
      if (reem.substring(0, 1) === '0') {
        let subNum = reem.substring(1, reem.length);
        reem = subNum;
        if (subNum.length > 4 && subNum.substring(0, 2) == '00') {
          let subNumber = subNum.substring(2, subNum.length);
          reem = subNumber;
        }
      }
    }

    let point = reem.substring(0, reem.length - 2) + "." + reem.substring(reem.length - 2, reem.length);
    text = point.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    
    if (text.length === 3)
      text = '0' + text;

    if (text.length < 3) 
      text = '0.00';

    if (sign !== -1 && !isPositive)
      return '-$' + text;
    else 
      return '$' + text;
  },

  // Dar formato desde un input --> (USA format) "100,000,000.22"
  formatAmountUsa(event) {
    /* eslint-disable */
    if (event.target.value.length === 14) {
      return (event.target.value = event.target.value.slice(
        0,
        event.target.value.length - 1
      ));
    }

    if (event.target.value.length < 14) {
      let text = event.target.value;
      var reem = text
        .toString()
        .toString()
        .replace(/[^a-zA-Z0-9]/g, "");
      if (reem.length < 3 && event.key == "Backspace") {
        if (reem.length == 2) {
          reem = "0" + reem;
        }
      } else {
        if (reem.substring(0, 1) == "0") {
          let subNum = reem.substring(1, reem.length);
          reem = subNum;
          if (subNum.length > 4 && subNum.substring(0, 2) == "00") {
            let subNumber = subNum.substring(2, subNum.length);
            reem = subNumber;
          }
        }
      }

      var point =
        reem.substring(0, reem.length - 2) +
        "." +
        reem.substring(reem.length - 2, reem.length);
      event.target.value = point.replace(/\d(?=(\d{3})+\.)/g, "$&,");

      if (event.target.value != null) {
        for (let i = 0; i < event.target.value.length; i++) {
          if (event.target.value.indexOf("..") != -1) {
            event.target.value = event.target.value.split("..").join(".");
          }
        }
      }

      if (event.target.value.length < 4) {
        event.target.value = "0.00";
      }

      return event.target.value;
    }
  },

  // "100.000.000,22" || "100,000,000.22" --> "100000000.22"
  formatDirect(amount) {
    var newstring = amount.split(".").join("");
    var newa = newstring.split(",").join("");
    let newamount =
      newa.substring(0, newa.length - 2) +
      "." +
      newa.substring(newa.length - 2, newa.length);
    return newamount;
  },

  // 100000000.22 || "100000000.22" --> "100.000.000,22"
  intToStringFormat(i) {
    let n = parseFloat(i);
    return Number(n).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  // (USA format): 100000000.22 || "100000000.22" --> "100,000,000.22"
  intToStringFormatUsa(i) {
    let n = parseFloat(i);
    return Number(n).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  formatNumber(number) {
    // xxx-xxx-xxxx
    if (number.length === 12) {
      return number;
    } else if (number.length > 3 && number.length < 7) {
      return number.slice(0, 3) + "-" + number.slice(3);
    } else if (number.length >= 7) {
      return (
        number.slice(0, 3) + "-" + number.slice(3, 6) + "-" + number.slice(6)
      );
    } else {
      return number;
    }
  },
};

<!-- IconPayments -->
<template>
  <svg
    version="1.1"
    id="CustomIconPayments"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.1 16.1"
    style="enable-background: new 0 0 22.1 16.1"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M1.5,3H0v11.8c0,0.7,0.6,1.3,1.3,1.3h17.8v-1.6H1.5V3z"
      />
      <path
        class="st0"
        d="M20.7,0.2h-0.3h-16H4c-0.6,0-1.2,0.5-1.2,1.2v0.3v10V12c0,0.6,0.5,1.2,1.2,1.2h0.3h16.1h0.2
		c0.6,0,1.2-0.5,1.2-1.2v-0.3v-10V1.4C21.8,0.7,21.3,0.2,20.7,0.2z M18.5,9.9c-0.5,0.5-0.7,1.1-0.7,1.8h-11c0-0.6-0.2-1.3-0.7-1.8
		S5,9.2,4.3,9.2v-5C5,4.2,5.6,4,6.1,3.5s0.7-1.1,0.7-1.8h11.1c0,0.7,0.2,1.3,0.7,1.8s1.1,0.7,1.8,0.7v5C19.7,9.2,19,9.5,18.5,9.9z"
      />
      <path
        class="st0"
        d="M12.5,3.5c-0.8,0-1.5,0.3-2.1,0.9C9.8,4.9,9.5,5.7,9.5,6.5c0,0.8,0.3,1.6,0.9,2.1c0.5,0.6,1.3,0.9,2.1,0.9
		s1.6-0.3,2.1-0.9c0.6-0.5,0.9-1.3,0.9-2.1c0-0.8-0.3-1.5-0.9-2.1C14.1,3.8,13.3,3.5,12.5,3.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPayments",
};
</script>

<style scoped>
.st0 {
  fill: #42e69e;
}
</style>

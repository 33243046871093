import axios from "@/plugins/axios";

const state = () => ({});

const mutations = {};

const actions = {
  getRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get("/permission/roles-permissions")
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getRoleById(_, role) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/permission/role/${role}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getPermissionsByRole(_, role) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/permission/permissions-by-role/${role}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getPermissionsChange() {
    return new Promise((resolve, reject) => {
      axios
        .get("/permission/roles-permissions")
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  assignPermission(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `permission/assign-permissions-role/${data.roleId}/${data.permissionId}`
        )
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  },

  assignAllPermission(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/permission/assign-permissions-role/${data.roleId}`,
          data.permissions
        )
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  },

  removePermission(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `permission/remove-permission-role/${data.roleId}/${data.permissionId}`
        )
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  },

  removeAllPermission(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/permission/remove-permissions-role/${data.roleId}`,
          data.permissions
        )
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <svg
    version="1.1"
    id="CustomIconClaimNotProccess"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="2 2 40 40"
    style="enable-background: new 0 0 50 50"
    xml:space="preserve"
  >
    <g id="Grupo_4394" transform="translate(-60.421 -58.353)">
      <path
        id="Trazado_4005"
        class="st0"
        d="M76.1,83.7v-3.1c4.2,0,8.9,0,13.1,0v3.1L76.1,83.7z"
      />
      <path
        id="Trazado_4006"
        class="st0"
        d="M87.6,93.7H72.8c-0.1-0.4-0.1-25.9,0-26.4h11.6c0,0.2,0,0.4,0,0.6c0,2.3,0,4.6,0,7v0.7h8.2
		V89l1,1l1.9-2c0-4.7,0-9.4,0-14.1c0-0.3-0.1-0.5-0.3-0.7c-3-3-6-6-9-8.9c-0.2-0.2-0.4-0.3-0.7-0.3c-4.4,0-8.8,0-13.1,0
		c-0.7,0-1.3,0.3-1.8,0.7c-0.7,0.6-1.2,1.6-1.1,2.5c0,4.4,0,8.8,0,13.1l0,0c0,4.4,0,8.8,0,13.2c0,1.8,1.4,3.3,3.2,3.3
		c0.1,0,0.1,0,0.2,0c0.5,0,1,0,1.5,0c4.1,0,8.3,0,12.4,0l2.1-2.1L87.6,93.7z"
      />
      <path id="Trazado_4007" class="st0" d="M84.2,87.2h-8.1v3.1h8.1V87.2z" />
      <path
        id="Trazado_4008"
        class="st0"
        d="M101.4,89.5l-2.4-2.4l-3.4,3.4l-2,2l-1-1l-4.3-4.3l-0.1,0.1l-2.3,2.3l5.4,5.4L89.1,97
		l-3.3,3.3l2.4,2.4l5.4-5.4l5.4,5.3l2.4-2.4L96,94.9L101.4,89.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconClaimNotProccess",
};
</script>

<style scoped>
#CustomIconClaimNotProccess {
  fill: currentColor;
}
</style>

// insurance.policy
const dataForm = {
  // data
  readonly: false,
  insurancePlans: [],
  subscriber_type: "",
  // form
  billing_company_id: "",
  policy_number: "",
  group_number: "",
  insurance_company: "",
  insurance_plan: "",
  type_responsibility_id: 50,
  insurance_policy_type_id: 1,
  eff_date: "",
  end_date: "",
  complementary_policy_id: "",
  dual_plan: false,
  assign_benefits: true,
  release_info: true,
  own_insurance: true,
  status: false,
  subscriber: {
    id: "",
    relationship_id: "",
    ssn: "",
    date_of_birth: "",
    first_name: "",
    last_name: "",
    suffix: "",
    sex: "",
    address: {
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    contact: {
      phone: "",
      fax: "",
      mobile: "",
      email: "",
    },
  },
};

const getPivoteCode = () => {
  return Math.random() * 10000000000000000;
};

const setInsuranceCompaniesList = (policies) => {
  let insuranceCompanies = [];

  policies.data.map((item) => {
    item.billing_company_id = item.billing_company_id ?? item.policy_number;

    insuranceCompanies.push({
      id: getPivoteCode(),
      policy_id: item.id,
      billing_company: item?.billing_company ?? "-",
      type: item.type_responsibility,
      policy_number: item.policy_number,
      insurance_plan: item.insurance_plan,
      insurance_company: item.insurance_company,
      eligibility: item.eligibility,
      status: item.status,
      data: item,
    });
  });

  return {
    numberOfPages: 0,
    count: 0,
    data: insuranceCompanies,
  };
};

const setInsuranceCompanies = (data) => {
  let insuranceCompanies = [],
    numberOfPages = 0,
    count = 0;

  count = data.insurance_policies.count;
  numberOfPages = data.insurance_policies.numberOfPages;

  data.insurance_policies.data.map((item) => {
    insuranceCompanies.push({
      id: getPivoteCode(),
      policy_id: item.id,
      billing_company: item.billing_company,
      type: item.type_responsibility,
      policy_number: item.policy_number,
      insurance_plan: item.insurance_plan,
      insurance_company: item.insurance_company,
      eligibility: item.eligibility,
      status: item.status,
      data: item,
    });
  });

  return {
    numberOfPages: numberOfPages,
    count: count,
    data: insuranceCompanies,
  };
};

const setInsurancePolicy = (data, action) => {
  let subscriber = data && data.subscriber ? data.subscriber : null,
    address = subscriber && subscriber.address ? subscriber.address : null,
    contact = subscriber && subscriber.contact ? subscriber.contact : null;

  return {
    // data
    readonly: action === "view-policy" ? true : false,
    insurancePlans: [],
    subscriber_type: subscriber?.id ?? "",
    id: data?.id,
    // form
    billing_company_id: data.billing_company_id,
    policy_number: data.policy_number,
    group_number: data.group_number,
    insurance_company: data.insurance_company_id,
    insurance_plan: data.insurance_plan_id,
    type_responsibility_id: data.type_responsibility_id,
    insurance_policy_type_id: data.insurance_policy_type_id,
    eff_date: data.eff_date,
    end_date: data.end_date,
    complementary_policy_id: data.complementary_policy_id,
    complementary_policy: data.complementary_policy ?? "",
    dual_plan: data.dual_plan ? true : false,
    assign_benefits: data.assign_benefits ? true : false,
    release_info: data.release_info ? true : false,
    own_insurance: data.own_insurance ? true : false,
    status: data?.status ?? true,
    subscriber: {
      id: subscriber?.id ?? "",
      relationship_id: subscriber?.relationship_id ?? "",
      ssn: subscriber?.ssn ?? "",
      date_of_birth: subscriber?.date_of_birth ?? "",
      first_name: subscriber?.first_name ?? "",
      last_name: subscriber?.last_name ?? "",
      suffix: subscriber?.name_suffix_id ?? "",
      sex: subscriber?.sex ?? "",
      address: {
        address: address?.address ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        zip: address?.zip ?? "",
        country: address?.country ?? "",
      },
      contact: {
        phone: contact?.phone ?? "",
        fax: contact?.fax ?? "",
        mobile: contact?.mobile ?? "",
        email: contact?.email ?? "",
      },
    },
  };
};

const formatBodyRequest = (insuranceForm, permissionType) => {
  let data = {
    policy_number: insuranceForm.policy_number,
    group_number: insuranceForm.group_number,
    insurance_plan: insuranceForm.insurance_plan,
    type_responsibility_id: insuranceForm.type_responsibility_id,
    insurance_policy_type_id: insuranceForm.insurance_policy_type_id,
    eff_date: insuranceForm.eff_date,
    end_date: insuranceForm.end_date,
    complementary_policy_id: insuranceForm.complementary_policy_id,
    dual_plan: insuranceForm.dual_plan,
    assign_benefits: insuranceForm.assign_benefits,
    release_info: insuranceForm.release_info,
    own_insurance: insuranceForm.own_insurance,
    status: insuranceForm.status,
    subscriber: null,
  };

  if (permissionType.isSuperUser) {
    data.billing_company_id = insuranceForm.billing_company_id;
  }

  if (!data.own_insurance) {
    data.subscriber = {
      id: insuranceForm.subscriber?.id ?? "",
      relationship_id: insuranceForm.subscriber?.relationship_id ?? "",
      ssn: insuranceForm.subscriber?.ssn ?? "",
      date_of_birth: insuranceForm.subscriber?.date_of_birth ?? "",
      first_name: insuranceForm.subscriber?.first_name ?? "",
      last_name: insuranceForm.subscriber?.last_name ?? "",
      name_suffix_id: insuranceForm.subscriber?.suffix ?? "",
      sex: insuranceForm.subscriber?.sex ?? "",
      address: {
        address: insuranceForm.subscriber?.address?.address ?? "",
        city: insuranceForm.subscriber?.address?.city ?? "",
        state: insuranceForm.subscriber?.address?.state ?? "",
        zip: insuranceForm.subscriber?.address?.zip ?? "",
        country: insuranceForm.subscriber?.address?.country ?? "",
        apt_suite: insuranceForm.subscriber?.address?.apt_suite ?? "",
      },
      contact: {
        phone: insuranceForm.subscriber?.contact?.phone ?? "",
        fax: insuranceForm.subscriber?.contact?.fax ?? "",
        mobile: insuranceForm.subscriber?.contact?.mobile ?? "",
        email: insuranceForm.subscriber?.contact?.email ?? "",
      },
    };
  }

  return data;
};

export default {
  dataForm,
  setInsuranceCompanies,
  setInsuranceCompaniesList,
  setInsurancePolicy,
  formatBodyRequest,
};

<template>
  <svg
    id="CustomIconRooms"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 16.8"
  >
    <path
      id="king_bed_FILL0_wght400_GRAD0_opsz24"
      data-name="king bed FILL0 wght400 GRAD0 opsz24"
      class="cls-1"
      d="M3.6,16.8h-1.21l-.78-2.4H0v-6.04c0-.63.25-1.24.7-1.68.45-.45,1.06-.7,1.69-.69v-3.6c0-.64.25-1.25.7-1.69C3.55.24,4.16,0,4.8,0h14.41c.64,0,1.25.25,1.69.7.46.45.71,1.06.7,1.69v3.6c.64,0,1.25.25,1.69.7.46.45.71,1.06.7,1.69v6h-1.62l-.78,2.4h-1.2l-.78-2.4H4.38l-.78,2.4h0ZM13.21,6h6v-3.6h-6v3.6ZM4.8,6h6v-3.6h-6s0,3.6,0,3.6ZM2.41,12h19.21v-3.6H2.4v3.6h0ZM21.62,12h0Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRooms",
};
</script>

<style scoped>
#CustomIconRooms {
  fill: currentColor;
}
</style>

export const policyForFormsAdapter = (
  data,
  insuranceCompanies,
  allInsurancePlans,
  indexaInsurancePlans
) => {
  const insuranceCompany = insuranceCompanies.find(
    (insuranceCompany) => insuranceCompany.id === data.insuranceCompanyId
  );

  return {
    insurancePlans: allInsurancePlans[indexaInsurancePlans],
    insurancePlan: { name: data.insurancePlan.name, id: data.insurancePlan.id },
    insuranceCompany,
    policyNumber: data.policyNumber,
    copay: data.copay,
    groupNumber: data.groupNumber,
    effDate: data.effDate,
    endDate: data.endDate,
    complementary_policy_id: data?.complementary_policy_id ?? "",
    dual_plan: data?.dual_plan ?? false,
    releaseInfo: data?.releaseInfo ?? false,
    assignBenefits: data?.assignBenefits ?? false,
    ownInsurance: data?.own ?? false,
    type_responsibility_id: data?.type_responsibility_id ?? "",
    insurance_policy_type_id: data?.insurance_policy_type_id ?? "",
    relationship_id: data?.suscriber?.relationship_id ?? "",
    date_of_birth: data?.suscriber?.date_of_birth ?? "",
    suscriber: data.suscriber
      ? { label: "Others", value: "2" }
      : { label: "Select", value: "1" },
    ssn: data?.suscriber?.ssn ?? "",
    firstName:
      data.suscriber && data.suscriber.firstName
        ? data.suscriber.firstName
        : "",
    lastName:
      data.suscriber && data.suscriber.lastName ? data.suscriber.lastName : "",
    address: data?.suscriber?.address ?? "",
    city: data?.suscriber?.city ?? "",
    state: data?.suscriber?.state ?? "",
    zip: data?.suscriber?.zip ?? "",
    phone: data?.suscriber?.phone ?? "",
    fax: data?.suscriber?.fax ?? "",
    mobile: data?.suscriber?.mobile ?? "",
    email: data?.suscriber?.email ?? "",
  };
};

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import CustomIcons from "./CustomIcons";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: CustomIcons,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#23a3f5",
        secondary: "#0cb7f2",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        inPrimary: "#23a3f5",
        inSuccess: "#e9fdf2",
        inDenied: "#A72821",
        bgDenied: "#FFDEDC",
        // inDecoration: "#000e548f",
        inTertiary: "#def7ff",
        inGray: "#bbdefb",
        inGray2: "#C6D7E8",
        inGray3: "#f4f7fa",
        inGray4: "#f2f4f7",
        inGray5: "#8f9596",
        //Dashboard Colors
        inBlueFrance: "#4169e1",
        inPurple: "#512DA8",
        inGreen: "#388E3C",
        inDarkGreen: "#00BFA5",
        inPink: "#BA68C8",
        inOrange: "#f7bd56",
        inError: "#de0013",
        inTypeCharge: "#0066ff",
        inTypePayment: "#42e69e",
        inTypeWriteOffs: "#ff7e7e",
        inTypeRefund: "#1b6d49",
        inTypeClaim: "#004665",
        inTypePResp: "#71d7f9",

        // new colors
        "app-bar": "#07629F",
        "nav-icon": "#6AA0C5",
        search: "#F0F6F9",

        "drawer-top": "#17C7FF",
        "drawer-bottom": "#1771D1",

        input: "#E0E9F2",
        button: "#00D3FF",
        "text-button": "#07629F",
      },
    },
  },
});

<template>
  <svg
    id="CustomIconDisable"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <path
      d="M40.6,21.84h-3.05c-.2-5.45-2.42-9.83-6.93-12.93-3.21-2.21-6.81-3.03-10.68-2.58-7.67,.88-13.66,7.51-13.76,15.23-.05,4.36,1.43,8.14,4.48,11.26,3.04,3.11,6.8,4.6,11.15,4.73v3.03c-4.51,.27-11.65-2.07-15.76-8.53C1.4,24.76,2.16,15.71,7.93,9.23,13.39,3.09,22.56,1.32,30.09,4.98c7.78,3.79,10.73,11.53,10.51,16.85Z"
    />
    <path
      d="M37.61,28.14c5.16-.02,9.37,4.21,9.37,9.4,0,5.12-4.21,9.35-9.33,9.37-5.21,.02-9.46-4.16-9.48-9.31-.02-5.27,4.15-9.45,9.44-9.47Zm-.01,1.84c-4.2,0-7.58,3.36-7.58,7.52,0,4.19,3.39,7.58,7.59,7.57,4.17,0,7.52-3.39,7.52-7.58,0-4.14-3.39-7.52-7.53-7.51Z"
    />
    <path
      d="M35.58,34.6c.7,.69,1.37,1.35,2.09,2.05,.64-.69,1.28-1.39,1.93-2.08,.43,.46,.77,.81,1.09,1.15-.63,.62-1.3,1.29-2.01,1.99,.73,.75,1.4,1.43,2.06,2.11-.38,.37-.72,.72-1.08,1.07-.67-.66-1.35-1.33-2.08-2.05-.66,.7-1.3,1.39-1.97,2.1-.41-.45-.73-.8-1.05-1.15,.62-.62,1.3-1.29,2.02-2.01-.76-.7-1.47-1.35-2.16-1.99,.46-.47,.8-.82,1.16-1.19Z"
    />
    <path
      d="M17.29,15.07c1.58,1.56,3.09,3.05,4.72,4.65,1.45-1.57,2.91-3.14,4.37-4.72,.98,1.03,1.74,1.82,2.48,2.6-1.42,1.4-2.94,2.91-4.56,4.51,1.66,1.69,3.18,3.24,4.67,4.77-.85,.85-1.63,1.62-2.44,2.43-1.52-1.5-3.05-3.01-4.7-4.64-1.49,1.58-2.95,3.15-4.46,4.76-.92-1.01-1.66-1.82-2.39-2.62,1.41-1.4,2.94-2.91,4.58-4.54-1.73-1.59-3.33-3.07-4.89-4.51,1.04-1.07,1.81-1.86,2.63-2.7Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDisable",
};
</script>

<style scoped>
#CustomIconDisable {
  fill: currentColor;
}
</style>

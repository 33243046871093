import axios from "@/plugins/axios";

const state = () => ({
  insTypes: [],
  planTypes: [],
  chargeUsings: [],
  formats: [],
  currentInsuranceplan: [],
  listResponsibilities: [],
  insurance_plan: null,
});

const mutations = {
  setInsTypes(state, insTypes) {
    state.insTypes = insTypes;
  },
  setPlanTypes(state, planTypes) {
    state.planTypes = planTypes;
  },
  setChargeUsings(state, chargeUsings) {
    state.chargeUsings = chargeUsings;
  },
  setFormats(state, formats) {
    state.formats = formats;
  },
  setCurrentInsurancePlan(state, currentInsuranceplan) {
    state.currentInsuranceplan = currentInsuranceplan;
  },
  setListResponsibilities(state, listResponsibilities) {
    state.listResponsibilities = listResponsibilities;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getAllInsurancePlans(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-all-server", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePlans(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/insurance-plan/insurance-company/${data}/get-by-insurance-company`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeInsurancePlan(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/insurance-plan", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateInsurancePlan(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/insurance-plan/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePlanById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/insurance-plan/${data}`)
        .then((result) => {
          commit("setCurrentInsurancePlan", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePlanByName(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/insurance-plan/${data}/get-by-name`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePlanByPayer(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/insurance-plan/get-by-payer-id/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  getInsTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-list-ins-types")
        .then((result) => {
          commit("setInsTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getPlanTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-list-plan-types")
        .then((result) => {
          commit("setPlanTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getChargeUsings({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-list-charge-usings")
        .then((result) => {
          commit("setChargeUsings", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListByPayerId(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/insurance-plan/get-list-by-payer/${payload.payer}`, {
          params: payload.params,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getFormats({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-list-formats")
        .then((result) => {
          commit("setFormats", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListResponsibilities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-list-responsibility-type")
        .then((result) => {
          commit("setListResponsibilities", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  changeStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`insurance-plan/${data.id}/change-status`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  saveCopay(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/insurance-plan/${data.id}/copays`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  saveContract(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/insurance-plan/${data.id}/contract-fees`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

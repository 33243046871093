// import axios from "@/plugins/axios";

const state = () => ({
  tableName: "",
  selectedHistory: [],
  isLoadingGetModuleData: false,
  isLoadingSavePresets: false,
  isLoadingConfirmHistory: false,
});

const mutations = {
  setTableName(state, tableName) {
    state.tableName = tableName;
  },
  setSelectedHistory(state, selectedHistory) {
    state.selectedHistory = selectedHistory;
  },
  setIsLoadingGetModuleData(state, isLoadingGetModuleData) {
    state.isLoadingGetModuleData = isLoadingGetModuleData;
  },
  setIsLoadingSavePresets(state, isLoadingSavePresets) {
    state.isLoadingSavePresets = isLoadingSavePresets;
  },
  setIsLoadingConfirmHistory(state, isLoadingConfirmHistory) {
    state.isLoadingConfirmHistory = isLoadingConfirmHistory;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

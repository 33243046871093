<template>
  <svg
    version="1.1"
    id="CustomIconAllServices"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 13.4 14.8"
    style="enable-background: new 0 0 13.4 14.8"
    xml:space="preserve"
  >
    <path
      id="density_small_FILL0_wght400_GRAD0_opsz24"
      class="st28"
      d="M0,14.8v-1.5h13.4v1.5H0z M0,10.4V8.9h13.4v1.5L0,10.4z M0,5.9
    V4.5h13.4v1.5H0z M0,1.5V0h13.4v1.5L0,1.5z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconAllServices",
};
</script>

<style scoped>
.st0 {
  fill: #4d4d4d;
}
.st1 {
  fill: #ffffff;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #4ac1e0;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00ccff;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #155dff;
}
.st5 {
  fill: #ff0000;
}
.st6 {
  fill: #00ff00;
}
.st7 {
  fill: #155dff;
}
.st8 {
  fill: #aa0c18;
  stroke: #aa0c18;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #00e2e2;
  stroke: #00e2e2;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #006837;
  stroke: #006837;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #fce906;
  stroke: #fce906;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st12 {
  fill: #ff0000;
  stroke: #ff0000;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #00cc3e;
}
.st14 {
  fill: #fce906;
}
.st15 {
  fill: #880088;
}
.st16 {
  fill: #00e2e2;
}
.st17 {
  fill: #aa0c18;
}
.st18 {
  fill: #f7931e;
}
.st19 {
  fill: #006837;
}
.st20 {
  fill: #808080;
}
.st21 {
  fill: #f2f2f2;
}
.st22 {
  fill: #707070;
}
.st23 {
  fill: #fbecdd;
}
.st24 {
  fill: #b04d12;
}
.st25 {
  fill: #fffaec;
}
.st26 {
  fill: #eac767;
}
.st27 {
  fill: #e9fdf2;
}
.st28 {
  fill: #1b6d49;
}
.st29 {
  fill: #d1ffe5;
}
.st30 {
  fill: #42e69e;
}
.st31 {
  fill: #ffdedc;
}
.st32 {
  fill: #e60c00;
}
.st33 {
  fill: #fff1f1;
}
.st34 {
  fill: #a72821;
}
.st35 {
  fill: #ff7e7e;
}
.st36 {
  fill: #59d0f8;
}
.st37 {
  fill: #0066ff;
}
.st38 {
  fill: #004665;
}
.st39 {
  fill: #ff5f00;
}
.st40 {
  fill: none;
  stroke: #e6e6e6;
  stroke-miterlimit: 10;
}
</style>

// patient.claims
export const patientClaims = (data) => {
  let claims = [];

  data.data.map((item) => {
    claims.push({
      id: item?.id ?? "",
      code: item?.code ?? "",
      dos: item?.date_of_service ?? "",
      status: item?.status ?? null,
      billed_amount: item?.billed_amount ?? "",
      company: item?.demographic_information?.company ?? null,
      billing_company: item?.billing_company?.name ?? "",
    });
  });

  const numberOfPages = data?.numberOfPages
    ? data.numberOfPages
    : data?.last_page ?? "";

  const count = data?.numberOfPages ? data.numberOfPages : data?.total ?? "";

  return {
    numberOfPages: numberOfPages,
    count: count,
    data: claims,
  };
};

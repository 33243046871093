<template>
  <svg
    version="1.1"
    id="CustomIconClaims"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.1 16.4"
    style="enable-background: new 0 0 22.1 16.4"
    xml:space="preserve"
  >
    <path
      id="folder_open_FILL0_wght400_GRAD0_opsz48_00000140692842591504537190000015868579042812697748_"
      class="st38"
      d="M1.5,16.4
	c-0.4,0-0.8-0.2-1.1-0.5C0.2,15.7,0,15.3,0,14.9V1.5c0-0.4,0.2-0.8,0.5-1.1C0.7,0.2,1.1,0,1.5,0h7.2l1.5,1.5H19
	c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1H9.6L8.1,1.5H1.5v13.3L4.2,4.6h17.9l-2.7,10.6c-0.1,0.4-0.3,0.7-0.6,0.9
	c-0.3,0.2-0.7,0.3-1.1,0.3L1.5,16.4z M3.2,14.9h14.7L20,6.2H5.3L3.2,14.9z M3.2,14.9l2.2-8.7L3.2,14.9z M1.5,3.1L1.5,3.1z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClaims",
};
</script>

<style scoped>
.st0 {
  fill: #4d4d4d;
}
.st1 {
  fill: #ffffff;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #4ac1e0;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00ccff;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #155dff;
}
.st5 {
  fill: #ff0000;
}
.st6 {
  fill: #00ff00;
}
.st7 {
  fill: #155dff;
}
.st8 {
  fill: #aa0c18;
  stroke: #aa0c18;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #00e2e2;
  stroke: #00e2e2;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #006837;
  stroke: #006837;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #fce906;
  stroke: #fce906;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st12 {
  fill: #ff0000;
  stroke: #ff0000;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #00cc3e;
}
.st14 {
  fill: #fce906;
}
.st15 {
  fill: #880088;
}
.st16 {
  fill: #00e2e2;
}
.st17 {
  fill: #aa0c18;
}
.st18 {
  fill: #f7931e;
}
.st19 {
  fill: #006837;
}
.st20 {
  fill: #808080;
}
.st21 {
  fill: #f2f2f2;
}
.st22 {
  fill: #707070;
}
.st23 {
  fill: #fbecdd;
}
.st24 {
  fill: #b04d12;
}
.st25 {
  fill: #fffaec;
}
.st26 {
  fill: #eac767;
}
.st27 {
  fill: #e9fdf2;
}
.st28 {
  fill: #1b6d49;
}
.st29 {
  fill: #d1ffe5;
}
.st30 {
  fill: #42e69e;
}
.st31 {
  fill: #ffdedc;
}
.st32 {
  fill: #e60c00;
}
.st33 {
  fill: #fff1f1;
}
.st34 {
  fill: #a72821;
}
.st35 {
  fill: #ff7e7e;
}
.st36 {
  fill: #59d0f8;
}
.st37 {
  fill: #0066ff;
}
.st38 {
  fill: #004665;
}
.st39 {
  fill: #ff5f00;
}
.st40 {
  fill: none;
  stroke: #e6e6e6;
  stroke-miterlimit: 10;
}
</style>

import VueI18n from "@/i18n.js";

const isTableDiagComplete = (dxs, format, patientChange) => {
  let isComplete = true;
  let hasAdmission = false;

  for (let field of dxs) {
    if (
      (field.diagnosis_id === "" && dxs.indexOf(field) === 0) ||
      (format === 2 && patientChange && field.poa === "")
    ) {
      isComplete = false;
    }

    if (format === 2 && patientChange && field.admission === true) {
      hasAdmission = true;
    }
  }

  if (format === 2 && patientChange && !hasAdmission) {
    isComplete = false;
  }

  return isComplete;
};

const isTableServComplete = (services, format) => {
  let isComplete = true;

  if (format === 1) {
    isComplete = !services.every((field) => {
      return (
        field.from_service === "" ||
        field.to_service === "" ||
        field.procedure_id === "" ||
        field.place_of_service_id === "" ||
        field.type_of_service_id === "" ||
        field.diagnostic_pointers.length === 0 ||
        field.days_or_units === "" ||
        field.price === ""
      );
    });

    services.forEach((field) => {
      if (
        field.units_limit !== null &&
        field.days_or_units !== "" &&
        parseFloat(field.days_or_units) > field.units_limit
      ) {
        isComplete = false;
      }
    });
  }

  if (format === 2) {
    isComplete = !services.every((field) => {
      return (
        field.from_service === "" ||
        field.to_service === "" ||
        field.revenue_code_id === "" ||
        field.price === "" ||
        field.units_of_service === "" ||
        parseFloat(field.units_of_service) === 0 ||
        field.total_charge === "0.00"
      );
    });

    services.forEach((field) => {
      if (
        field.units_limit !== null &&
        field.units_of_service !== "" &&
        parseFloat(field.units_of_service) > field.units_limit
      ) {
        isComplete = false;
      }
    });
  }

  return isComplete;
};

const validSequential = (services) => {
  for (let i = 1; i < services.length; i++) {
    if (
      services[i].from_service &&
      services[i].from_service !== "" &&
      services[i - 1].to_service &&
      services[i - 1].to_service !== ""
    ) {
      if (services[i].from_service < services[i - 1].to_service) {
        return false;
      }
    }
  }

  return true;
};

const validUnitServices = (services, format) => {
  for (let i = 0; i < services.length; i++) {
    for (let j = i + 1; j < services.length; j++) {
      if (
        format === 1 &&
        services[i].from_service === services[j].from_service &&
        services[i].to_service === services[j].to_service &&
        services[i].procedure_id !== "" &&
        services[j].procedure_id !== "" &&
        services[i].procedure_id === services[j].procedure_id
      ) {
        return false;
      }

      if (
        format === 2 &&
        services[i].from_service === services[j].from_service &&
        services[i].to_service === services[j].to_service &&
        services[i].revenue_code_id !== "" &&
        services[j].revenue_code_id !== "" &&
        services[i].revenue_code_id === services[j].revenue_code_id &&
        services[i].procedure_id !== "" &&
        services[j].procedure_id !== "" &&
        services[i].procedure_id === services[j].procedure_id
      ) {
        return false;
      }
    }
  }

  return true;
};

const createID = (type, header, index, rowNo, format, change) => {
  let id = "";

  if (type === "lef") {
    id =
      header[index - 1].value === "diagnosis_description"
        ? rowNo + "_" + header[index - 2].value.toUpperCase()
        : header[index - 1].value === "poa" && format === 2 && !change
        ? rowNo + "_" + header[index - 4].value.toUpperCase()
        : header[index - 1].value === "price" ||
          header[index - 1].value === "total_charge"
        ? rowNo + "_" + header[index - 2].value.toUpperCase()
        : header[index - 1]
        ? rowNo + "_" + header[index - 1].value.toUpperCase()
        : null;
  }

  if (type === "rig") {
    id =
      header[index + 1].value === "diagnosis_description" &&
      (change || format === 1)
        ? rowNo + "_" + header[index + 2].value.toUpperCase()
        : header[index + 1].value === "diagnosis_description" &&
          !change &&
          format === 2
        ? rowNo + "_" + header[index + 4].value.toUpperCase()
        : header[index + 1].value === "price" ||
          header[index + 1].value === "total_charge"
        ? rowNo + "_" + header[index + 2].value.toUpperCase()
        : header[index + 1]
        ? rowNo + "_" + header[index + 1].value.toUpperCase()
        : null;
  }

  return id;
};

const messageFound = (type, item) => {
  switch (type) {
    case "dx": {
      return item.foundDiag === 1
        ? VueI18n.t("claim.enter-diagnosis-code")
        : item.foundDiag === 2
        ? VueI18n.t("claim.diagnosis-code-not-found")
        : VueI18n.t("claim.diagnosis-code-entered");
    }
    case "rev": {
      return item.foundRevenue === 2
        ? VueI18n.t("general.revenue-code-not-found")
        : VueI18n.t("general.enter-revenue-code");
    }
    case "pro": {
      return item.foundProcedure === 2
        ? VueI18n.t("general.procedure-code-not-found")
        : VueI18n.t("general.enter-procedure-code");
    }
    case "mod": {
      return item.foundModifiers === 2
        ? VueI18n.t("general.modifier-code-not-found")
        : VueI18n.t("general.enter-modifier-code");
    }
  }
};

const showDataProperty = (type, id, array) => {
  if (array && array.length > 0) {
    const itemSelected = array.find((item) => {
      return item.id === id;
    });

    if (itemSelected)
      return type === "n" ? itemSelected.name : itemSelected.description;
  }

  return "";
};

const showDataModifiers = (modifiers) => {
  let modifiersNames = [];
  modifiers.map((item) => {
    modifiersNames.push(item.name);
  });

  return modifiersNames.join(", ");
};

const verifySearch = (property, item) => {
  const getCode = (item, codeKey) => {
    const code = item[codeKey] ? item[codeKey].split(" ")[0] : "";
    return code === "" ? null : code;
  };

  const verifyProperties = {
    diagnosis_id: (item) => {
      const code = getCode(item, "diagnosis_code");
      return code ? !item.diagnosis.find((dx) => dx.name === code) : false;
    },
    revenue_code_id: (item) => {
      const code = getCode(item, "rev_code");
      return code ? !item.revenueCodes.find((dx) => dx.name === code) : false;
    },
    procedure_id: (item) => {
      const code = getCode(item, "procedure_code");
      return code ? !item.procedures.find((dx) => dx.name === code) : false;
    },
    modifier_ids: (item) => {
      const code = getCode(item, "modifier_code");
      return code ? !item.modifiers.find((dx) => dx.name === code) : false;
    },
  };

  const hasCode = verifyProperties[property];
  return hasCode ? hasCode(item) : false;
};

const setCode = (array) => {
  if (array.length > 0) {
    const fixedName =
      array[0].description && array[0].description !== ""
        ? array[0].name + " - " + array[0].description
        : array[0].name;

    return fixedName;
  }

  return "";
};

const setName = (array, property) => {
  const arrayFixed = array.map((item) => {
    item.nameConcat =
      item.description && item.description !== ""
        ? item.name + " - " + item.description
        : item.name;

    if (item[property] && item[property].length > 0) {
      item[property] = setName(item[property], "");
    }

    return item;
  });

  return arrayFixed;
};

const whichMenu = (fieldId, item) => {
  switch (fieldId) {
    case "diagnosis_id": {
      return item.menuDx;
    }
    case "poa": {
      return item.menuPoa;
    }
    case "revenue_code_id": {
      return item.menuRev;
    }
    case "procedure_id": {
      return item.menuPro;
    }
    case "modifier_ids": {
      return item.menuMod;
    }
    case "place_of_service_id": {
      return item.menuPos;
    }
    case "type_of_service_id": {
      return item.menuTos;
    }
    case "diagnostic_pointers": {
      return item.menuPtr;
    }
    case "epsdt_id": {
      return item.menuEpsdt;
    }
    case "family_planning_id": {
      return item.menuFamily;
    }
    default:
      return "";
  }
};

const getElementDetails = (el, diagnostics, services, hDx, hSer) => {
  const isDx = el.id.includes("_DX");
  const field = el.id.substring(el.id.indexOf("_"));
  const fieldId = isDx
    ? field.substring(1).toLowerCase().replace("_dx", "")
    : field.substring(1).toLowerCase();
  const array = isDx ? diagnostics : services;
  const header = isDx ? hDx : hSer;
  const rowNo = el.id.substring(0, el.id.indexOf("_"));
  const rowRef = el.name
    ? el.name.substring(el.name.indexOf("_")).substring(1)
    : null;

  return { isDx, field, fieldId, array, header, rowNo, rowRef };
};

const setFirstRev = (type, array) => {
  switch (type) {
    case "code": {
      return array.length === 1 ? array[0].nameConcat : "";
    }
    case "id": {
      return array.length === 1 ? array[0].id : "";
    }
    case "selected": {
      return array.length === 1 ? array[0] : null;
    }
  }
};

const dxAlreadyEntered = (item, dxs) => {
  if (dxs.length <= 1) return false;

  const indexDx = dxs.findIndex((a) => a.pivote === item.pivote);
  if (indexDx === -1) return false;

  return dxs.some(
    (dx, index) =>
      index !== indexDx &&
      dx.diagnosis_code === item.diagnosis_code.toUpperCase()
  );
};

const getTodayDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const validChangeTime = (value) => {
  if (value.includes(":mm:ss")) return value.replace(/:mm:ss$/, ":00:00");
  if (value.includes(":ss")) return value.replace(/:ss$/, ":00");

  return value;
};

const findObjPointer = (pointers, dxs) => {
  return dxs.filter((dx) => pointers.includes(dx.item));
};

const selectsIds = [
  "modifier_ids",
  "poa",
  "place_of_service_id",
  "type_of_service_id",
  "diagnostic_pointers",
  "epsdt_id",
  "family_planning_id",
];

const propertyTypes = {
  diagnosis_id: "dx",
  revenue_code_id: "rev",
  procedure_id: "pro",
  modifier_ids: "mod",
};

const propertyIds = [
  "diagnosis_id",
  "revenue_code_id",
  "procedure_id",
  "modifier_ids",
];

const fieldIdToValue = {
  diagnosis_id: "diagnosis",
  revenue_code_id: "revenueCodes",
  procedure_id: "procedures",
  modifier_ids: "modifiers",
};

const poas = [
  { id: "Y", name: "YES" },
  { id: "N", name: "NO" },
  { id: "U", name: "Unknown" },
  { id: "W", name: "Clinically undetermined" },
  { id: "1", name: "Unreported/Not used" },
];

const selectsIdsAdditional = [
  "admission_type_id",
  "admission_source_id",
  "patient_status_id",
  "bill_classification_id",
  "diagnosis_related_group_id",
  "signed_assignment_id",
  "information_release_id",
];

const billKeys = [
  "Interim - First Claim",
  "Interim-Continuing Claims",
  "Replacement of Prior Claim",
];

const listHierarchyType = {
  P: 1,
  S: 2,
  T: 3,
  A: 4,
  B: 5,
  C: 6,
  D: 7,
  E: 8,
  F: 9,
  G: 10,
  H: 11,
  U: 12,
};

const actionsTabs = (tabClaim, viewPreview) => {
  return [
    {
      href: "#view-claim",
      name: VueI18n.t("claim.view-claim"),
      class: "view-key",
      classIcon: "",
      icon: "visibility",
      sizeIcon: "24px",
      action: () => {},
    },
    {
      href: "#edit-claim",
      name: VueI18n.t("claim.edit-claim"),
      class: "edit-key",
      classIcon: "",
      icon: "edit",
      sizeIcon: "24px",
      action: () => {},
    },
    {
      href: "#records-claim",
      name: VueI18n.t("claim.records-claim"),
      class: "record-key",
      classIcon: "",
      icon: "history",
      sizeIcon: "24px",
      action: () => {},
    },
    {
      href: `#${tabClaim}`,
      name: VueI18n.t("claim.preview"),
      class: "preview-key",
      classIcon: "colorIcon",
      icon: "description",
      sizeIcon: "24px",
      action: () => {
        viewPreview(false);
      },
    },
    {
      href: `#${tabClaim}`,
      name: VueI18n.t("claim.print"),
      class: "preview-key",
      classIcon: "colorIcon",
      icon: "print",
      sizeIcon: "24px",
      action: () => {
        viewPreview(true);
      },
    },
    {
      href: "#status-claim",
      name: VueI18n.t("claim.status"),
      class: "done-key",
      classIcon: "",
      icon: "published_with_changes",
      sizeIcon: "24px",
      action: () => {},
    },
  ];
};

const textsTabs = (tabClaim) => {
  const tabs = {
    "view-claim": {
      name: VueI18n.t("claim.view-claim"),
    },
    "edit-claim": {
      name: VueI18n.t("claim.edit-claim"),
    },
    "records-claim": {
      name: VueI18n.t("claim.records-claim"),
    },
    "status-claim": {
      name: VueI18n.t("claim.status-claim"),
    },
  };

  return tabs[tabClaim].name;
};

const tabsEdit = (checkDisableTab, checkPassPolicies) => {
  return [
    {
      href: "#tabEditClaim-1",
      name: VueI18n.t("claim.basic-information"),
      disabled: false,
    },
    {
      href: "#tabEditClaim-2",
      name: VueI18n.t("claim.services-offered"),
      disabled: checkDisableTab,
    },
    {
      href: "#tabEditClaim-3",
      name: VueI18n.t("claim.policies-insurance"),
      disabled: checkPassPolicies,
    },
    {
      href: "#tabEditClaim-4",
      name: VueI18n.t("claim.additional-information"),
      disabled: checkDisableTab,
    },
  ];
};

const tabsView = [
  {
    href: "#tabViewClaim-1",
    name: VueI18n.t("claim.basic-information"),
  },
  {
    href: "#tabViewClaim-2",
    name: VueI18n.t("claim.services-offered"),
  },
  {
    href: "#tabViewClaim-3",
    name: VueI18n.t("claim.policies-insurance"),
  },
  {
    href: "#tabViewClaim-4",
    name: VueI18n.t("claim.additional-information"),
  },
];

export {
  isTableDiagComplete,
  isTableServComplete,
  validSequential,
  validUnitServices,
  createID,
  messageFound,
  showDataProperty,
  showDataModifiers,
  verifySearch,
  setCode,
  setName,
  whichMenu,
  getElementDetails,
  setFirstRev,
  dxAlreadyEntered,
  getTodayDate,
  validChangeTime,
  findObjPointer,
  selectsIds,
  propertyTypes,
  propertyIds,
  fieldIdToValue,
  poas,
  selectsIdsAdditional,
  billKeys,
  listHierarchyType,
  actionsTabs,
  textsTabs,
  tabsEdit,
  tabsView,
};

import axios from "@/plugins/axios";

const state = () => ({
  diagnosis: {},
  diagnostics: [],
  clasifications: [],
  type: [],
  pivoteRequest: null,
});
const mutations = {
  setDiagnostics(state, diagnostics) {
    state.diagnostics = diagnostics;
  },
  setDiagnosis(state, diagnosis) {
    state.diagnosis = diagnosis;
  },
  setDiagnosisType(state, type) {
    state.type = type;
  },
  updateStatusDiagnosis(state, data) {
    state.diagnostics.map((item) => {
      if (item.id === data) {
        item.active = !item.active;
      }
      return item;
    });
  },
  setClasifications(state, clasifications) {
    state.clasifications = clasifications;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getDiagnostics({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/diagnosis/")
        .then((result) => {
          commit("setDiagnostics", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getClasificationByCode(_, diagnosisCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/diagnosis/classification/${diagnosisCode}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getDiagnosisByCode(_, diagnosisCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/diagnosis/get-by-code/${diagnosisCode}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  create(/*{ dispatch }, */ { commit }, body) {
    return new Promise((resolve, reject) => {
      axios
        .post("/diagnosis/", body)
        .then((result) => {
          commit("setDiagnostics", result.data);
          // dispatch("getDiagnostics");
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getDiagnosisById({ commit }, diagnosisId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/diagnosis/${diagnosisId}`)
        .then((result) => {
          commit("setDiagnosis", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  update({ commit }, body) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/diagnosis/${body.id}`, body)
        .then((result) => {
          commit("setDiagnosis", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  updateNote(_, body) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/diagnosis/${body.id}/note`, body)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getClasifications({ commit }, type) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/diagnosis/type/${type}/classification`)
        .then((result) => {
          resolve(result.data);
          commit("setClasifications", result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getClasificationsSpecific(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/diagnosis/type/${payload.type}/classification?general=${payload.general}`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  changeStatusDiagnosis({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/diagnosis/change-status/${data.diagnosisId}`, data.update)
        .then((result) => {
          commit("updateStatusDiagnosis", data.diagnosisId);
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },
  getType({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/diagnosis/type`)
        .then((result) => {
          commit("setDiagnosisType", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const state = {
  genders: [],
};

const mutations = {
  setGender(state, genders) {
    state.genders = genders;
  },
};

const actions = {
  updatePassword(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`user/update-password`, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  uploadPhoto(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`user/img-profile`, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getGender({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/get-list-gender")
        .then((result) => {
          commit("setGender", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const state = () => ({
  modifier: {},
  items: [],
  currentModifier: {},
  modifiers: [],
  clasifications: [],
  types: [],
  pivoteRequest: null,
});

const mutations = {
  setModifiers(state, data) {
    state.items = data;
  },

  setCurrentModifier(state, modifier) {
    state.currentModifier = modifier;
  },

  updateStatusModifier(state, data) {
    state.items.map((item) => {
      if (item.id === data) {
        item.active = !item.active;
      }

      return item;
    });
  },

  setModifiersList(state, modifiers) {
    state.modifiers = modifiers;
  },
  setClasifications(state, clasifications) {
    state.clasifications = clasifications;
  },
  setTypes(state, types) {
    state.types = types;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  /**
   * @function storeModifier
   * @description Petition for create a Health Professional.
   * @param {Object} data Data for petition.
   */
  storeModifier(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/modifier", data)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  /**
   * @function storeupdateHealth
   * @description Petition for update a Health Professional.
   * @param {Object} data Data for petition.
   */

  storeUpdateModifier(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/modifier/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },
  storeUpdateModifierNote(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/modifier/${data.id}/note`, { note: data.update.note })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getModifierById({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/modifier/${data}`)
        .then((result) => {
          commit("setCurrentModifier", result.data);
          resolve(result.data);
          state.modifier = result.data;
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getAllModifier({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/modifier")
        .then((result) => {
          commit("setModifiers", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
          console.log("error", error);
        });
    });
  },

  searchModifierByCode(_, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/modifier/get-by-code/${code}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchModifiersList({ commit }) {
    return axios
      .get("/modifier/get-list")
      .then((result) => {
        commit("setModifiersList", result.data);
        return result.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  /**
   * @function updateStatus
   * @description Petition for update status  to one health professional.
   * @param {Object} data Data for petition.
   */
  updateStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/modifier/change-status/${data.id}`, data.update)
        .then((result) => {
          commit("updateStatusModifier", data.id);
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getListModifiers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/modifier/get-list")
        .then((result) => {
          commit("setModifiersList", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getClasifications({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/modifier/classification")
        .then((result) => {
          resolve(result.data);
          commit("setClasifications", result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/modifier/type")
        .then((result) => {
          resolve(result.data);
          commit("setTypes", result.data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const state = () => ({
  items: [],
  itemsUnits: [],
  unit: {},
  rooms: [],
  units: [],
  types: [],
  places: [],
  companies: [],
  resultFacilityNpi: null,
  billing_company_id: null,
  billingCompanies: [],
  billingCompaniesSU: [],
  facilities: [],
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },
  onEdit: false,
  onSelectBillingComapny: false,
});

const mutations = {
  setBillingCompany(state, data) {
    state.billing_company_id = data;
  },

  setFacilities(state, data) {
    state.items = data;
  },

  setTypes(state, data) {
    state.types = data;
  },
  setPlaces(state, data) {
    state.places = data;
  },

  setFacilityNpi(state, data) {
    state.resultFacilityNpi = data;
  },
  setFacilitiesByBillingC(state, data) {
    state.facilities = data;
  },

  updateStatusFacility(state, data) {
    state.items.map((item) => {
      if (item.id === data) {
        item.status = !item.status;
      }

      return item;
    });
  },

  setCompanies(state, companies) {
    state.companies = [];

    companies.map((company) => {
      state.companies.push({
        id: company.id,
        name: company.name,
      });
    });
  },

  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
  },

  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },

  setBillingCompaniesSU(state, billingCompaniesSU) {
    state.billingCompaniesSU = billingCompaniesSU;
  },
  setUnits(state, units) {
    state.itemsUnits = [];

    units.map((unit) => {
      state.itemsUnits.push({
        id: unit.id,
        name: unit.name,
        code: unit.code,
        status: unit.status,
      });
    });
  },

  setOnEdit(state, onEdit) {
    if (state.onSelectBillingComapny) {
      state.onEdit = onEdit;
    }
  },

  setOnSelectBillingComapny(state, onSelectBillingComapny) {
    state.onSelectBillingComapny = onSelectBillingComapny;
  },
};

const actions = {
  setBillingCompany({ commit }, billinCompanyId) {
    commit("setBillingCompany", billinCompanyId);
  },

  searchFacilityByNpi(_, npi) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/get-by-npi/${npi}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeFacility(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/facility", data)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  storeUpdateFacility(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/facility/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateFacility(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/facility/${data.id}`, JSON.stringify(data.update))
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getFacilities({ commit }, data) {
    let query = data.query ? data.query : "";
    const url = `/facility/get-all-server?query=${query}&itemsPerPage=${data.itemsPerPage}&sortDesc=${data.sortDesc}&page=${data.page}&sortBy=${data.sortBy}${data.searchOn}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          commit("setFacilities", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getFacilityById({ commit }, facilityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/${facilityId}`)
        .then((result) => {
          commit("setFacilityNpi", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/facility/${data.id}/change-status`, data.update)
        .then((result) => {
          // commit("updateStatusFacility", data.id);
          resolve(result);
        })
        .catch((error) => {
          console.log(error);
          reject(error.response.data);
        });
    });
  },

  addToBillingCompany(facilityId) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/facility/add-to-billing-company/${facilityId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getFacilityTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/get-list-facility-types`)
        .then((result) => {
          commit("setTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getFacilityPlaceOfService({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/get-list-place-of-services`)
        .then((result) => {
          commit("setPlaces", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company")
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompaniesByFaciity(_, payload) {
    const url = payload.billinCompanyId
      ? `/company/get-all-server?wheres[facility]=${payload.facilityId}&billing_company_id=${payload.billinCompanyId}&return=list&enabled`
      : `/company/get-all-server?wheres[facility]=${payload.facilityId}&return=list&enabled`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getEntityAudits(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/${data.entity}/${data.id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getBillingCompanies({ commit }, facilityId, editId) {
    let facilityID = facilityId == null ? "" : facilityId;
    let edit = editId == null ? "" : editId;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/facility/get-list-billing-companies?=facility_id=${facilityID}&edit=${edit}`
        )
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesCreate({ commit }, npi) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/get-list-billing-companies/${npi}`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesRoom(_, facilityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/billing-company/get-all-server?filter=facilities.id=${facilityId}`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getFacilitiesByBillingC({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/get-list?billing_company_id=${billingCompanyId}`)
        .then((response) => {
          commit("setFacilitiesByBillingC", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject("error:", error);
        });
    });
  },

  getBillClassifications(_, facilityTipeId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/bill-classifications/${facilityTipeId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setBillingCompaniesSU({ commit }, billingCompanies) {
    commit("setBillingCompaniesSU", billingCompanies);
  },

  setPermissionType({ commit }, roles) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };
    if (roles) {
      roles.find((role) => {
        if (role.type === 1) {
          permissionType.isSuperUser = true;
        }

        if (role.type === 2) {
          permissionType.isBillingManager = true;
        }
      });
    }

    commit("setPermissionType", permissionType);
  },

  getCompanyByIDBillingCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/${data.id}`, {
          params: data.params,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateCompanies(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/facility/${data.facility_id}/company/`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getUnitsFacility({ commit }, params) {
    let query = params.query ? params.query : "";
    const url =
      params.options.sortDesc[0] === true
        ? `/unit?filter=facility.id=${params.facility_id}&query=${query}&itemsPerPage=${params.options.itemsPerPage}&sortDesc=${params.options.sortDesc}&page=${params.options.page}&sortBy=${params.options.sortBy}`
        : `/unit?filter=facility.id=${params.facility_id}&query=${query}&itemsPerPage=${params.options.itemsPerPage}&page=${params.options.page}&sortBy=${params.options.sortBy}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          commit("setUnits", result.data.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

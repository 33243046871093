<template>
  <svg
    id="CustomIconArchive"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.83 22.26"
  >
    <path
      id="folder_delete_FILL0_wght400_GRAD0_opsz24"
      data-name="folder delete FILL0 wght400 GRAD0 opsz24"
      class="cls-1"
      d="M17.39,18.09h2.78c.56,0,1.09-.21,1.48-.61.4-.39.62-.92.61-1.48v-5.57h1.39v-2.09h-3.48v-1.39h-2.78v1.39h-3.48v2.09h1.39v5.56c0,.56.21,1.09.61,1.48.39.4.92.62,1.48.61ZM17.39,10.44h2.78v5.56h-2.78v-5.56ZM2.78,22.26c-.74.01-1.45-.29-1.96-.82C.29,20.93-.01,20.22,0,19.48V2.78c-.01-.74.29-1.45.82-1.97C1.33.28,2.04-.01,2.78,0h8.35l2.78,2.78h11.13c.74-.01,1.45.29,1.96.82.53.51.83,1.23.82,1.97v13.91c.01.74-.29,1.45-.82,1.97-.51.53-1.23.83-1.96.82H2.78ZM2.78,19.48h22.26V5.57h-12.28l-2.78-2.78H2.78v16.7ZM2.78,19.48h0Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArchive",
};
</script>

<style scoped>
#CustomIconArchive {
  fill: currentColor;
}
</style>

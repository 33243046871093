<!-- IconCharge -->
<template>
  <svg
    version="1.1"
    id="CustomIconCharge"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 19.7 19.7"
    style="enable-background: new 0 0 19.7 19.7"
    xml:space="preserve"
  >
    <path
      id="post_add_FILL0_wght400_GRAD0_opsz48"
      class="st0"
      d="M1.6,19.6c-0.4,0-0.8-0.1-1.1-0.4C0.2,18.9,0,18.5,0,18V1.6
	c0-0.4,0.2-0.8,0.5-1.1S1.2,0,1.6,0h10.8v1.6H1.6V18H18V7.3h1.6V18c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5H1.6L1.6,19.6z
	 M5.5,15.4v-1.6h8.7v1.6H5.5z M5.5,11.9v-1.6h8.7v1.6H5.5z M5.5,8.4V6.8h8.7v1.6L5.5,8.4z M15.6,6.4V4h-2.4V2.4h2.4V0h1.6v2.4h2.4V4
	h-2.4v2.4H15.6z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCharge",
};
</script>

<style scoped>
.st0 {
  fill: #0066ff;
}
</style>

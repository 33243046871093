<template>
  <svg
    version="1.1"
    id="CustomIconStatement"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="2 2 44 44"
    style="enable-background: new 0 0 50 50"
    xml:space="preserve"
  >
    <path
      id="newspaper_FILL0_wght400_GRAD0_opsz48"
      class="st0"
      d="M9.3,41.6c-0.7,0-1.4-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2V8.4l3.1,3.1
	l3-3.1l3.1,3.1l3.1-3.1l3,3.1L25,8.4l3.1,3.1l3-3.1l3.1,3.1l3.1-3.1l3,3.1l3.1-3.1v30.5c0,0.7-0.3,1.4-0.8,2c-0.5,0.5-1.2,0.8-2,0.8
	H9.3z M9.3,38.9h14.3V25.9H9.3V38.9z M26.4,38.9h14.3v-5.1H26.4V38.9z M26.4,31h14.3v-5.1H26.4V31z M9.3,23.2h31.4v-5.5H9.3V23.2z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStatement",
};
</script>

<style scoped>
#CustomIconStatement {
  fill: currentColor;
}
</style>

import initialFormsTest from "./data.test";

let test = false;

let initialForms = {
  id: null,
  patient_id: null,
  code: null,
  created: false,
  status: false,
  draft_note: "",
  is_homeless: false,
  patient: {
    billingCompanyOwner: { name: "Select", id: "0" },
    company_id: null,
    ssn: "",
    patientNo: "",
    driverLicense: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    sex: "",
    maritalStatus: "",
    spuseName: "",
    spuseWork: "",
    spuseWorkPhone: "",
    referenceNumber: "",
    doctorNumber: "",
    preferred_language: "en",
    suffix: "",
    avatar: "",
    deceased: false,
    deceased_date: "",
    create_user: false,
  },
  contact: {
    phone: "",
    fax: "",
    mobile: "",
    email: "",
    country: "",
  },
  addresses: [
    {
      address_type_id: 2,
      address: "",
      apt_suite: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      main_address: true,
      address_types: [],
    },
  ],
  insurancePolicy: [
    {
      policyNumber: "",
      groupNumber: "",
      insuranceCompany: { name: "Select", id: "0" },
      insurancePlans: [],
      insurancePlan: { name: "Select", id: "0" },
      type_responsibility_id: "",
      insurance_policy_type_id: "",
      relationship_id: "",
      date_of_birth: "",
      effDate: "",
      endDate: "",
      complementary_policy_id: "",
      dual_plan: "",
      releaseInfo: "",
      assignBenefits: "",
      ownInsurance: true,
      suscriber: { label: "Select", value: "0" },
      ssn: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      fax: "",
      mobile: "",
      email: "",
    },
  ],
  injuries: [
    {
      injuryDate: "",
      diagnosis: { name: "Select", id: "0" },
      typeDiagnosis: { name: "Select", id: "0" },
      noteInjury: "",
    },
  ],
  guarantor: {
    needGuardian: false,
    name: "",
    phone: "",
  },
  employer: [
    {
      name: "",
      position: "",
      address: "",
      phone: "",
    },
  ],
  emergencyContact: [
    {
      name: "",
      cellphone: "",
      relationship_id: "",
    },
  ],
  socialNetwork: [
    {
      name: "",
      link: "",
    },
  ],
  notes: {
    public: "",
    private: "",
  },

  valid: {
    patient: false,
    contact: false,
    insurancePolicy: false,
    guarantor: false,
    emergencyContact: false,
  },

  readonly: false,
};

const setInitialForms = () => {
  return {
    id: null,
    patient_id: null,
    code: null,
    created: false,
    draft_note: "",
    status: false,
    is_homeless: false,
    patient: {
      billingCompanyOwner: { name: "Select", id: "0" },
      company_id: null,
      ssn: "",
      patientNo: "",
      driverLicense: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      sex: "",
      maritalStatus: 1,
      spuseName: "",
      spuseWork: "",
      spuseWorkPhone: "",
      referenceNumber: "",
      doctorNumber: "",
      preferred_language: "en",
      suffix: "",
      avatar: "",
      deceased: false,
      deceased_date: "",
      create_user: false,
    },
    contact: {
      phone: "",
      fax: "",
      mobile: "",
      email: "",
      country: "",
    },
    addresses: [
      {
        address_type_id: 2,
        address: "",
        apt_suite: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        main_address: true,
        address_types: [],
      },
    ],
    insurancePolicy: [
      {
        policyNumber: "",
        groupNumber: "",
        insuranceCompany: { name: "Select", id: "0" },
        insurancePlans: [],
        insurancePlan: { name: "Select", id: "0" },
        type_responsibility_id: "",
        insurance_policy_type_id: "",
        relationship_id: "",
        date_of_birth: "",
        effDate: "",
        endDate: "",
        complementary_policy_id: "",
        dual_plan: "",
        releaseInfo: "",
        assignBenefits: "",
        ownInsurance: true,
        suscriber: { label: "Select", value: "0" },
        ssn: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        fax: "",
        mobile: "",
        email: "",
      },
    ],
    injuries: [
      {
        injuryDate: "",
        diagnosis: { name: "Select", id: "0" },
        typeDiagnosis: { name: "Select", id: "0" },
        noteInjury: "",
      },
    ],
    guarantor: {
      needGuardian: false,
      name: "",
      phone: "",
    },
    employer: [
      {
        name: "",
        position: "",
        address: "",
        phone: "",
      },
    ],
    emergencyContact: [
      {
        name: "",
        cellphone: "",
        relationship_id: "",
      },
    ],
    socialNetwork: [
      {
        name: "",
        link: "",
      },
    ],
    notes: {
      public: "",
      private: "",
    },

    valid: {
      patient: false,
      contact: false,
      insurancePolicy: false,
      guarantor: false,
      emergencyContact: false,
    },

    readonly: false,
    readonly_create: false,
  };
};

if (test) {
  initialForms = initialFormsTest;
}

let formData = setInitialForms();

const setFormData = (data) => {
  Object.assign(formData, {
    id: data?.id ?? null,
    code: null,
    patient_id: data?.patient_id ?? null,
    created: false,
    draft_note: "",
    status: false,
    is_homeless: data?.is_homeless ?? false,
    patient: {
      billingCompanyOwner: { name: "Select", id: "0" },
      company_id: null,
      ssn: data?.user?.profile?.ssn ?? "",
      patientNo: "",
      driverLicense: data?.driver_license ?? "",
      firstName: data?.user?.profile?.first_name ?? "",
      middleName: data?.user?.profile?.middle_name ?? "",
      lastName: data?.user?.profile?.last_name ?? "",
      dateOfBirth: data?.user?.profile?.date_of_birth ?? "",
      sex: data?.user?.profile?.sex ?? "",
      maritalStatus: data?.marital_status_id ?? "",
      spuseName: data?.marital?.spuse_name ?? "",
      spuseWork: data?.marital?.spuse_work ?? "",
      spuseWorkPhone: data?.marital?.spuse_work_phone ?? "",
      referenceNumber: "",
      doctorNumber: "",
      preferred_language: data?.profile?.language ?? "",
      suffix: "",
      avatar: data?.user?.profile?.avatar ?? null,
      deceased: false,
      deceased_date: "",
      create_user: false,
    },
    contact: {
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      fax: "",
      mobile: "",
      email: "",
      country: "",
    },
    insurancePolicy: [
      {
        policyNumber: "",
        insuranceCompany: { name: "Select", id: "0" },
        insurancePlans: [],
        insurancePlan: { name: "Select", id: "1" },
        copay: "",
        groupNumber: "",
        effDate: "",
        endDate: "",
        complementary_policy_id: "",
        complementary_policy: "",
        dual_plan: "",
        releaseInfo: "",
        assignBenefits: "",
        ownInsurance: true,
        suscriber: { label: "Select", value: "1" },
        ssn: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        fax: "",
        mobile: "",
        email: "",
      },
    ],
    injuries: [
      {
        injuryDate: "",
        diagnosis: { name: "Select", id: "0" },
        typeDiagnosis: { name: "Select", id: "0" },
        noteInjury: "",
      },
    ],
    guarantor: {
      needGuardian: false,
      name: "",
      phone: "",
    },
    employer: [
      {
        name: "",
        position: "",
        address: "",
        phone: "",
      },
    ],
    emergencyContact: [
      {
        name: "",
        cellphone: "",
        relationship_id: "",
      },
    ],
    socialNetwork: [
      {
        name: "",
        link: "",
      },
    ],
    notes: {
      public: "",
      private: "",
    },

    valid: {
      patient: false,
      contact: false,
      insurancePolicy: false,
      guarantor: false,
      emergencyContact: false,
    },

    readonly: false,
    readonly_create: true,
  });
};

const setPatient = (data) => {
  let billing_company = data?.billing_company ?? null;

  let patient = {
    billingCompanyOwner: {
      name: billing_company?.name ?? "",
      id: billing_company?.id ?? "",
    },
    company_id: data?.company?.id ?? "",
    ssn: data?.profile?.ssn ?? "",
    patientNo: "",
    driverLicense: data?.driver_license ?? "",
    firstName: data?.profile?.first_name ?? "",
    middleName: data?.profile?.middle_name ?? "",
    lastName: data?.profile?.last_name ?? "",
    dateOfBirth: data?.profile?.date_of_birth ?? "",
    sex: data?.profile?.sex ?? "",
    maritalStatus: data?.marital_status_id ?? "",
    spuseName: data?.marital?.spuse_name ?? "",
    spuseWork: data?.marital?.spuse_work ?? "",
    spuseWorkPhone: data?.marital?.spuse_work_phone ?? "",
    referenceNumber: "",
    doctorNumber: data?.med_num ?? "",
    preferred_language: data?.profile?.language ?? "",
    suffix: data?.profile?.name_suffix_id ?? "",
    avatar: data?.profile?.avatar ?? null,
    deceased: data?.profile?.deceased ?? false,
    deceased_date: data?.profile?.deceased_date ?? "",
    create_user: data?.has_user ?? false,
  };

  let contact = {
    phone: data?.contact?.phone ?? "",
    fax: data?.contact?.fax ?? "",
    mobile: data?.contact?.mobile ?? "",
    email: data?.contact?.email ?? "",
    country: "",
  };

  let addresses = [];

  if (data && data.addresses) {
    data.addresses.map((item) => {
      addresses.push({
        address_type_id: item.address_type_id,
        address: item.address,
        apt_suite: item.apt_suite,
        city: item.city,
        state: item.state,
        zip: item.zip,
        country: item.country,
        main_address: item.main_address,
        address_types: [],
      });
    });
  }

  let insurancePolicy = [];

  if (data && data.insurance_policies) {
    data.insurance_policies.map((item) => {
      let subscriber = item?.subscriber[0] ?? null,
        subscriberSelect = subscriber
          ? { label: "Other", value: "2" }
          : { label: "Select", value: "1" };

      insurancePolicy.push({
        policyNumber: item.policy_number,
        insuranceCompany: {
          name: item.insurance_company,
          id: item.insurance_company_id,
        },
        insurancePlans: [
          {
            name: item.insurance_plan,
            id: item.insurance_plan_id,
          },
        ],
        insurancePlan: {
          name: item.insurance_plan,
          id: item.insurance_plan_id,
        },
        type_responsibility_id: item.type_responsibility_id,
        insurance_policy_type_id: item.insurance_policy_type_id,
        relationship_id: subscriber?.relationship_id ?? "",
        date_of_birth: subscriber?.date_of_birth ?? "",
        copay: "",
        groupNumber: item.group_number,
        effDate: item.eff_date,
        endDate: item.end_date,
        complementary_policy_id: item.complementary_policy_id,
        complementary_policy: item?.complementary_policy ?? "",
        dual_plan: item.dual_plan,
        releaseInfo: item.release_info,
        assignBenefits: item.assign_benefits,
        ownInsurance: item.own_insurance,
        suscriber: subscriberSelect,
        ssn: subscriber?.ssn ?? "",
        firstName: subscriber?.first_name ?? "",
        lastName: subscriber?.last_name ?? "",
        address: subscriber?.address?.address ?? "",
        city: subscriber?.address?.city ?? "",
        state: subscriber?.address?.state ?? "",
        zip: subscriber?.address?.zip ?? "",
        phone: subscriber?.contact?.phone ?? "",
        fax: subscriber?.contact?.fax ?? "",
        mobile: subscriber?.contact?.mobile ?? "",
        email: subscriber?.contact?.email ?? "",
      });
    });
  }

  let injuries = [
    {
      injuryDate: "",
      diagnosis: { name: "Select", id: "0" },
      typeDiagnosis: { name: "Select", id: "0" },
      noteInjury: "",
    },
  ];

  let guarantor = {
    needGuardian: data?.guarantor ? true : false,
    name: data?.guarantor?.name ?? "",
    phone: data?.guarantor?.phone ?? "",
  };

  let employer = [];

  if (data && data.employments) {
    if (data.employments.length === 0) {
      employer.push({
        name: "",
        position: "",
        address: "",
        phone: "",
      });
    }

    data.employments.map((item) => {
      employer.push({
        name: item.employer_name,
        position: item.position,
        address: item.employer_address,
        phone: item.employer_phone,
      });
    });
  }

  let emergencyContact = [];

  if (data && data.emergency_contacts) {
    if (data.emergency_contacts.length === 0) {
      emergencyContact.push({
        name: "",
        cellphone: "",
        relationship_id: "",
      });
    }

    data.emergency_contacts.map((item) => {
      emergencyContact.push({
        name: item.name,
        cellphone: item.cellphone,
        relationship_id: item.relationship_id,
      });
    });
  }

  let socialNetwork = [];

  if (data && data.social_medias) {
    if (data.social_medias.length === 0) {
      socialNetwork.push({
        name: "",
        link: "",
      });
    }

    data.social_medias.map((item) => {
      socialNetwork.push({
        name: item.social_network,
        link: item.link,
      });
    });
  }

  let notes = {
    public: data?.public_note ?? "",
    private: data?.note ?? "",
  };

  let valid = {
    contact: true,
    insurancePolicy: true,
    guarantor: true,
    emergencyContact: true,
  };

  return {
    id: data?.id ?? null,
    code: data?.code ?? null,
    created: true,
    status: data?.status ?? false,
    draft_note: "",
    patient: patient,
    is_homeless: data?.is_homeless ?? false,
    contact: contact,
    addresses: addresses,
    insurancePolicy: insurancePolicy,
    injuries: injuries,
    guarantor: guarantor,
    employer: employer,
    emergencyContact: emergencyContact,
    socialNetwork: socialNetwork,
    notes: notes,
    valid: valid,
    readonly: false,
  };
};

export default {
  initialForms,
  formData,
  setFormData,
  setPatient,
  setInitialForms,
};

import { DateTime } from "luxon";

export const dataTable = (data) => {
  let patients = [];

  data.map((patient) => {
    patients.push({
      id: patient.id,
      full_name: patient.profile.full_name,
      code: patient.code,
      dob: patient.profile?.date_of_birth
        ? DateTime.fromISO(patient.profile.date_of_birth)
            .setLocale("en")
            .toFormat("LL/dd/yyyy")
        : "-",
      email: patient?.user?.email ?? "",
      medical_number: patient.med_num ?? "-",
      ssn: patient.profile.ssn,
      billing_company: patient?.billing_company?.name ?? "-",
      company: patient?.company?.name ?? "-",
      avatar: patient?.profile?.avatar ?? null,
      status: patient.status ?? false,
    });
  });

  return patients;
};

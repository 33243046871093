export const setGuarantor = (data) => {
  if (data?.guarantor?.needGuardian) {
    return {
      name: data.guarantor.name,
      phone: data.guarantor.phone,
    };
  }

  return {
    name: "",
    phone: "",
  };
};

import axios from "../../plugins/axios";

const state = () => ({
  billingCompany: {},
  showModalCreateBillingCompany: false,
  currentData: [],
  countries: [],
  permissions: {
    create: false,
    disable: false,
    edit: false,
    history: false,
    show: false,
    view: false,
  },
  pivoteRequest: null,
});

const mutations = {
  setBillingCompany(state, billingCompany) {
    state.billingCompany = billingCompany;
  },

  setShowModalCreateBillingCompany(state, showModalCreateBillingCompany) {
    state.showModalCreateBillingCompany = showModalCreateBillingCompany;
  },

  setCountries(state, countries) {
    state.countries = countries;
  },

  setPermissions(state, menu) {
    let menuPermissions = menu["billing company management"];

    Object.assign(state.permissions, {
      create: menuPermissions.create,
      disable: menuPermissions.disable,
      edit: menuPermissions.edit,
      history: menuPermissions.history,
      show: menuPermissions.show,
      view: menuPermissions.view,
    });
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getBillingCompanies(_, params) {
    let query = params.query ? params.query : "";
    const options = {
      params: {
        query: query,
        itemsPerPage: params.itemsPerPage,
        page: params.page,
        sortDesc: params.sortDesc,
        sortBy: params.sortBy,
        ...params.searchOn,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company/get-all-server", options)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getBillingCompany({ commit }, company_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company/${company_id}`)
        .then((result) => {
          commit("setBillingCompany", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getAllBillingCompanies(_, options) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company/get-all-server`, { params: options })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  storeBillingCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/billing-company/create", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateBillingCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/billing-company/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateLogo(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/billing-company/upload-image`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateStatusBillingCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/billing-company/change-status/${data.id}`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getListBillingCompany() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company/get-list`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  setShowModalCreateBillingCompany({ commit }, showModalCreateBillingCompany) {
    commit("setShowModalCreateBillingCompany", showModalCreateBillingCompany);
  },

  searchBillingCompanyByTaxID(_, taxId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company/${taxId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/address/get-list-countries")
        .then((result) => {
          commit("setCountries", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  setAuthUser({ commit }, auth) {
    commit("setPermissions", auth.menu);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const state = () => ({
  paymentStatuses: [],
  billingCompanies: [],
  companies: [],
  sources: [],
  insurancePlans: [],
  paymentMethods: [],
  batch: {},
  loadingBatch: false,
  loadingEobs: false,
  loadingClaims: false,
  loadingStoreBatch: false,
  loadingStoreServices: false,
  eobs: [],
  getBatchs: null,
  date_closed: {
    value: "",
    isValid: false,
  },
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },
  permissions: {
    create: false,
    view: false,
    show: false,
    edit: false,
    disable: false,
    history: false,
    closeBatch: false,
    eob: false,
    removeBatch: false,
    generate: false,
    send: false,
    editComplete: false,
  },
  selectedClaims: [],
  files: [],
  isPaymentTableModalOpen: false,
  pivoteRequest: null,
  userType: null,
  dataToSave: [],
  lastDataToSave: [],
  pendingToDistribute: [],
});

const mutations = {
  setPaymentStatuses(state, paymentStatuses) {
    state.paymentStatuses = paymentStatuses;
  },

  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
    state.listBillingCompanies = billingCompanies.map((billing) => {
      billing.name = `${billing.abbreviation} - ${billing.name}`;
      return billing;
    });
  },

  setCompanies(state, companies) {
    state.companies = [];

    state.companies = companies.map((company) => {
      company.name =
        company.abbreviation && company.abbreviation !== ""
          ? `${company.abbreviation} - ${company.name}`
          : company.name;
      return company;
    });
  },

  setSources(state, sources) {
    state.sources = sources;
  },

  setInsurancePlans(state, insurancePlans) {
    state.insurancePlans = insurancePlans;
  },

  setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },

  setAdjustmentReasonCodes(state, adjustmentReasonCodes) {
    state.adjustmentReasonCodes = adjustmentReasonCodes;
  },

  setBatch(state, batch) {
    state.batch = batch;
  },

  setLoadingBatch(state, loading) {
    state.loadingBatch = loading;
  },

  setLoadingEobs(state, loading) {
    state.loadingEobs = loading;
  },

  setLoadingClaims(state, loading) {
    state.loadingClaims = loading;
  },

  setLoadingStoreBatch(state, loading) {
    state.loadingStoreBatch = loading;
  },

  setLoadingStoreServices(state, loading) {
    state.loadingStoreServices = loading;
  },

  setEobs(state, eobs) {
    state.eobs = eobs;
  },

  setGetBatchs(state, getBatchs) {
    state.getBatchs = getBatchs;
  },

  setDateClosed(state, date_closed) {
    state.date_closed = date_closed;
  },

  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },

  setPermission(state, permissions) {
    state.permissions = permissions;
  },

  setSelectedClaims(state, selectedClaims) {
    state.selectedClaims = selectedClaims;
  },

  addFile(state, file) {
    state.files.push(file);
  },

  setIsPaymentTableModalOpen(state, isPaymentTableModalOpen) {
    state.isPaymentTableModalOpen = isPaymentTableModalOpen;
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },

  setUserType(state, userType) {
    state.userType = userType;
  },

  setSavOrDelIds(state, data) {
    const { paymentId, claimId, services, isSaving } = data;

    const index = state.dataToSave.findIndex(
      (obj) => obj.paymentId === paymentId && obj.claimId === claimId
    );

    if (isSaving && index === -1)
      state.dataToSave.push({ paymentId, claimId, services });

    if (!isSaving && index !== -1) state.dataToSave.splice(index, 1);
  },

  setLastDataToSave(state, data) {
    state.lastDataToSave = [];
    state.lastDataToSave = data;
  },

  setClearData(state) {
    state.dataToSave = [];
    state.lastDataToSave = [];
  },

  setAllPendingToDistribute(state, pendingToDistribute) {
    state.pendingToDistribute = pendingToDistribute;
  },

  setOnePendingToDistribute(state, data) {
    const pendingToDistribute = data.pendingToDistribute;
    const index = data.index;

    state.pendingToDistribute.splice(index, 1, pendingToDistribute);
  },
};

const actions = {
  // Get all eras of server
  getAllEras(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/eras", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get check New
  getCheckNew() {
    return new Promise((resolve, reject) => {
      axios
        .post("/payments/eras/sync")
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get eras deleted
  eraArchive(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/eras/archive", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Delete - restore ERA
  deleteRestoreEra(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payments/eras", payload)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  // Preview ERA
  getPreviewEra(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/payments/eras/${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  // Process ERAs
  processEras(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payments/eras/process", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get all baches states list
  getBatchStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/batch/states")
        .then((result) => {
          commit("setPaymentStatuses", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get all payments sources list
  getSources({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/sources")
        .then((result) => {
          commit("setSources", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get all payments methods list
  getPaymentMethods({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/methods")
        .then((result) => {
          commit("setPaymentMethods", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // This is the view for the eob file
  getEobFile(_, data) {
    console.log(data);
    // /payments/eob/{eob_file}
  },

  // Get list of all payments batches
  getAllPaymentBatches(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/batch", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get all of batch information
  getBatchId({ commit }, batchId) {
    commit("setLoadingBatch", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`/payments/batch/${batchId}`)
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        })
        .then(() => {
          commit("setLoadingBatch", false);
        });
    });
  },

  // Store batch
  storeBatch({ commit }, data) {
    commit("setLoadingStoreBatch", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/payments/batch", data)
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        })
        .then(() => {
          commit("setLoadingStoreBatch", false);
        });
    });
  },

  /*
  getBatch({ commit }, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/batch/${batchId}`)
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  */

  // Get all billing companies
  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get a billing company by id
  getCompaniesByBillingId({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/get-list-by-billing-company/${billingCompanyId}?batch=${true}`
        )
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get list insurance plans
  getInsurancePlans({ commit }, billingCompanyId = 0) {
    return new Promise((resolve, reject) => {
      const params = {
        params: {
          groupBy: true,
          billing_company_id: billingCompanyId,
          simple: true,
        },
      };

      axios
        .get(`/insurance-plan/get-list`, params)
        .then((result) => {
          commit("setInsurancePlans", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Get adjustment reason code
  getAdjustmentReasonCodes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/payments/codes`)
        .then((result) => {
          commit("setAdjustmentReasonCodes", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  /*deleteBatch(_, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/claim/batch/${batchId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },*/

  /*getRecords(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/claim-batch/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },*/

  // Update batch
  updateBatch({ commit }, data) {
    commit("setLoadingStoreBatch", true);
    return new Promise((resolve, reject) => {
      axios
        .put(`/payments/batch/${data.id}`, data)
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error))
        .then(() => {
          commit("setLoadingStoreBatch", false);
        });
    });
  },

  // Update store batch claims
  updateBatchClaims({ commit }, data) {
    commit("setLoadingClaims", true);
    return new Promise((resolve, reject) => {
      axios
        .post(`/payments/batch/${data.id}/claims`, data)
        .then((result) => {
          commit("setBatch", result.data.data);
          resolve(result.data);
        })
        .catch((error) => reject(error))
        .then(() => {
          commit("setLoadingClaims", false);
        });
    });
  },

  // Add a claim to an existing batch
  addBatchClaim({ commit }, data) {
    commit("setLoadingClaims", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/payments/batch/${data.batchId}/payment/${data.paymentId}/claims/${data.claimId}`,
          data
        )
        .then((result) => {
          commit("setBatch", result.data.data);
          resolve(result.data);
        })
        .catch((error) => reject(error))
        .then(() => {
          commit("setLoadingClaims", false);
        });
    });
  },

  // Delete a claim from an existing batch
  deleteBatchClaim({ commit }, data) {
    commit("setLoadingClaims", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/payments/batch/${data.batchId}/payment/${data.paymentId}/claims/${data.claimId}`,
          data
        )
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error))
        .then(() => {
          commit("setLoadingClaims", false);
        });
    });
  },

  // Store/Update batch services
  updateServiceClaims({ commit }, data) {
    commit("setLoadingStoreServices", true);
    return new Promise((resolve, reject) => {
      axios
        .post(`/payments/batch/${data.id}/services`, data)
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error))
        .then(() => {
          commit("setLoadingStoreServices", false);
        });
    });
  },

  // Store/Update batch services (Modification)
  updateServicesClaim({ commit }, data) {
    commit("setLoadingStoreServices", true);
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/payments/batch/${data.batchId}/services/${data.paymentId}/claims/${data.claimId}`,
          data.services
        )
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error))
        .then(() => {
          commit("setLoadingStoreServices", false);
        });
    });
  },

  // Update claim status
  updateStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/change-status/${data.id}`, data.status)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  /*getPreviewBatch(_, payload) {
    return new Promise((resolve, reject) => {
      let url = payload.printBatch
        ? `/claim/batch/show-batch-preview/${payload.id}?print=${true}`
        : `/claim/batch/show-batch-preview/${payload.id}`;
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },*/

  /*getReport(_, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/batch/show-batch-report/${batchId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },*/

  // Store batch
  storeEOB(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/payments/batch/${data.batchId}/payments/${data.paymentId}/eobs`,
          data.body
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateEOB(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/payments/batch/${data.batchId}/payments/${data.paymentId}/eobs/${data.eobId}?_method=put`,
          data.body
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getEobsByBatchId({ commit }, batchId) {
    commit("setLoadingEobs", true);
    commit("setEobs", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/payments/batch/${batchId}/eobs`)
        .then((result) => {
          commit("setEobs", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        })
        .then(() => {
          commit("setLoadingEobs", false);
        });
    });
  },

  getEobByURL(_, eob_file) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${eob_file}`, {
          responseType: "arraybuffer",
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteEOB(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/payments/batch/${data.batchId}/payments/${data.paymentId}/eobs/${data.eobId}`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  deleteBatch(_, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/payments/batch/${batchId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  closeBatch(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`payments/batch/${data.batchId}/close`, data.body)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  setPermissionType({ commit }, roles) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };

    if (roles) {
      roles.find((role) => {
        if (role.name === "Super User") {
          permissionType.isSuperUser = true;
        }

        if (role.name === "Billing Manager") {
          permissionType.isBillingManager = true;
        }
      });
    }

    commit("setPermissionType", permissionType);
  },

  setPermission({ commit }, menu) {
    let permissions = menu["payments management"];

    commit("setPermission", {
      create: Boolean(permissions.create),
      view: Boolean(permissions.view),
      show: Boolean(permissions.show),
      edit: Boolean(permissions.edit),
      disable: Boolean(permissions.disable),
      history: Boolean(permissions.history),
      closeBatch: Boolean(permissions["close batch"]),
      eob: Boolean(permissions.eob),
      removeBatch: Boolean(permissions["remove batch"]),
      generate: Boolean(permissions["generate patient accounts statements"]),
      send: Boolean(permissions["send co-pays and co-insurance"]),
      editComplete: Boolean(permissions["update batch in status complete"]),
    });
  },

  getPoliciesInsurance(_, patientData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/${patientData.patient_id}/get-policies`, patientData)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addFile({ commit }, file) {
    return new Promise(() => {
      commit("addFile", file);
    });
  },

  savOrDelIds({ commit }, data) {
    commit("setSavOrDelIds", data);
  },

  saveLastData({ commit }, data) {
    commit("setLastDataToSave", data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (data) resolve();
        else reject("error");
      }, 300);
    });
  },

  clearData({ commit }) {
    commit("setClearData");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

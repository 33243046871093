import axios from "@/plugins/axios";
import { setName } from "@/utils/admission_encounter/validAdmission";

const state = () => ({
  programs: [],
  listBillingCompanies: [],
  listBillingCompaniesAbbre: [],
  listCompanies: [],
  listCompaniesAbbre: [],
  listFacilities: [],
  listFacilitiesAbbre: [],
  listPatients: [],
  listPlaceOfServices: [],
  listUnits: [],
  listRooms: [],
  healthProfessionals: [],
  datesOther: {
    from: null,
    to: null,
    unit: null,
  },
  datesAdmDis: {
    admission: null,
    discharge: null,
  },
  listAssignments: [],
  listReleases: [],
  fields: [],
  pivoteRequest: null,
});

const mutations = {
  setPrograms(state, programs) {
    state.programs = programs;
  },

  setBillingCompanies(state, billings) {
    state.listBillingCompanies = billings;
    state.listBillingCompaniesAbbre = billings.map((billing) => {
      billing.name = `${billing.abbreviation} - ${billing.name}`;
      return billing;
    });
  },

  setCompanies(state, companies) {
    state.listCompanies = companies;
    state.listCompaniesAbbre = companies.map((company) => {
      company.name = `${company.abbreviations} - ${company.name}`;
      return company;
    });
  },

  setFacilities(state, facilities) {
    state.listFacilities = [];
    state.listFacilitiesAbbre = [];

    facilities.map((facility) => {
      state.listFacilities.push({
        id: facility.id,
        name: facility.name,
      });

      state.listFacilitiesAbbre.push({
        id: facility.id,
        name: facility.abreviation
          ? `${facility.abreviation} - ${facility.name}`
          : facility.name,
      });
    });
  },

  setPatients(state, listPatients) {
    state.listPatients = listPatients;
  },

  setPlaceOfServices(state, listPlaceOfServices) {
    state.listPlaceOfServices = [];
    state.listPlaceOfServices = listPlaceOfServices;
  },

  setUnits(state, listUnits) {
    state.listUnits = [];
    state.listUnits = listUnits;
  },

  setRooms(state, listRooms) {
    state.listRooms = [];
    state.listRooms = listRooms.map((room) => {
      const code = room.code ?? "-";
      const name = room.name ?? "-";
      const ocupation =
        room.ocupation !== null && room.ocupation !== "" ? room.ocupation : "-";
      const capacity =
        room.capacity !== null && room.capacity !== "" ? room.capacity : "-";

      room.nameRoom = `${code} ~ ${name} ~ ${ocupation}/${capacity}`;
      return room;
    });
  },

  setHealthProfessionals(state, healthProfessionals) {
    state.healthProfessionals = [];

    state.healthProfessionals = healthProfessionals.map((health) => {
      health.name =
        health.npi && health.npi !== ""
          ? `${health.npi} - ${health.name}`
          : health.name;
      return health;
    });
  },

  setSaveOtherDates(state, payload) {
    if (payload.date) {
      state.datesOther.from = payload.from ?? null;
      state.datesOther.to = payload.to ?? null;
    } else {
      state.datesOther.unit = payload.unit ?? null;
    }
  },

  setSaveAdmDisDates(state, payload) {
    state.datesAdmDis = payload;
  },

  setAssignments(state, listAssignments) {
    state.listAssignments = [];
    state.listAssignments = listAssignments;
  },

  setReleases(state, listReleases) {
    state.listReleases = [];
    state.listReleases = listReleases;
  },

  setFields(state, fields) {
    state.fields = [];
    state.fields = fields;
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getAllAdmissions(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admission", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  registerAdmission(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admission/", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getAdmissionById(_, admissionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admission/${admissionId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateAdmission(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admission/${data.id}`, data)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  deleteAdmission(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admission/${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  dischargeAdmission(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admission/${id}/discharge`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPrograms({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admission/programs")
        .then((result) => {
          commit("setPrograms", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company/get-list`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompanies({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = `company/get-all-server?wheres[program]=${data.program}&billing_company_id=${data.id}&return=list`;
      axios
        .get(url)
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getFacilities({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/facility/get-list", { params: data })
        .then((result) => {
          commit("setFacilities", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/patient/get-list", { params: data })
        .then((result) => {
          commit("setPatients", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPlaceOfServices({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-list-place-of-services?facility_id=${data}`)
        .then((result) => {
          commit("setPlaceOfServices", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getUnits({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/unit/get-list", { params: data })
        .then((result) => {
          commit("setUnits", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getRooms({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/unit/get-list-rooms?unit_id=${id}`)
        .then((result) => {
          commit("setRooms", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getHealthProfessionals({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/health-professional/get-list", { params: data })
        .then((result) => {
          commit("setHealthProfessionals", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchRevenueByCode(_, payload) {
    return new Promise((resolve, reject) => {
      const url = payload.procedureId
        ? `/admission/revenue-codes?search=${payload.search}&company_id=${payload.companyId}&procedure_id=${payload.procedureId}&program=${payload.program}`
        : payload.search
        ? `/admission/revenue-codes?search=${payload.search}&company_id=${payload.companyId}&program=${payload.program}`
        : `/admission/revenue-codes?company_id=${payload.companyId}&program=${payload.program}`;

      axios
        .get(url)
        .then((result) => {
          result.data = setName(result.data, "procedures");
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchProcedureByCode(_, payload) {
    return new Promise((resolve, reject) => {
      const url = payload.revenueId
        ? `/admission/procedures/${payload.companyId}?search=${payload.search}&revenue_code_id=${payload.revenueId}&program=${payload.program}`
        : payload.search
        ? `/admission/procedures/${payload.companyId}?search=${payload.search}&program=${payload.program}`
        : `/admission/procedures/${payload.companyId}?program=${payload.program}`;

      axios
        .get(url)
        .then((result) => {
          result.data = setName(result.data, "revenue_codes");
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getQualifiers(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admission/health-care-qualifiers?program=${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getFields({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admission/fields?program=${id}`)
        .then((result) => {
          commit("setFields", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getQualifiersOtherInfo(_, data) {
    return new Promise((resolve, reject) => {
      const url = `admission/qualifiers?program=${data.program}&field_id=${data.field_id}`;
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPolicies(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admission/${data.patient}/policies`, { params: data.payload })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getAssignments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("admission/signed-assignments")
        .then((result) => {
          commit("setAssignments", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getReleases({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("admission/information-releases")
        .then((result) => {
          commit("setReleases", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  saveNote(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admission/${data.idAdmission}/notes/`, data.payload)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateNote(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/admission/${data.idAdmission}/notes/${data.idNote}`,
          data.payload
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  deleteNote(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admission/${data.idAdmission}/notes/${data.idNote}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  saveTracking(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admission/${data.idAdmission}/tracking`, data.payload)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateTracking(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/admission/${data.idAdmission}/tracking/${data.idTracking}`,
          data.payload
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  deleteTracking(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admission/${data.idAdmission}/tracking/${data.idTracking}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  saveOtherDates({ commit }, payload) {
    commit("setSaveOtherDates", payload);
  },

  saveAdmDisDates({ commit }, payload) {
    commit("setSaveAdmDisDates", payload);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

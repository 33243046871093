let initialForms = {
  id: "",
  code: "",
  public_note: "",
  driver_license: "",
  language: "",
  profile: {
    ssn: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    date_of_birth: "",
    sex: "",
  },
  billing_companies: [],
};

let formData = initialForms;

const setFormData = (data) => {
  let profile = {},
    billing_companies = data?.billing_companies ?? [];

  billing_companies.map((billingCompany) => {
    if (billingCompany.private_patient.emergency_contacts.length === 0) {
      billingCompany.private_patient.emergency_contacts.push({
        name: "",
        cellphone: "",
        relationship_id: "",
      });
    }

    if (billingCompany.private_patient.employments.length === 0) {
      billingCompany.private_patient.employments.push({
        employer_name: "",
        position: "",
        employer_address: "",
        employer_phone: "",
      });
    }

    if (billingCompany.private_patient.social_medias.length === 0) {
      billingCompany.private_patient.social_medias.push({
        name: "",
        link: "",
      });
    }
  });

  Object.assign(profile, {
    ssn: data?.profile?.ssn ?? "",
    first_name: data?.profile?.first_name ?? "",
    middle_name: data?.profile?.middle_name ?? "",
    last_name: data?.profile?.last_name ?? "",
    date_of_birth: data?.profile?.date_of_birth ?? "",
    sex: data?.profile?.sex ?? "",
    suffix: data?.profile?.name_suffix_id ?? "",
    suffix_name: data?.profile?.name_suffix ?? "",
    avatar: data?.profile?.avatar ?? null,
    deceased: data?.profile?.deceased ?? false,
    deceased_date: data?.profile?.deceased_date ?? "",
    create_user: data?.has_user ?? false,
  });

  Object.assign(formData, {
    id: data?.id ?? "",
    code: data?.code ?? "",
    public_note: data?.public_note ?? "",
    driver_license: data?.driver_license ?? "",
    language: data?.profile?.language ?? "",
    profile: profile,
    billing_companies: billing_companies,
  });
};

const setFormDataEdit = (data, billingCompanyId = null) => {
  let billingCompanyKey = null;

  if (billingCompanyId) {
    data.billing_companies.map((item, key) => {
      if (item.id === billingCompanyId) {
        billingCompanyKey = key;
      }
    });
  }

  let billing_company = data?.billing_companies[billingCompanyKey] ?? null,
    private_patient =
      data?.billing_companies[billingCompanyKey]?.private_patient ?? null;

  let patient = {
    billingCompanyOwner: {
      name: billing_company?.name ?? "",
      id: billing_company?.id ?? "",
    },
    company_id: private_patient?.companies[0]?.company_id ?? "",
    ssn: data?.profile?.ssn ?? "",
    patientNo: "",
    driverLicense: data?.driver_license ?? "",
    firstName: data?.profile?.first_name ?? "",
    middleName: data?.profile?.middle_name ?? "",
    lastName: data?.profile?.last_name ?? "",
    dateOfBirth: data?.profile?.date_of_birth ?? "",
    sex: data?.profile?.sex ?? "",
    maritalStatus: private_patient?.marital_status_id ?? "",
    spuseName: private_patient?.marital?.spuse_name ?? "",
    spuseWork: private_patient?.marital?.spuse_work ?? "",
    spuseWorkPhone: private_patient?.marital?.spuse_work_phone ?? "",
    referenceNumber: "",
    doctorNumber: private_patient?.companies[0]?.med_num ?? "",
    preferred_language: data?.profile?.language ?? "",
    suffix: data?.profile?.name_suffix_id ?? "",
    avatar: data?.profile?.avatar ?? null,
    deceased: data?.profile?.deceased ?? false,
    deceased_date: data?.profile?.deceased_date ?? "",
    create_user: data?.has_user ?? false,
  };

  let contact = {
    phone: private_patient?.contact?.phone ?? "",
    fax: private_patient?.contact?.fax ?? "",
    mobile: private_patient?.contact?.mobile ?? "",
    email: private_patient?.contact?.email ?? "",
  };

  let addresses = [];

  if (private_patient && private_patient.addresses) {
    private_patient.addresses.map((item) => {
      addresses.push({
        address_type_id: item.address_type_id,
        address: item.address,
        apt_suite: item.apt_suite,
        city: item.city,
        state: item.state,
        zip: item.zip,
        country: item.country,
        main_address: item.main_address,
      });
    });
  }

  let insurancePolicy = [];

  if (private_patient && private_patient.insurance_policies) {
    private_patient.insurance_policies.map((item) => {
      let subscriber = item?.subscriber[0] ?? null,
        subscriberSelect = subscriber
          ? { label: "Other", value: "2" }
          : { label: "Select", value: "1" };

      insurancePolicy.push({
        policyNumber: item.policy_number,
        insuranceCompany: {
          name: item.insurance_company,
          id: item.insurance_company_id,
        },
        insurancePlans: [
          {
            name: item.insurance_plan,
            id: item.insurance_plan_id,
          },
        ],
        insurancePlan: {
          name: item.insurance_plan,
          id: item.insurance_plan_id,
        },
        type_responsibility_id: item.type_responsibility_id,
        insurance_policy_type_id: item.insurance_policy_type_id,
        relationship_id: subscriber?.relationship_id ?? "",
        date_of_birth: subscriber?.date_of_birth ?? "",
        copay: "",
        groupNumber: item.group_number,
        effDate: item.eff_date,
        endDate: item.end_date,
        releaseInfo: item.release_info,
        assignBenefits: item.assign_benefits,
        ownInsurance: item.own_insurance,
        suscriber: subscriberSelect,
        ssn: subscriber?.ssn ?? "",
        firstName: subscriber?.first_name ?? "",
        lastName: subscriber?.last_name ?? "",
        address: subscriber?.address?.address ?? "",
        city: subscriber?.address?.city ?? "",
        state: subscriber?.address?.state ?? "",
        zip: subscriber?.address?.zip ?? "",
        phone: subscriber?.contact?.phone ?? "",
        fax: subscriber?.contact?.fax ?? "",
        mobile: subscriber?.contact?.mobile ?? "",
        email: subscriber?.contact?.email ?? "",
      });
    });
  }

  let injuries = [
    {
      injuryDate: "",
      diagnosis: { name: "Select", id: "0" },
      typeDiagnosis: { name: "Select", id: "0" },
      noteInjury: "",
    },
  ];

  let guarantor = {
    needGuardian: private_patient?.guarantor ? true : false,
    name: private_patient?.guarantor?.name ?? "",
    phone: private_patient?.guarantor?.phone ?? "",
  };

  let employer = [];

  if (private_patient && private_patient.employments) {
    if (private_patient.employments.length === 0) {
      employer.push({
        name: "",
        position: "",
        address: "",
        phone: "",
      });
    }

    private_patient.employments.map((item) => {
      employer.push({
        name: item.employer_name,
        position: item.position,
        address: item.employer_address,
        phone: item.employer_phone,
      });
    });
  }

  let emergencyContact = [];

  if (private_patient && private_patient.emergency_contacts) {
    if (private_patient.emergency_contacts.length === 0) {
      emergencyContact.push({
        name: "",
        cellphone: "",
        relationship_id: "",
      });
    }

    private_patient.emergency_contacts.map((item) => {
      emergencyContact.push({
        name: item.name,
        cellphone: item.cellphone,
        relationship_id: item.relationship_id,
      });
    });
  }

  let socialNetwork = [];

  if (private_patient && private_patient.social_medias) {
    if (private_patient.social_medias.length === 0) {
      socialNetwork.push({
        name: "",
        link: "",
      });
    }

    private_patient.social_medias.map((item) => {
      socialNetwork.push({
        name: item.social_network,
        link: item.link,
      });
    });
  }

  let notes = {
    public: data?.public_note ?? "",
    private: private_patient?.private_note ?? "",
  };

  let valid = {
    contact: true,
    insurancePolicy: true,
    guarantor: true,
    emergencyContact: true,
  };

  return {
    id: data?.id ?? null,
    code: data?.code ?? null,
    created: true,
    draft_note: "",
    patient: patient,
    contact: contact,
    addresses: addresses,
    insurancePolicy: insurancePolicy,
    injuries: injuries,
    guarantor: guarantor,
    employer: employer,
    emergencyContact: emergencyContact,
    socialNetwork: socialNetwork,
    notes: notes,
    valid: valid,
    readonly: false,
  };
};

const setBillingCompanies = (data) => {
  let billingComapines = [];
  data.billing_companies.map((item) => {
    billingComapines.push({
      id: item.id,
      abbreviation: item.abbreviation ?? "",
      name: `${item.abbreviation} - ${item.name}`,
    });
  });

  return billingComapines;
};

export default {
  initialForms,
  formData,
  setFormData,
  setFormDataEdit,
  setBillingCompanies,
};

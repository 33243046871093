<template>
  <svg
    version="1.1"
    id="CustomIconEOB"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 13.1 16.4"
    style="enable-background: new 0 0 13.1 16.4"
    xml:space="preserve"
  >
    <path
      id="description_FILL0_wght400_GRAD0_opsz48"
      class="st20"
      d="M3.3,12.9h6.6v-1.2H3.3V12.9z M3.3,9.4h6.6V8.2H3.3V9.4z
	 M1.2,16.4c-0.3,0-0.6-0.1-0.9-0.4C0.1,15.8,0,15.5,0,15.2V1.2c0-0.3,0.1-0.6,0.4-0.9C0.6,0.1,0.9,0,1.2,0h7.4l4.5,4.5v10.7
	c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.4-0.9,0.4L1.2,16.4z M8,5V1.2H1.2v13.9h10.7V5L8,5z M1.2,1.2L1.2,1.2z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEOB",
};
</script>

<style scoped>
#CustomIconEOB {
  fill: currentColor;
}
</style>

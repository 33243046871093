<template>
  <svg
    version="1.1"
    id="CustomIconViewLedger"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 31.6"
    style="enable-background: new 0 0 24 31.6"
    xml:space="preserve"
  >
    <path
      id="touch_app_FILL0_wght400_GRAD0_opsz48"
      class="st20"
      d="M11.4,0c2.2,0,4.3,0.8,5.9,2.4c1.6,1.5,2.5,3.6,2.4,5.8
    c0,2.6-1.2,5-3.2,6.6h-1.3v-1.9c0.7-0.6,1.3-1.3,1.7-2.1c0.4-0.8,0.6-1.7,0.6-2.6c0-1.6-0.6-3.1-1.8-4.2c-2.4-2.3-6.1-2.3-8.5,0
    C6,5.1,5.3,6.6,5.3,8.2c0,0.9,0.2,1.8,0.6,2.6c0.4,0.8,1,1.5,1.7,2.1v2.7c-1.4-0.7-2.5-1.8-3.3-3.1c-0.8-1.3-1.2-2.8-1.2-4.4
    c0-2.2,0.8-4.3,2.4-5.8C7.1,0.8,9.2,0,11.4,0z M9.9,31.6c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.7-0.4-1-0.7L0,23l2.1-2.2
    c0.3-0.3,0.7-0.6,1.2-0.8c0.4-0.2,0.9-0.2,1.4-0.1l2.9,0.7V8.3c0-2.1,1.6-3.7,3.7-3.8c0,0,0,0,0.1,0c2.1,0,3.7,1.6,3.8,3.7
    c0,0,0,0,0,0.1v6.5h1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2l5.6,2.7c0.6,0.3,1.1,0.7,1.3,1.3c0.3,0.6,0.4,1.3,0.2,1.9
    l-1.4,8.2c-0.1,0.7-0.5,1.3-1.1,1.8c-0.5,0.5-1.2,0.7-2,0.7L9.9,31.6z M9.6,29.4h10.6l1.6-9.4l-6.9-3.4h-2.1V8.3
    c0-0.8-0.6-1.5-1.5-1.6c-0.8,0-1.5,0.6-1.6,1.5c0,0,0,0.1,0,0.1v15l-5.8-1.2l-0.9,0.9L9.6,29.4z M20.2,29.4L20.2,29.4z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconViewLedger",
};
</script>

<style scoped>
#CustomIconViewLedger {
  fill: currentColor;
}
.st0 {
  fill: #4d4d4d;
}
.st1 {
  fill: #ffffff;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #4ac1e0;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00ccff;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #155dff;
}
.st5 {
  fill: #ff0000;
}
.st6 {
  fill: #00ff00;
}
.st7 {
  fill: #155dff;
}
.st8 {
  fill: #aa0c18;
  stroke: #aa0c18;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #00e2e2;
  stroke: #00e2e2;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #006837;
  stroke: #006837;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #fce906;
  stroke: #fce906;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st12 {
  fill: #ff0000;
  stroke: #ff0000;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #00cc3e;
}
.st14 {
  fill: #fce906;
}
.st15 {
  fill: #880088;
}
.st16 {
  fill: #00e2e2;
}
.st17 {
  fill: #aa0c18;
}
.st18 {
  fill: #f7931e;
}
.st19 {
  fill: #006837;
}
.st20 {
  fill: #808080;
}
.st21 {
  fill: #f2f2f2;
}
.st22 {
  fill: #707070;
}
.st23 {
  fill: #fbecdd;
}
.st24 {
  fill: #b04d12;
}
.st25 {
  fill: #fffaec;
}
.st26 {
  fill: #eac767;
}
.st27 {
  fill: #e9fdf2;
}
.st28 {
  fill: #1b6d49;
}
.st29 {
  fill: #d1ffe5;
}
.st30 {
  fill: #42e69e;
}
.st31 {
  fill: #ffdedc;
}
.st32 {
  fill: #e60c00;
}
.st33 {
  fill: #fff1f1;
}
.st34 {
  fill: #a72821;
}
.st35 {
  fill: #ff7e7e;
}
.st36 {
  fill: #59d0f8;
}
.st37 {
  fill: #0066ff;
}
.st38 {
  fill: #004665;
}
.st39 {
  fill: #ff5f00;
}
.st40 {
  fill: none;
  stroke: #e6e6e6;
  stroke-miterlimit: 10;
}
</style>

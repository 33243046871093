import axios from "@/plugins/axios";

const state = () => ({
  items: [],
  userAudits: [],
  entityAudits: {
    data: [],
    count: 0,
  },
  current: {},
  paginate: {
    query: "",
    itemsPerPage: 10,
    page: 1,
    sortBy: "",
    sortDesc: 0,
  },
});

const mutations = {
  setAudits(state, audits) {
    state.items = audits;
  },

  setUserAudits(state, audits) {
    state.userAudits = audits;
  },

  setEntityAudits(state, audits) {
    state.entityAudits = audits;
  },

  setAudit(state, audit) {
    state.current = audit;
  },

  setPaginate(state, paginate) {
    state.paginate = paginate;
  },
};

const actions = {
  getAudits({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/audit-all")
        .then((result) => {
          commit("setAudits", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getUserAudits({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setUserAudits", []);
      axios
        .post("/audit-all-by-user/", data)
        .then((result) => {
          commit("setUserAudits", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getEntityAudits({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit("setEntityAudits", {
        data: [],
        count: 0,
      });
      axios
        .patch(
          `/audit-all-by-entity/${data.entity}/${data.id}`,
          {},
          {
            params: state.paginate,
          }
        )
        .then((result) => {
          commit("setEntityAudits", result.data);
          commit("setPaginate", {
            query: "",
            itemsPerPage: 10,
            page: 1,
            sortBy: "",
            sortDesc: 0,
          });
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getAuditById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/audit-one", data)
        .then((result) => {
          commit("setAudit", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  rollbackData(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/rollback-audit/${data.auditId}/by-entity/${data.entity}/${data.entityId}`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  setPaginate({ commit }, paginate) {
    commit("setPaginate", paginate);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

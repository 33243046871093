export const setEmployments = (data) => {
  let employments = [];

  data.employer.map((item) => {
    employments.push({
      employer_name: item?.name ?? "",
      employer_address: item?.address ?? "",
      employer_phone: item?.phone ?? "",
      position: item?.position ?? "",
    });
  });

  return employments;
};

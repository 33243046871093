import axios from "axios";

const storage = {
  namespaced: true,
  state: () => ({
    spotlightData: [],
    spotlightDataList: [],
    progressSearch: false,
    spotlightDataIframe: {},
  }),
  actions: {
    setDataIframe({ state }, data) {
      state.spotlightDataIframe = data;
    },
    async getSpotlightData({ state }, query) {
      if (query === "") return;

      state.progressSearch = true;

      try {
        const response = await axios.get(`search/${query}`, {
          params: {
            limit: 5,
          },
        });

        state.spotlightData = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        state.progressSearch = false;
      }
    },

    async getSpotlightDataList({ state }) {
      try {
        const response = await axios.get("/search-filters/");
        state.spotlightDataList = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default storage;

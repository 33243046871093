export default {
  id: null,
  code: null,
  patient: {
    billingCompanyOwner: { name: "Select", id: "0" },
    company_id: null,
    ssn: "04566",
    patientNo: "045",
    driverLicense: "0410",
    firstName: "Johnatan",
    middleName: "Michael",
    lastName: "Doe",
    dateOfBirth: "1994-10-04",
    sex: "M",
    maritalStatus: "married",
    spuseName: "spuse name",
    spuseWork: "spuse employer",
    spuseWorkPhone: "010203",
    referenceNumber: "02",
    doctorNumber: "01",
    preferred_language: "en",
  },
  contact: {
    phone: "777777",
    fax: "558889999",
    mobile: "55555555",
    email: "jhonatan.doe.01@sample.com",
  },
  addresses: [
    {
      address_type_id: 1,
      address: "addrees",
      city: "city",
      state: "state",
      zip: "69952",
    },
  ],
  insurancePolicy: [
    {
      policyNumber: "010304",
      insuranceCompany: { name: "Select", id: "0" },
      insurancePlans: [],
      insurancePlan: { name: "Select", id: "1" },
      type_responsibility_id: "",
      insurance_policy_type_id: "",
      relationship_id: "",
      date_of_birth: "",
      copay: "01",
      groupNumber: "0102",
      effDate: "",
      endDate: "",
      releaseInfo: false,
      assignBenefits: false,
      ownInsurance: true,
      suscriber: { label: "Select", value: "1" },
      ssn: "ssn01",
      firstName: "first name",
      lastName: "last name",
      address: "direccion",
      city: "city",
      state: "state",
      zip: "0625551",
      phone: "01020304",
      fax: "010203",
      mobile: "01020305",
      email: "correo85554541@correo.com",
    },
  ],
  injuries: [
    {
      injuryDate: "",
      diagnosis: { name: "Select", id: "0" },
      typeDiagnosis: { name: "Select", id: "0" },
      noteInjury: "",
    },
  ],
  guarantor: {
    needGuardian: false,
    name: "",
    phone: "",
  },
  employer: [
    {
      name: "employer name",
      position: "0102",
      address: "address employer",
      phone: "0103060507",
    },
  ],
  emergencyContact: [
    {
      name: "name emergency",
      cellphone: "05055110505",
      relationship_id: 1,
    },
  ],
  socialNetwork: [
    {
      name: "Facebook",
      link: "facebook",
    },
  ],
  notes: {
    public: "note public",
    private: "note private",
  },

  valid: {
    contact: false,
    insurancePolicy: false,
    guarantor: false,
    emergencyContact: false,
  },

  readonly: false,
};

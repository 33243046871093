import { setProfile } from "./set.profile";
import { setMarital } from "./set.marital";
import { setContact } from "./set.contact";
import { setAddresses } from "./set.addresses";
import { setGuarantor } from "./set.guarantor";
import { setEmergencyContacts } from "./set.emergency.contacts";
import { setEmployments } from "./set.employments";

let initForm = {
  billing_company_id: null,
  patient_id: null,
  profile_id: null,
  driver_license: "",
  public_note: "",
  private_note: "",
  save_as_draft: false,
  marital_status_id: null,
  company_id: null,
  is_homeless: false,
  company_med_num: "",
  language: "",
  profile: {
    ssn: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    date_of_birth: "",
    sex: "",
    name_suffix_id: "",
    social_medias: [],
  },
  marital: {
    spuse_name: "",
    spuse_work: "",
    spuse_work_phone: "",
  },
  contact: {
    phone: "",
    mobile: "",
    fax: "",
    email: "",
  },
  addresses: [],
  insurance_policies: [],
  guarantor: {
    name: "",
    phone: "",
  },
  emergency_contacts: [],
  employments: [],
};

let form = initForm;

let setBodyResquest = async (data) => {
  let profile = await setProfile(data);
  let marital = await setMarital(data);
  let contact = await setContact(data);
  let addresses = await setAddresses(data);
  let guarantor = await setGuarantor(data);
  let emergency_contacts = await setEmergencyContacts(data);
  let employments = await setEmployments(data);

  Object.assign(form, {
    billing_company_id: data?.patient?.billingCompanyOwner?.id ?? "",
    id: data?.id ?? "",
    deceased: data?.patient?.deceased ?? true,
    is_homeless: data?.is_homeless ?? true,
    create_user: data?.patient?.create_user ?? false,
    patient_id: data?.patient_id ?? "",
    driver_license: data?.patient?.driverLicense ?? "",
    note: data?.notes?.private ?? "",
    marital_status_id: data?.patient?.maritalStatus ?? "",
    company_id: data?.patient?.company_id?.id ?? "",
    med_num: data?.patient?.doctorNumber ?? "",
    profile: profile,
    marital: marital,
    contact: contact,
    addresses: addresses,
    guarantor: guarantor,
    emergency_contacts: emergency_contacts,
    employments: employments,
  });
};

export default { form, setBodyResquest };

<template>
  <svg
    id="CustomIconRefund"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      id="currency_exchange_FILL0_wght400_GRAD0_opsz48"
      data-name="currency exchange FILL0 wght400 GRAD0 opsz48"
      class="cls-1"
      d="M10,20c-1.75-.01-3.45-.54-4.91-1.5-1.46-.89-2.72-2.05-3.73-3.43v3.11H0v-5.45h5.46v1.36h-3.11c.87,1.27,2,2.34,3.31,3.15,1.28.89,2.8,1.37,4.35,1.4,1.15,0,2.29-.23,3.35-.68,1.03-.44,1.96-1.07,2.75-1.86.79-.79,1.42-1.72,1.85-2.75.46-1.06.69-2.2.68-3.35h1.36c0,1.34-.26,2.66-.78,3.89-.51,1.19-1.24,2.27-2.15,3.18-.91.91-1.99,1.64-3.18,2.15-1.23.53-2.55.79-3.89.78ZM9.34,16.52v-1.22c-.63-.16-1.22-.46-1.72-.88-.5-.46-.9-1.02-1.17-1.65l1.16-.39c.16.55.5,1.03.97,1.36.46.33,1.01.51,1.58.5.54.02,1.07-.14,1.51-.44.38-.26.61-.7.6-1.17,0-.48-.2-.95-.57-1.26-.63-.49-1.34-.87-2.09-1.15-.75-.28-1.44-.7-2.04-1.23-.47-.47-.71-1.11-.68-1.77-.01-.64.24-1.25.68-1.7.49-.49,1.13-.79,1.82-.86v-1.16h1.25v1.16c.54.05,1.06.24,1.5.55.44.32.79.75,1.02,1.25l-1.09.52c-.19-.39-.48-.71-.84-.96-.35-.22-.77-.33-1.18-.32-.5-.03-.99.12-1.4.41-.34.27-.53.68-.51,1.11-.01.46.21.9.59,1.16.6.39,1.24.72,1.91.98.83.3,1.59.77,2.23,1.39.45.52.69,1.2.66,1.89,0,.36-.06.71-.2,1.04-.13.31-.33.59-.58.82-.27.24-.58.43-.92.56-.39.15-.81.25-1.23.28v1.18h-1.25ZM0,10c0-1.34.26-2.66.78-3.89.51-1.19,1.24-2.27,2.15-3.18.91-.91,1.99-1.64,3.18-2.15C7.34.26,8.66,0,10,0c1.75.01,3.45.54,4.91,1.5,1.46.89,2.72,2.05,3.73,3.43V1.82h1.36v5.46h-5.46v-1.36h3.11c-.87-1.26-2-2.33-3.3-3.15-1.28-.89-2.8-1.38-4.36-1.4-1.15,0-2.29.23-3.35.68-1.02.44-1.96,1.07-2.74,1.86-.79.79-1.42,1.72-1.85,2.75-.46,1.06-.69,2.2-.68,3.35H0Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRefund",
};
</script>

<style scoped>
.cls-1 {
  fill: #1b6d49;
  stroke-width: 0px;
}
</style>

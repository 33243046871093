export const setProfile = (data) => {
  return {
    ssn: data?.patient?.ssn ?? "",
    first_name: data?.patient?.firstName ?? "",
    last_name: data?.patient?.lastName ?? "",
    middle_name: data?.patient?.middleName ?? "",
    date_of_birth: data?.patient?.dateOfBirth ?? "",
    deceased_date: data?.patient?.deceased_date ?? "",
    sex: data?.patient?.sex ?? "",
    name_suffix_id: data?.patient?.suffix ?? "",
    social_medias: data?.socialNetwork ?? [],
    language: data?.patient?.preferred_language ?? "",
  };
};

<template>
  <svg
    version="1.1"
    id="CustomIconPayment"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18.4 13.4"
    style="enable-background: new 0 0 18.4 13.4"
    xml:space="preserve"
  >
    <path
      id="payments_FILL0_wght400_GRAD0_opsz48_2_"
      class="st20"
      d="M10.5,8C9.1,8,8,6.8,8,5.4c0-0.7,0.3-1.3,0.7-1.8
	c1-1,2.6-1,3.6,0c1,1,1,2.6,0,3.6C11.8,7.7,11.1,8,10.5,8L10.5,8z M3.8,10.9c-0.7,0-1.3-0.6-1.3-1.2c0,0,0,0,0,0V1.3
	C2.5,0.6,3.1,0,3.8,0c0,0,0,0,0,0h13.4c0.7,0,1.3,0.6,1.3,1.2c0,0,0,0,0,0v8.4c0,0.7-0.6,1.3-1.2,1.3c0,0,0,0,0,0H3.8z M5.9,9.6h9.2
	c0-1.1,0.9-2.1,2.1-2.1c0,0,0,0,0,0V3.3c-1.1,0-2.1-0.9-2.1-2.1c0,0,0,0,0,0H5.9c0,1.1-0.9,2.1-2.1,2.1c0,0,0,0,0,0v4.2
	C4.9,7.5,5.9,8.4,5.9,9.6C5.9,9.6,5.9,9.6,5.9,9.6z M15.9,13.4H1.3c-0.7,0-1.3-0.6-1.3-1.2c0,0,0,0,0,0V2.5h1.3v9.6h14.7V13.4z
	M3.8,9.6L3.8,9.6z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPayment",
};
</script>

<style scoped>
#CustomIconPayment {
  fill: currentColor;
}
</style>

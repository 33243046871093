import axios from "@/plugins/axios";

const state = () => ({});

const mutations = {};

const actions = {
  getNpi(_, npi) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/npi/${npi}`)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

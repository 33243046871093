import Vue from "vue";
import axios from "axios";
import router from "../router";
import store from "../store/index";

// const baseURL = "http://31.220.55.211:81/api/v1/";
const baseURL = process.env.VUE_APP_API_URL;
const session = localStorage.getItem("session");
const token = session ? JSON.parse(session).access_token : null;
const remove = ["session"];

axios.defaults.headers.common = {
  Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWNjZGV2LmNpcGgzci5jbzo4MVwvYXBpXC92MVwvYXV0aFwvcmVmcmVzaC10b2tlbiIsImlhdCI6MTY1NzIwOTg4NSwiZXhwIjoxNjU4NDE5NDg2LCJuYmYiOjE2NTcyMDk4ODYsImp0aSI6IlJONk1xTGo0c1NWSkVaNWUiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.CqwgQkPhoL4EXEZpW10Vj5DQlZ3sm5hu11IWZvRKpFs`,

  Accept: "application/json",
  "Content-Type": "application/json",
};

axios.defaults.baseURL = baseURL;
token
  ? (axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    })
  : null;

// eslint-disable-next-line no-unused-vars
function inactivityLogout() {
  for (const key of remove) {
    localStorage.removeItem(key);
  }
  axios.defaults.headers.common = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  router.currentRoute.path !== "/" ? router.replace("/") : null;
}

let worker = new Worker("session_worker.js");
worker.onmessage = function (evt) {
  console.log("Worker said : " + evt.data);
  store.state.system.sessionModal = true;
};

axios.interceptors.response.use(
  (res) => {
    if (window.location == window.parent.location) {
      worker.postMessage(res.headers["inactivity-time"]);
    } else {
      window.parent.postMessage(
        { "inactivity-time": res.headers["inactivity-time"] },
        "*"
      );
    }

    return res;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message == "Unauthenticated."
    ) {
      if (window.frameElement) {
        window.parent.postMessage("logout", "*");
      } else {
        inactivityLogout();
      }
    }

    if (error.response.status === 429) {
      store.state.system.isTooManyRequest = true;
    }

    return Promise.reject(error);
  }
);

window.addEventListener("message", (e) => {
  if (e.data === "logout") {
    // inactivityLogout();
  }
  if (e.data && e.data["inactivity-time"]) {
    worker.postMessage(e.data["inactivity-time"]);
  }
});

Vue.prototype.$axios = axios;

export default axios;

import axios from "@/plugins/axios";

const state = () => ({
  billingCompanies: [],
  listBillingCompanies: [],
  insuranceCompany: [],
  fileMethods: [],
  listFrontDate: [],
  billingCompaniesIncompletes: [],
  reasonsForAppeals: [],
  currentInsurance: {},
  listInsuranceCompanies: [],
  listCountries: [],
  listStates: [],
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },
  pivoteRequest: null,
});

const mutations = {
  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = [];

    if (billingCompanies.length > 0) {
      billingCompanies.map((bill) => {
        state.billingCompanies.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setListBillingCompanies(state, listBillingCompanies) {
    state.listBillingCompanies = [];

    if (listBillingCompanies.length > 0) {
      listBillingCompanies.map((bill) => {
        state.listBillingCompanies.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setFileMethods(state, fileMethods) {
    state.fileMethods = fileMethods;
  },
  setListFrontDate(state, listFrontDate) {
    state.listFrontDate = listFrontDate;
  },
  setBillingIncompletes(state, billingCompaniesIncompletes) {
    state.billingCompaniesIncompletes = billingCompaniesIncompletes;
  },
  setReasons(state, reasonsForAppeals) {
    state.reasonsForAppeals = reasonsForAppeals;
  },
  setCurrentInsurance(state, insurance) {
    state.currentInsurance = insurance;
  },
  setInsuranceCompanies(state, insuranceCompany) {
    state.insuranceCompany = insuranceCompany;
  },
  setListInsuranceCompany(state, listInsuranceCompanies) {
    state.listInsuranceCompanies = [];
    state.listInsuranceCompanies = listInsuranceCompanies;
  },
  setCountries(state, listCountries) {
    state.listCountries = listCountries;
  },
  setStates(state, listStates) {
    state.listStates = listStates;
  },
  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const getters = {};

const actions = {
  getBillingCompanies({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-list-billing-companies", params)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListBillingCompanies({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/insurance-company/get-list-billing-companies?insurance_company_id=${payload.insuranceId}&edit=${payload.edit}`
        )
        .then((result) => {
          commit("setListBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListInsuranceCompanies({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = data
        ? `/insurance-company/get-list?billing_company_id=${data}`
        : "/insurance-company/get-list";
      axios
        .get(url)
        .then((result) => {
          commit("setListInsuranceCompany", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getAllInsuranceCompanies(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-all-server", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getFileMethods({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-list-file-methods")
        .then((result) => {
          commit("setFileMethods", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListFrontDate({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-list-from-the-date")
        .then((result) => {
          commit("setListFrontDate", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getBillingIncompletes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-list-billing-incomplete-reasons")
        .then((result) => {
          commit("setBillingIncompletes", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getReasonsForAppeals({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-list-appeal-reasons")
        .then((result) => {
          commit("setReasons", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getInsuranceCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company")
        .then((result) => {
          resolve(result.data);
          commit("setInsuranceCompanies", result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getInsuranceCompanyById({ commit }, insuranceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/insurance-company/${insuranceId}`)
        .then((result) => {
          commit("setCurrentInsurance", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getInsuranceCompanyByPayerId(_, payerId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/insurance-company/get-by-payer-id/${payerId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.status));
    });
  },

  storeInsuranceCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/insurance-company", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  updateInsurance(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/insurance-company/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getAudits(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/${data.entity}/${data.id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getAuditById(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/audit-one", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  changeStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/insurance-company/${data.id}/change-status`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("address/get-list-countries")
        .then((result) => {
          commit("setCountries", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getStates({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("address/get-list-states")
        .then((result) => {
          commit("setStates", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  setPermissionType({ commit }, roles) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };

    if (roles) {
      roles.find((role) => {
        if (role.name === "Super User") {
          permissionType.isSuperUser = true;
        }

        if (role.name === "Billing Manager") {
          permissionType.isBillingManager = true;
        }
      });
    }

    commit("setPermissionType", permissionType);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

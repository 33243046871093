<template>
  <svg
    version="1.1"
    id="CustomIconWriteOffs"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    style="enable-background: new 0 0 20 20"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M15.9,18.6H1.4V4.1h8.4l1.4-1.4H1.4c-0.4,0-0.7,0.1-1,0.4C0.2,3.3,0,3.7,0,4.1v14.6C0,19.4,0.6,20,1.3,20H16
		c0.4,0,0.7-0.1,1-0.4c0.2-0.2,0.3-0.5,0.3-0.9V8.8l-1.4,1.4V18.6z"
      />
      <path
        class="st0"
        d="M19.5,2.5l-2-2.1c-0.2-0.2-0.6-0.4-1-0.4s-0.7,0.1-1,0.4l-2.4,2.4l-7.3,7.3v4.1h4l7.4-7.3l0,0l2.3-2.4
		c0.2-0.3,0.4-0.6,0.4-1S19.8,2.8,19.5,2.5z M9.2,12.8h-2v-2.1l6.9-6.9l2.1,2L9.2,12.8z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconWriteOffs",
};
</script>

<style scoped>
.st0 {
  fill: #ff7e7e;
}
</style>

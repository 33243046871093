<template>
  <div id="v-app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.$store.dispatch("system/initConfig");
  },
};
</script>

const filterCode = (obj) => {
  const array = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key].code !== "") {
      array.push(obj[key]);
    }
  });

  return array;
};

const setGroup = (item) => {
  item.values = Object.values(item.values);
  item.values.forEach((v) => {
    if (v.type === "multiple") {
      setMultiple(v);
    }

    if (v.type === "group") {
      setGroup(v);
    }
  });
};

const setMultiple = (item) => {
  const array = [];
  for (let key in item.values) {
    array.push({
      header: key,
      children: item.values[key],
    });
  }
  item.items = array;
  item.parameters = [];

  if (!Array.isArray(item.value)) item.value = Array.of(item.value);

  item.value.map((bar) => {
    if (bar.id.startsWith("|")) {
      item.parameters.push({
        id: bar.id,
        text: bar.name,
        selected: true,
      });
    }
  });
};

const orderAttributes = (fi) => {
  if (fi.type === "multiple") {
    setMultiple(fi);
  }

  if (fi.type === "group") {
    setGroup(fi);
  }
};

export { filterCode, orderAttributes };

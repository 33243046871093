import axios from "@/plugins/axios";
import router from "@/router";

const state = () => ({
  loading: false,
  previousToLogin: true,
  user: {},
  role: localStorage.getItem("role_user") || "",
  allPermissionList: [],
  access: [],
  totalPermissions: 0,
  permissionsName: [],
  accessEmail: "",
  accessPassword: "",
  permissionsRole: [],
  listStates: [],
  listCountries: [],
});

const getters = {
  get_user(state) {
    return state.user;
  },
};

const mutations = {
  checkPermission(state, permission) {
    let allPermissions = [...state.allPermissionList],
      boolean = false;

    allPermissions.map((item) => {
      if (item.name == permission) {
        let roles = [...item.roles];

        roles.map((role) => {
          if (role.name == state.role) boolean = true;
        });
      }
    });

    return boolean;
  },

  newDevice(state, data) {
    state.accessEmail = data[0];
    state.accessPassword = data[1];
  },

  clearAccessData(state) {
    state.accessEmail = "";
    state.accessPassword = "";
  },

  /**
   * @function sendToLogin
   * @description Send to login section.
   * @param {Boolean} open Boolean for show/hide the previous login screen.
   */
  sendToLogin(state, open) {
    state.previousToLogin = open;
    localStorage.setItem("previewScreen", true);
  },

  /**
   * @function setRole
   * @description Save the user role.
   * @param {String} role User role.
   */
  setRole(state, role) {
    localStorage.setItem("role_user", role);
    state.role = role;
  },

  setUserAuth(state, user) {
    state.user = user;
  },

  setPermissionsRole(state, permissions) {
    state.permissionsRole = permissions;
  },

  async getAllPermissions(state) {
    await axios
      .get("permission/permissions")
      .then((result) => {
        let data = result.data;

        state.totalPermissions = data.length;
        state.allPermissionList = [];

        data.map((permission) => {
          state.allPermissionList.push(permission);
          state.permissionsName.push(permission.name);
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  },

  setListStates(state, listStates) {
    state.listStates = listStates;
  },

  setListCountries(state, listCountries) {
    state.listCountries = listCountries;
  },
};

const actions = {
  /**
   * @function setSessionRole
   * @description Set the session role.
   * @param {String} role User role.
   */
  setSessionRole({ commit }, role) {
    commit("setRole", role);
  },

  async rToken() {
    await axios
      .get("auth/refresh-token")
      .then((result) => localStorage.setItem("mccaccesstoken", result.data));
  },

  /**
   * @function refreshToken
   * @description Refresh the token and save in localStorage.
   */
  refreshToken({ state }) {
    return new Promise((resolve, reject) => {
      state.loading = true;
      axios
        .get("auth/refresh-token")
        .then((result) => {
          localStorage.setItem("mccaccesstoken", result.data);
          resolve(result);
          state.loading = false;
        })
        .catch((error) => reject(error));
    });
  },

  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/auth/me")
        .then((result) => {
          commit("setUserAuth", result.data);
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getMenuPermissionsByRole({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/permission/permissions-by-role/${state.role}`)
        .then((result) => {
          commit("setPermissionsRole", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getPermissionsByRole({ state, commit }, permissionsName) {
    return new Promise((resolve, reject) => {
      if (state.permissionsRole.length) {
        let checkPermission = state.permissionsRole.find((permission) => {
          return permission.name === permissionsName;
        });

        if (checkPermission) {
          resolve(1);
        } else {
          resolve(2);
        }
      } else {
        axios
          .get(`/permission/permissions-by-role/${state.role}`)
          .then((result) => {
            commit("setPermissionsRole", result.data);

            let checkPermission = state.permissionsRole.find((permission) => {
              return permission.name === permissionsName;
            });

            if (checkPermission) {
              resolve(1);
            } else {
              resolve(2);
            }
          })
          .catch(() => reject(2));
      }
    });
  },

  checkPermission({ state }, permissionsName) {
    return new Promise((resolve, reject) => {
      if (state.permissionsRole.length) {
        let checkPermission = state.permissionsRole.find((permission) => {
          return permission.name === permissionsName;
        });

        if (checkPermission) {
          resolve(1);
        } else {
          resolve(2);
        }
      } else {
        reject(2);
      }
    });
  },

  unsetPermissions({ commit }) {
    commit("setPermissionsRole", []);
  },

  getStates({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/address/get-list-states")
        .then((result) => {
          commit("setListStates", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/address/get-list-countries")
        .then((result) => {
          commit("setListCountries", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  async logout() {
    try {
      const { data } = await axios.get("/auth/logout");
      if (data) {
        localStorage.removeItem("session");
        localStorage.removeItem("config");
        router.replace("/");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../layouts/LoginLayout.vue"),
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("../views/login/LoginView.vue"),
      },
      {
        name: "Recover Password",
        path: "newCredentials",
        component: () => import("../views/login/LoginView.vue"),
      },
    ],
  },
  {
    path: "/desktop",
    name: "Desktop",
    component: () => import("../layouts/DesktopLayout.vue"),
  },
  {
    path: "/patient-management",
    component: () => import("../views/patient/IndexPatient"),
  },
  {
    path: "/billing-company-management",
    component: () => import("../views/billing-company/IndexBillingCompany"),
  },
  {
    path: "/my-billing-company-management",
    component: () => import("../views/my-billing-company/MyBillingCompany"),
  },
  {
    path: "/company-management",
    component: () => import("../views/company/IndexCompany"),
  },
  {
    path: "/facility-management",
    component: () => import("../views/facility/IndexFacility"),
  },
  {
    path: "/clearing-house-management",
    component: () => import("../views/clearing_house/IndexClearing.vue"),
  },
  {
    path: "/claims-management",
    component: () => import("../views/claim/IndexClaim"),
  },
  {
    path: "/claims-rules-management",
    component: () => import("../views/claim_rules/IndexClaimRules"),
  },
  {
    path: "/admission-encounter",
    component: () =>
      import("../views/admission_encounter/IndexAdmissionEncounter"),
  },
  {
    path: "/claims-status-management",
    component: () => import("../views/claim-status/IndexClaimStatus"),
  },
  {
    path: "/user-management",
    component: () => import("../views/user/IndexUser"),
  },
  {
    path: "/diagnosis-management",
    component: () => import("../views/diagnosis/IndexDiagnosis"),
  },
  {
    path: "/modifier-management",
    component: () => import("../views/modifier/IndexModifier"),
  },
  {
    path: "/permission-management",
    component: () => import("../views/role/IndexRole"),
  },
  {
    path: "/procedure-management",
    component: () => import("../views/procedure/IndexProcedure"),
  },
  {
    path: "/role-management",
    component: () => import("../views/role/IndexRole"),
  },
  {
    path: "/user-restriction-by-ip",
    component: () => import("../views/apps/Guidelines.vue"),
  },
  {
    path: "/insurance-management",
    component: () => import("../views/insurance_company/IndexInsuranceCompany"),
  },
  {
    path: "/health-professional-management",
    component: () => import("../views/health-professional/IndexHealth"),
  },
  {
    path: "/payment-management",
    component: () =>
      import("../views/payment-management/IndexPaymentManagement"),
  },
  {
    path: "/shortcuts",
    component: () => import("../views/apps/Shortcuts.vue"),
  },
  {
    path: "/technical-suport",
    component: () => import("../views/apps/TechnicalSuport.vue"),
  },
  {
    path: "/guidelines",
    component: () => import("../views/apps/Guidelines.vue"),
  },
  {
    path: "/profile",
    component: () => import("../views/profile/IndexProfile.vue"),
  },
  {
    path: "/denial",
    component: () => import("../views/denials/IndexDenial.vue"),
  },
  {
    path: "/ledger-management",
    component: () => import("../views/ledger/IndexLedger.vue"),
  },
  {
    path: "/reports",
    component: () => import("../views/reports/IndexReports.vue"),
    children: [
      {
        name: "ReportSheetsSimpleView",
        path: "view/:module/:moduleId",
        component: () =>
          import("../views/reports/ReportDetailedPatientView.vue"),
        props: true,
      },
      {
        name: "SheetsReportsView",
        path: "sheets",
        component: () => import("../views/reports/SheetsReportsView.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    component: () => import("../views/Error404.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;

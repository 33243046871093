<template>
  <svg
    id="CustomIconEnable"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <path
      d="M40.73,34.6c.37,.36,.69,.68,1,.99-1.67,1.67-3.35,3.34-5.04,5.03-.02-.02-.07-.06-.12-.11-.85-.85-1.7-1.71-2.56-2.55-.14-.14-.13-.22,0-.35,.28-.27,.55-.56,.83-.86,.64,.65,1.25,1.26,1.86,1.89,1.36-1.36,2.69-2.7,4.02-4.03Z"
    />
    <path
      d="M40.35,21.75h-3.05c-.2-5.45-2.42-9.83-6.93-12.93-3.21-2.21-6.81-3.03-10.68-2.58-7.67,.88-13.66,7.51-13.76,15.23-.05,4.36,1.43,8.14,4.48,11.26,3.04,3.11,6.8,4.6,11.15,4.73v3.03c-4.51,.27-11.65-2.07-15.76-8.53C1.15,24.68,1.92,15.63,7.68,9.15,13.14,3.01,22.31,1.24,29.84,4.9c7.78,3.79,10.73,11.53,10.51,16.85Z"
    />
    <path
      d="M37.86,28.22c5.16-.02,9.37,4.21,9.37,9.4,0,5.12-4.21,9.35-9.33,9.37-5.21,.02-9.46-4.16-9.48-9.31-.02-5.27,4.15-9.45,9.44-9.47Zm-.01,1.84c-4.2,0-7.58,3.36-7.58,7.52,0,4.19,3.39,7.58,7.59,7.57,4.17,0,7.52-3.39,7.52-7.58,0-4.14-3.39-7.52-7.53-7.51Z"
    />
    <path
      d="M15.26,20.31c1.26,1.28,2.57,2.61,3.92,3.99,2.94-2.96,5.83-5.87,8.69-8.76,.78,.8,1.48,1.52,2.14,2.2-3.56,3.57-7.19,7.21-10.78,10.81-2.02-2.03-4.08-4.09-6.11-6.13,.66-.65,1.38-1.36,2.13-2.11Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEnable",
};
</script>

<style scoped>
#CustomIconEnable {
  fill: currentColor;
}
</style>

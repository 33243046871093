<template>
  <svg
    id="CustomIconDataBalance"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.6 14"
  >
    <path
      id="finance_chip_FILL0_wght400_GRAD0_opsz48"
      data-name="finance chip FILL0 wght400 GRAD0 opsz48"
      class="cls-1"
      d="M11.05,11h.75v-.8c.46-.06.89-.26,1.22-.58.33-.32.52-.76.5-1.23,0-.42-.18-.82-.51-1.09-.35-.3-.75-.54-1.18-.71v-1.85c.16.04.31.13.42.25.11.12.2.27.24.42l.93-.38c-.11-.34-.33-.63-.62-.84-.29-.21-.62-.35-.98-.41v-.8h-.77v.77c-.45.04-.88.22-1.22.51-.33.29-.52.72-.5,1.16,0,.44.19.85.53,1.12.36.3.76.55,1.2.73v1.98c-.27-.08-.51-.22-.69-.42-.19-.2-.32-.44-.39-.7l-.9.38c.12.45.37.84.72,1.15.36.31.79.5,1.26.55v.78ZM11.82,9.25v-1.65c.18.08.35.18.5.3.15.13.23.33.22.52.02.21-.05.42-.21.56-.15.12-.33.21-.51.26ZM11.05,6.27c-.18-.08-.35-.18-.51-.3-.16-.13-.25-.32-.23-.52-.02-.2.07-.39.23-.51.15-.11.33-.18.51-.21v1.55ZM7.18,14c-1.91.04-3.75-.7-5.11-2.04C-.66,9.3-.72,4.92,1.94,2.18c.05-.05.09-.09.14-.14C3.43.7,5.28-.04,7.18,0h8.23c1.91-.04,3.75.7,5.11,2.04,2.74,2.66,2.8,7.05.14,9.79-.05.05-.09.09-.14.14-1.36,1.34-3.2,2.08-5.1,2.04H7.18ZM7.18,12.5h8.23c1.5.03,2.94-.55,4-1.61,2.15-2.07,2.21-5.49.14-7.64-.05-.05-.1-.1-.14-.14-1.06-1.06-2.5-1.64-4-1.61H7.18c-1.5-.03-2.94.55-4,1.61-2.15,2.07-2.21,5.49-.14,7.64.05.05.1.1.14.14,1.06,1.06,2.5,1.64,4,1.61Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDataBalance",
};
</script>

<style scoped>
#CustomIconDataBalance {
  fill: currentColor;
}
</style>

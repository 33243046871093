import axios from "@/plugins/axios";

const state = () => ({
  insuranceCompanies: [],
  genders: [],
  discriminatories: [],
  macLocalities: [],
  procedures: [],
  insuranceCompaniesProcedures: [],
  priceProcedures: [],
  insurancePlans: [],
  currentProcedure: {},
  type: [],
  pivoteRequest: null,
  proceduresListInsurance: [],
});

const mutations = {
  setInsurancePlans(state, insurance) {
    state.insurancePlans = insurance;
  },

  setProcedures(state, procedure) {
    state.procedures = procedure;
  },

  setInsuranceCompaniesProcedure(state, insuranceCompaniesProcedure) {
    state.insuranceCompaniesProcedures = insuranceCompaniesProcedure;
  },

  setInsuranceCompanies(state, insuranceCompanies) {
    state.insuranceCompanies = insuranceCompanies;
  },

  setGenders(state, genders) {
    state.genders = genders;
  },

  setDiscriminatories(state, discriminatories) {
    state.discriminatories = discriminatories;
  },

  setMacLocalities(state, macLocalities) {
    state.macLocalities = macLocalities;
  },

  setPriceProcedures(state, priceProcedures) {
    state.priceProcedures = priceProcedures;
  },

  setProcedure(state, currentProcedure) {
    state.currentProcedures = currentProcedure;
  },

  setType(state, type) {
    state.type = type;
  },

  setProceduresListInsurance(state, proceduresListInsurance) {
    state.proceduresListInsurance = proceduresListInsurance;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getProceduresPaginated(_, data) {
    let query = data.query ? data.query : "";
    const url = `/procedure/get-all-server?query=${query}&itemsPerPage=${data.itemsPerPage}&sortDesc=${data.sortDesc}&page=${data.page}&sortBy=${data.sortBy}${data.searchOn}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchByCode(_, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-by-code/${code}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getProcedures() {
    return new Promise((resolve, reject) => {
      axios
        .get("/procedure")
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeProcedure(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/procedure", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateProcedureTab1(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/procedure/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateProcedureTab2(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/procedure/${data.id}/considerations`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateProcedureTab3(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/procedure/${data.id}/note`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getProcedureById({ commit }, procedureId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/${procedureId}`)
        .then((result) => {
          commit("setProcedure", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getGenders({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/procedure/get-list-genders")
        .then((result) => {
          commit("setGenders", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDiscriminatories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/procedure/get-list-discriminatories")
        .then((result) => {
          commit("setDiscriminatories", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getInsuranceCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company")
        .then((result) => {
          commit("setInsuranceCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getMacLocalities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/procedure/get-list-mac-localities")
        .then((result) => {
          commit("setMacLocalities", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPriceProcedure(_, filter) {
    console.log("entroPriceProcedure");
    return new Promise((resolve, reject) => {
      axios
        .get("/procedure/get-price-of-procedure", { params: filter })
        .then((result) => {
          resolve(result.data);
          console.log("priceProcedure", result);
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
        });
    });
  },

  filterMacLocalities(_, filter) {
    return new Promise((resolve, reject) => {
      axios
        .get("/procedure/get-list-mac-localities", { params: filter })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  searchDiagnosisByCode(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-list-diagnoses/${payload.code}`, {
          params: payload.data,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchModifiersList() {
    return axios
      .get("/modifier/get-list")
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  searchModifiersByCode(_, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-list-modifiers/${code}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAudits(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/${data.entity}/${data.id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  changeStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/procedure/change-status/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getListProcedure({ commit }) {
    // let companyId = 1;
    return new Promise((resolve, reject) => {
      axios
        .get("procedure/get-list")
        .then((result) => {
          commit("setProcedures", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getListProcedurePlans(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`procedure/get-list?search=${payload}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* getPrice() {
    return new Promise((resolve, reject) => {
      axios
        .get("procedure/get-list")
        .then((result) => {
          //commit("setProcedures", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },*/
  /* getProceduresListToCompany(_, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-list/${companyId}`)
        .then((result) => {
          resolve(result.data);
          console.log("ctp", result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },*/

  getListInsuranceCompanies({ commit }, procedure_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-list-insurance-companies/${procedure_id}`)
        .then((result) => {
          commit("setInsuranceCompaniesProcedure", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getListInsurancePlans({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-plan/get-list")
        .then((result) => {
          commit("setInsurancePlans", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
          console.log("error", error);
        });
    });
  },

  addServiceToCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/procedure/add-to-company/${data.company_id}`, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getProceduresToCompany(_, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-to-company/${companyId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
        });
    });
  },

  getProceduresListInsurance({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/procedure/get-list-insurance-label-fees`)
        .then((result) => {
          commit("setProceduresListInsurance", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getListClassifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url =
        payload && payload.pecificId
          ? `procedure/type/${payload.typeId}/classification?general=${payload.generalId}&specific=${payload.specificId}`
          : payload && payload.generalId
          ? `procedure/type/${payload.typeId}/classification?general=${payload.generalId}`
          : payload && payload.typeId
          ? `procedure/type/${payload.typeId}/classification`
          : "/procedure/type";

      axios
        .get(url)
        .then((result) => {
          if (!payload) {
            commit("setType", result.data);
          }
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
          console.log("error", error);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <svg
    version="1.1"
    id="CustomIconActionsClaims"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 13.4"
    style="enable-background: new 0 0 18 13.4"
    xml:space="preserve"
  >
    <path
      id="folder_open_FILL0_wght400_GRAD0_opsz48_4_"
      class="st20"
      d="M1.3,13.4c-0.3,0-0.6-0.1-0.9-0.4C0.1,12.8,0,12.5,0,12.1V1.3
	c0-0.3,0.1-0.6,0.4-0.9C0.6,0.1,0.9,0,1.3,0h5.9l1.3,1.3h7.1c0.3,0,0.6,0.1,0.9,0.4c0.2,0.2,0.4,0.5,0.4,0.9H7.9L6.6,1.3H1.3v10.9
	l2.1-8.4H18l-2.2,8.7c-0.1,0.3-0.2,0.6-0.5,0.7c-0.3,0.2-0.6,0.2-0.9,0.2L1.3,13.4z M2.6,12.1h12L16.3,5h-12L2.6,12.1z M2.6,12.1
	L4.3,5L2.6,12.1z M1.3,2.5L1.3,2.5z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconActionsClaims",
};
</script>

<style scoped>
#CustomIconActionsClaims {
  fill: currentColor;
}
</style>

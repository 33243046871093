<template>
  <svg
    version="1.1"
    id="CustomIconPatientResponsability"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.1 18.6"
    style="enable-background: new 0 0 22.1 18.6"
    xml:space="preserve"
  >
    <circle class="st0" cx="15.1" cy="8" r="2.8" />
    <path class="st1" d="M-6.2,6.5" />
    <g>
      <rect y="9.6" class="st2" width="8.9" height="1.7" />
      <rect y="0" class="st2" width="13.7" height="1.7" />
      <polygon
        class="st2"
        points="22.1,16.6 22.1,18.6 8.1,18.6 8.1,18.6 22.1,18.6 22.1,16.6 	"
      />
      <path class="st2" d="M11,4.8H0v1.7h10.1C10.2,5.8,10.7,5,11,4.8z" />
      <path
        class="st2"
        d="M21.7,15.1c-0.3-0.4-0.7-0.7-1.1-0.9c-0.9-0.4-1.8-0.7-2.8-1c-0.9-0.2-1.8-0.3-2.7-0.3c-0.9,0-1.8,0.1-2.7,0.3
		c-1,0.2-1.9,0.6-2.8,1c-0.4,0.2-0.8,0.5-1.1,0.9c-0.3,0.4-0.4,0.9-0.4,1.4v2.1h0l14,0v-2v-0.1C22.1,16,22,15.5,21.7,15.1z
		M20.8,17.3H9.4v-0.7c0-0.5,0.3-0.9,0.7-1.1c0.8-0.4,1.7-0.7,2.6-0.9c0.8-0.2,1.6-0.3,2.4-0.3c0.8,0,1.6,0.1,2.4,0.3
		c0.9,0.2,1.7,0.5,2.6,0.9c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7V17.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPatientResponsability",
};
</script>

<style scoped>
.st0 {
  fill: none;
  stroke: #59d0f8;
  stroke-width: 1.8;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #ffffff;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #59d0f8;
}
</style>

import { uuid } from "vue-uuid";

export default {
  apps: {
    dashbaord: {
      id: uuid.v4(), // unique id
      img: require("../../assets/images/Dashboard.svg"), // icon, apps, whatever in format png
      name: "Dashbaord", // app name
      component: () => {}, //component to render
      taskbar: false, // pin at taskbar
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false, //  dirrect access at desktop
      canMaximize: false, // can maximize
      alwaysOnTop: false, // always on top over others windows
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0, // initial render width
      height: 0, // initial render height
      minWidth: 0, // min width to resize
      minHeight: 0, // min height to resize
      maxWidth: 0, // max width to resize for some apps
      maxHeight: 0, // max height to resize for some apps
    },
    "patient management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Patient Management.png"),
      name: "Patient Management",
      title: "Patient Mgmt",
      api_key: "patient",
      permission: true,
      url: "#/patient-management",
      key: "patient management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    profile: {
      id: uuid.v4(),
      img: require("../../assets/apps/View Profile.png"),
      name: "Profile",
      title: "Profile",
      // component: () => import("../../views/profile/IndexProfile"),
      url: "#/profile",
      key: " profile",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "billing company management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Billing Company Management.png"),
      name: "Billing Company",
      title: "Billing Company",
      api_key: "billing_company",
      permission: "View Billing Companies",
      url: "#/billing-company-management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      key: "billing company management",
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "my billing company management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Billing Company Management.png"),
      name: "Billing Company",
      title: "Billing Company",
      api_key: "my_billing_company",
      permission: "Show Billing Company",
      url: "#/my-billing-company-management",
      key: "my billing company management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "company management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Company Management.png"),
      name: "Company Management",
      title: "Company Mgmt",
      api_key: "company",
      permission: "View Companies",
      url: "#/company-management",
      key: "company management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: false,
      maximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "facility management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Facility Management.png"),
      name: "Facility Management",
      title: "Facility Mgmt ",
      api_key: "facility",
      permission: "View Facilities",
      // component: () => import("../../views/facility/IndexFacility"),
      url: "#/facility-management",
      key: "facility management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      maximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "clearing house management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Clearing House Management.png"),
      name: "Clearing Houses",
      title: "Clearing Houses",
      api_key: "clearing_house",
      permission: "View Clearing Houses",
      // component: () => import("../../views/clearing_house/IndexClearing.vue"),
      url: "#/clearing-house-management",
      key: "clearing house management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "claims management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Claims Management.png"),
      name: "Claim Management",
      title: "Claim Mgmt",
      permission: "View Claims",
      api_key: "claim",
      // component: () => import("../../views/claim/IndexClaim"),
      url: "#/claims-management",
      key: "claims management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "denial management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Denial Management.svg"),
      name: "Denial Management",
      title: "Denial Mgmt",
      permission: "Show Denial",
      api_key: "denial",
      url: "#/denial",
      key: "denial management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "claim rules management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Claim rules.png"),
      name: "Claim Rules Management",
      title: "Claim Rule Mgmt",
      permission: "View Claim Rules",
      api_key: "claim_rules",
      url: "#/claims-rules-management",
      key: "claim rules management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "admission / encounter management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Admission - Encounter.svg"),
      title: "Admission / Encounter Mgmt",
      name: "Admission / Encounter Management",
      permission: "View Admission Encounter",
      api_key: "admission_encounter",
      url: "#/admission-encounter",
      key: "admission / encounter management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "status management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Substatus.svg"),
      name: "Claim Status Management",
      title: "Claim Status Mgmt",
      permission: "View Status",
      // component: () => import("../../views/claim/IndexClaim"),
      url: "#/claims-status-management",
      key: "status management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "payments management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Payment Management.svg"),
      name: "Payment Management",
      title: "Payment Mgmt",
      permission: true,
      url: "#/payment-management",
      key: "payments management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "user management": {
      id: uuid.v4(),
      img: require("../../assets/apps/User Management.png"),
      name: "User Management",
      title: "User Mgmt",
      api_key: "user",
      permission: true,
      // permission: "View Users",
      component: () => import("../../views/user/IndexUser"),
      url: "#/user-management",
      key: "user management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: false,
      alwaysOnTop: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    reports: {
      id: uuid.v4(),
      img: require("../../assets/apps/Reports.png"),
      name: "Reports",
      title: "Reports",
      permission: "View Reports",
      // component: () => import("../../views/AboutView"),
      url: "#/reports",
      key: "reports",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: false,
      alwaysOnTop: false,
      width: window.innerWidth - 100,
      height: window.innerHeight - 100,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "diagnosis management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Diagnosis Management.png"),
      name: "Diagnosis Management",
      title: " Diagnosis Mgmt",
      api_key: "diagnosis",
      // component: () => import("../../views/diagnosis/IndexDiagnosis"),
      url: "#/diagnosis-management",
      key: "diagnosis management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "modifier management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Modifier Management.png"),
      name: "Modifier Management",
      title: " Modifier Mgmt",
      api_key: "modifier",
      // component: () => import("../../views/modifier/IndexModifier"),
      url: "#/modifier-management",
      key: "modifier management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "permission management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Permission Management.png"),
      name: "Permission Management",
      title: "Permission Mgmt",
      // component: () => import("../../views/role/IndexRole"),
      url: "#/permission-management",
      key: "permission management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "procedure management": {
      id: uuid.v4(),
      img: require("../../assets/apps/ProcedureService.png"),
      name: "Procedure Management",
      title: "Procedure Mgmt",
      api_key: "procedure",
      permission: true,
      // permission: "View Procedure of Services",
      // component: () => import("../../views/procedure/IndexProcedure"),
      url: "#/procedure-management",
      key: "procedure management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "role management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Role management.png"),
      name: "Role management",
      title: "Role Mgmt",
      // component: () => import("../../views/role/IndexRole"),
      url: "#/role-management",
      key: "role management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "restriction by ip": {
      id: uuid.v4(),
      img: require("../../assets/apps/User Restriction by IP.png"),
      name: "Restriction by IP",
      title: "Restriction by IP",
      permission: "View Restrictions",
      // component: () => {},
      url: "#/user-restriction-by-ip",
      key: "restriction by ip",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    file: {
      id: uuid.v4(),
      img: require("../../assets/icons/apps/file.svg"),
      name: "File Manager",
      permission: true,
      key: "file",
      component: () => import("../../views/AboutView"),
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: false,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 300,
      height: 0,
      minWidth: 300,
      minHeight: 300,
      maxWidth: 300,
      maxHeight: 0,
    },
    "sticky notes": {
      id: uuid.v4(),
      img: require("../../assets/icons/apps/notes.svg"),
      name: "Sticky Notes",
      title: "Sticky Notes",
      permission: true,
      component: () => import("../../views/AboutView"),
      key: "sticky notes",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: false,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 300,
      height: 0,
      minWidth: 300,
      minHeight: 300,
      maxWidth: 300,
      maxHeight: 0,
    },
    calculator: {
      id: uuid.v4(),
      img: require("../../assets/icons/apps/calculator.svg"),
      name: "Calculator",
      title: "Calculator",
      permission: true,
      component: () => import("../../views/apps/Calculator"),
      key: "calculator",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: false,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 300,
      height: 500,
      minWidth: 300,
      minHeight: 500,
      maxWidth: 300,
      maxHeight: 500,
    },
    messenger: {
      id: uuid.v4(),
      img: require("../../assets/icons/apps/chat.svg"),
      name: "Messenger",
      title: "Messenger",
      permission: true,
      component: () => {},
      key: "messenger",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    browser: {
      id: uuid.v4(),
      img: require("../../assets/icons/apps/web.svg"),
      name: "Web Browser",
      title: "Web Browser",
      permission: true,
      component: () => {},
      key: "browser",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "insurance management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Insurance Management.png"),
      name: "Insurance Management",
      title: "Insurance Mgmt",
      permission: true,
      api_key: ["insurance_company", "insurance_plan"],
      url: "#/insurance-management",
      key: "insurance management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "health care professional management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Health Professional Management.png"),
      name: "Healthcare Professional Management",
      title: "Healthcare Professional Mgmt",
      api_key: "health_professional",
      permission: true,
      // component: () => import("../../views/health-professional/IndexHealth"),
      url: "#/health-professional-management",
      key: "health care professional management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "ledger management": {
      id: uuid.v4(),
      img: require("../../assets/apps/Ledger Management.svg"),
      name: "Ledger",
      title: "Ledger",
      permission: true,
      api_key: "ledger_management",
      url: "#/ledger-management",
      key: "ledger management",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 1024,
      height: 500,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    setting: {
      id: uuid.v4(),
      img: require("../../assets/apps/Setting.png"),
      name: "Settings",
      title: "Settings",
      permission: true,
      component: () => {},
      key: "setting",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    "technical support": {
      id: uuid.v4(),
      img: require("../../assets/apps/Technical Support.png"),
      name: "Technical Support",
      title: "Technical Support",
      permission: true,
      component: () => {},
      url: "#/technical-suport",
      //url: window.open("https://qa.ciph3r.co", "_blank", "noreferrer"),
      key: "technical support",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: false,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 327,
      height: 172,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    guidelines: {
      id: uuid.v4(),
      img: require("../../assets/icons/guidelines.svg"),
      name: "Guidelines",
      title: "Guidelines",
      permission: true,
      // component: () => import("../../views/apps/Guidelines"),
      url: "#/guidelines",
      key: "guidelines",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true,
      desktop: true,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    shortcuts: {
      id: uuid.v4(),
      img: require("../../assets/icons/guidelines.svg"),
      name: "Shortcuts",
      title: "Shortcuts",
      permission: true,
      url: "#/shortcuts",
      key: "shortcuts",
      taskbar: false,
      production: process.env.VUE_APP_ACTIVE === "true" ? true : true, //App
      desktop: true,
      canMaximize: true,
      alwaysOnTop: false,
      isClose: true,
      isMaximize: false,
      isMinimize: false,
      isFocus: false,
      width: 1280,
      height: 600,
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
  },
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMask from "v-mask";

import "./plugins/window";
import "./plugins/draggable";
import "./plugins/axios";

import "./scss/app.scss";
import "./scss/main.sass";

import "./assets/icons/material-design-icons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import i18n from "./i18n";

Vue.config.productionTip = false;

import amountFormat from "./plugins/amountFormat";
Vue.prototype.globalNumberFormat = amountFormat;

Vue.use(VueTheMask);
Vue.use(CKEditor);
Vue.use(Toast);
Vue.use(VueMask);

Vue.component("multi-select", () => import("./components/UI/Multiselect.vue"));
Vue.component("i-frame", () => import("./components/reusable/Iframe.vue"));
Vue.component("v-modal", () => import("./components/desktop/Modal.vue"));
Vue.component(
  "b-pagination",
  () => import("./components/reusable/Pagination.vue")
);
Vue.component(
  "b-headband",
  () => import("./components/reusable/LastModifyInfo.vue")
);
Vue.component(
  "last-modify-info",
  () => import("./components/reusable/LastModifyInfo.vue")
);
Vue.component("b-loader", () => import("./components/UI/Loader.vue"));
// @ts-ignore
Vue.component("date-picker", () => import("./components/UI/DatePicker.vue"));

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h: any) => h(App),
} as any).$mount("#app");

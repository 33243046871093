import axios from "@/plugins/axios";

const state = () => ({
  show: false,
  show_list: true,
  status_queue: 0,
  current_index: 0,
  files: [],
  statusToUpload: [0, 3],
});

const mutations = {
  viewQueue(state, show) {
    state.show = show;
  },

  showListFile(state, show_list) {
    state.show_list = show_list;
  },

  addFile(state, file) {
    state.files.push(file);
  },

  setStatusQueue(state, status) {
    state.status_queue = status;
  },

  setStatus(state, data) {
    state.files[data.index].status = data.status;
  },

  setUploadError(state, data) {
    state.files[data.index].status = 3;
    state.files[data.index].error = data.error;
  },

  setCurrentIndex(state, index) {
    state.current_index = index;
  },

  closeQueue(state) {
    state.current_index = 0;
    state.status_queue = 0;
    state.show_list = true;
    state.files = [];
    state.show = false;
  },
};

const actions = {
  addFile({ commit }, file) {
    return new Promise(() => {
      commit("addFile", file);
    });
  },

  uploadFile({ commit, state, dispatch, getters }, index) {
    return new Promise(() => {
      const file = state.files[index];
      if (file) {
        commit("viewQueue", true);
        commit("setCurrentIndex", index);

        if (state.statusToUpload.includes(file.status)) {
          commit("setStatusQueue", 1);

          dispatch("saveFile", { file, index, nextFile: true });
          // dispatch("testUploading", { file, index, nextFile: true });
        } else {
          dispatch("uploadFile", index + 1);
        }
      } else {
        if (getters.errors) {
          commit("setStatusQueue", 3);
        } else {
          commit("setStatusQueue", 2);
        }
      }
    });
  },

  saveFile({ commit, dispatch }, data) {
    return new Promise(() => {
      const { file, index, nextFile } = data;
      let url = "";

      commit("setStatus", { index, status: 1 });

      if (file.module === "payment") {
        url = `/payments/batch/${file.form.batchId}/payments/${file.form.paymentId}/eobs`;
      }

      axios
        .post(url, file.form.body)
        .then(() => {
          commit("setStatus", { index, status: 2 });

          file.callback();

          if (nextFile) {
            dispatch("uploadFile", index + 1);
          }
        })
        .catch((error) => {
          commit("setUploadError", { index, error });
          if (nextFile) {
            dispatch("uploadFile", index + 1);
          }
        });
    });
  },

  retryQueue({ dispatch, state }) {
    if (state.status_queue !== 1) {
      dispatch("uploadFile", 0);
    }
  },

  retryFile({ dispatch, state }, index) {
    const file = state.files[index];

    if (file && state.status_queue !== 1) {
      dispatch("saveFile", { file, index, nextFile: false });
      // dispatch("testUploading", { file, index, nextFile: false });
    }
  },

  testUploading({ commit, dispatch }, data) {
    return new Promise(() => {
      const { index, nextFile } = data;

      commit("setStatus", { index, status: 1 });

      setTimeout(() => {
        commit("setStatus", { index, status: 3 });

        if (nextFile) {
          dispatch("uploadFile", index + 1);
        }
      }, 5000);
    });
  },
};

const getters = {
  errors(state) {
    if (state.files.length > 0) {
      const fileError = state.files.find((file) => file.status === 3);

      if (fileError) {
        return true;
      } else return false;
    } else return false;
  },

  showErrors(state) {
    if (state.files.length > 0 && state.status_queue !== 1) {
      const fileError = state.files.find((file) => file.status === 3);

      if (fileError) {
        return true;
      } else return false;
    } else return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

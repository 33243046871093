<template>
  <svg
    version="1.1"
    id="CustomIconPreviewClaim"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 15.4 15.4"
    style="enable-background: new 0 0 15.4 15.4"
    xml:space="preserve"
  >
    <path
      id="text_snippet_FILL0_wght400_GRAD0_opsz48_2_"
      class="st0"
      d="M1.3,14.1h12.8v-8L9.3,1.3h-8L1.3,14.1z M1.3,15.3
	c-0.7,0-1.3-0.5-1.3-1.2l0,0V1.3C0,0.6,0.6,0,1.3,0l0,0h8.5l5.5,5.5V14c0,0.7-0.6,1.3-1.3,1.3l0,0H1.3L1.3,15.3z M3.4,11.7H12v-1.3
	H3.4V11.7z M3.4,8.3H12V7H3.4V8.3z M3.4,4.9h5.9V3.6H3.4V4.9z M1.3,14.1L1.3,14.1z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPreviewClaim",
};
</script>

<style scoped>
#CustomIconPreviewClaim {
  fill: currentColor;
}
</style>

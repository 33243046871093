<template>
  <svg
    id="CustomIconOpenClaim"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.65 14.62"
  >
    <g id="Grupo_4031" data-name="Grupo 4031">
      <path
        id="folder_open_FILL0_wght400_GRAD0_opsz48"
        data-name="folder open FILL0 wght400 GRAD0 opsz48"
        class="cls-1"
        d="m1.37,14.62c-.36,0-.7-.16-.95-.42-.27-.24-.42-.59-.42-.95V1.37c0-.36.16-.7.42-.95C.67.16,1.01,0,1.37,0h6.42l1.37,1.37h7.75c.36,0,.7.16.95.42.27.24.42.59.42.95h-9.71l-1.37-1.37H1.37v11.88L3.7,4.11h15.95l-2.45,9.46c-.06.32-.24.6-.5.8-.28.18-.61.27-.94.25H1.37Zm1.44-1.37h13.07l1.92-7.77H4.73l-1.92,7.77Zm0,0l1.92-7.77-1.92,7.77ZM1.37,2.74h0Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconOpenClaim",
};
</script>

<style scoped>
#CustomIconOpenClaim {
  fill: currentColor;
}
.cls-1 {
  stroke-width: 0px;
}
</style>

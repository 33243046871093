import axios from "@/plugins/axios";

const state = () => ({
  items: [],
  facilities: [],
  companies: [],
  billingCompanies: [],
  listBillingCompanies: [],
  batch: {},
  pivoteRequest: null,
});

const mutations = {
  setCompanies(state, companies) {
    state.companies = [];

    state.companies = companies.map((company) => {
      company.name =
        company.abbreviation && company.abbreviation !== ""
          ? `${company.abbreviation} - ${company.name}`
          : company.name;
      return company;
    });
  },

  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
    state.listBillingCompanies = billingCompanies.map((billing) => {
      billing.name = `${billing.abbreviation} - ${billing.name}`;
      return billing;
    });
  },

  setBatch(state, batch) {
    state.batch = batch;
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getClaims(_, data) {
    return new Promise((resolve, reject) => {
      const url = data.id
        ? `claim/batch/get-all-server-claims/${data.id}`
        : `claim/batch/get-all-server-claims`;
      const rest = `?billing_company_id=${data.billing_company_id}&company_id=${data.company_id}`;

      axios
        .get(url + rest, { params: data.paginate })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  storeBatch(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim/batch/", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBatchs(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/batch/get-all-server", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBatch({ commit }, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/batch/${batchId}`)
        .then((result) => {
          commit("setBatch", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBillingCompany({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompaniesByBillingId({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/get-list-by-billing-company/${billingCompanyId}?batch=${true}`
        )
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  deleteBatch(_, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/claim/batch/${batchId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getRecords(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/claim-batch/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  updateBatch(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/claim/batch/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  submitClearingHouse(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/batch/submit-to-clearing-house/${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  confirmShipping(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/batch/confirm-shipping/${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getPreviewBatch(_, payload) {
    return new Promise((resolve, reject) => {
      let url = payload.printBatch
        ? `/claim/batch/show-batch-preview/${payload.id}?print=${true}`
        : `/claim/batch/show-batch-preview/${payload.id}`;
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getReport(_, batchId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/batch/show-batch-report/${batchId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBatchsView(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/batch/${data.batchId}/claims`, { params: data.pagination })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <svg
    version="1.1"
    id="CustomIconPatient"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 14.6 14.2"
    style="enable-background: new 0 0 14.6 14.2"
    xml:space="preserve"
  >
    <path
      id="person_FILL0_wght400_GRAD0_opsz48_5_"
      class="st20"
      d="M7.3,6.8C5.5,6.9,4,5.5,3.9,3.7c0-0.1,0-0.2,0-0.3
	C3.8,1.6,5.2,0.1,7,0c0.1,0,0.2,0,0.3,0c1.8-0.1,3.3,1.3,3.4,3.1c0,0.1,0,0.2,0,0.3c0.1,1.8-1.3,3.3-3.1,3.4
	C7.5,6.8,7.4,6.8,7.3,6.8z M0,14.2V12c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.7-0.7,1.1-0.9c0.9-0.4,1.9-0.8,2.9-1c0.9-0.2,1.9-0.3,2.8-0.3
	c0.9,0,1.9,0.1,2.8,0.4c1,0.3,2,0.6,2.9,1c0.5,0.2,0.9,0.5,1.1,0.9c0.3,0.4,0.4,1,0.4,1.5v2.1L0,14.2z M1.4,12.8h11.9V12
	c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5c-0.8-0.4-1.7-0.8-2.7-1C9,9.7,8.1,9.6,7.3,9.6c-0.9,0-1.7,0.1-2.5,0.3
	c-0.9,0.2-1.8,0.5-2.7,1c-0.4,0.2-0.7,0.7-0.7,1.2L1.4,12.8z M7.3,5.5c1.1,0,2-1,1.9-2.1c0-1.1-1-2-2.1-1.9s-2,1-1.9,2.1
	c0,0.5,0.2,1,0.6,1.3C6.2,5.3,6.7,5.5,7.3,5.5L7.3,5.5z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPatient",
};
</script>

<style scoped>
#CustomIconPatient {
  fill: currentColor;
}
</style>

import axios from "@/plugins/axios";

const state = () => ({
  loadingApp: false,
  patientId: null,
  currentDenial: null,
  showTable: false,
  loadingEOB: false,
  section: "search",
  querySearch: {
    filter: "",
  },
  patients: [],
  companies: [],
  companiesInView: [],
  insurancePlans: [],
  insurancePolicies: [],
  substatus_tracking: [],
  healthProfessionals: [],
  status: [],
  billingCompanies: [],
  patientClaims: null,
  departmentResponsibilities: null,
  validFormTracking: false,
  trackingForm: {
    claim_id: "",
    claim_number: "",
    interface_type: "",
    is_reprocess_claim: false,
    is_contact_to_patient: false,
    contact_through: "",
    rep_name: "",
    ref_number: "",
    claim_status: "",
    claim_sub_status: "",
    tracking_date: "",
    resolution_time: "",
    past_due_date: "",
    follow_up: "",
    department_responsible: "",
    policy_responsible: "",
    tracking_note: "",
    response_details: "",
  },
  permissionType: {
    isSuperUser: false,
  },
  billingCompanyId: 0,
  refresh: false,
  totalResult: 0,
});

const mutations = {
  setLoadingApp(state, loadingApp) {
    state.loadingApp = loadingApp;
  },

  setTotalResult(state, totalResult) {
    state.totalResult = totalResult;
  },

  setRefresh(state, refresh) {
    state.refresh = refresh;
  },

  setPatientId(state, patientId) {
    state.patientId = patientId;
  },

  setSection(state, section) {
    state.section = section;
  },

  setPatients(state, patients) {
    state.patients = patients;
  },

  setQuerySearch(state, querySearch) {
    state.querySearch = querySearch;
  },

  setCompanies(state, companies) {
    state.companies = companies;
  },

  setCompaniesInView(state, companiesInView) {
    state.companiesInView = companiesInView;
  },

  setInsurancePlans(state, insurancePlans) {
    state.insurancePlans = insurancePlans;
  },

  setInsurancePolicies(state, insurancePolicies) {
    state.insurancePolicies = insurancePolicies;
  },

  setHealthProfessionals(state, healthProfessionals) {
    state.healthProfessionals = healthProfessionals;
  },

  setPatientClaims(state, patientClaims) {
    state.patientClaims = patientClaims;
  },

  setStatus(state, status) {
    state.status = status;
  },

  setDepartmentResponsibilities(state, departmentResponsibilities) {
    state.departmentResponsibilities = departmentResponsibilities;
  },

  setSubStatusTracking(state, substatus_tracking) {
    state.substatus_tracking = substatus_tracking;
  },

  setTrackingForm(state, tracking) {
    Object.assign(state.trackingForm, tracking);
  },

  setValidForm(state, valid) {
    state.validFormTracking = valid;
  },

  setShowTable(state, showTable) {
    state.showTable = showTable;
  },

  setCurrentDenial(state, currentDenial) {
    state.currentDenial = currentDenial;
  },

  setLoadingEOB(state, loadingEOB) {
    state.loadingEOB = loadingEOB;
  },

  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
  },

  resetOptionsSelects(state) {
    state.companies = [];
    state.insurancePlans = [];
    state.healthProfessionals = [];
  },

  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },

  setBillingCompanyId(state, billingCompanyId) {
    state.billingCompanyId = billingCompanyId;
  },
};

const actions = {
  searchPatients({ commit, state }) {
    commit("setPatients", []);
    commit("setShowTable", false);
    return new Promise((resolve, reject) => {
      axios
        .get("/ledger/filter", { params: state.querySearch })
        .then((result) => {
          commit("setTotalResult", result.data.count);
          commit("setPatients", result.data);
          commit("setShowTable", true);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPatientClaims({ commit }, patientId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/ledger/${patientId}/claims`)
        .then((result) => {
          commit("setPatientClaims", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getClaimById({ commit }, claimId) {
    commit("setLoadingEOB", true);

    return new Promise((resolve, reject) => {
      axios
        .get(`/denial/${claimId}`)
        .then((result) => {
          commit("setCurrentDenial", result.data);
          commit("setLoadingEOB", false);
          resolve(result.data);
        })
        .catch((error) => {
          commit("setLoadingEOB", false);
          reject(error.response.data);
        });
    });
  },

  getCompanies({ commit }, data) {
    const { billingCompanyId, from } = data;

    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/${billingCompanyId}`)
        .then((result) => {
          if (from === "search") {
            commit("setCompanies", result.data);
          }

          if (from === "data") {
            commit("setCompaniesInView", result.data);
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePlans({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      const options = {
        params: {
          groupBy: true,
          simple: true,
          billing_company_id: billingCompanyId,
        },
      };

      axios
        .get(`/insurance-plan/get-list`, options)
        .then((result) => {
          commit("setInsurancePlans", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getHealthProfessionals({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      const options = {
        params: {
          billing_company_id: billingCompanyId,
        },
      };

      axios
        .get("/health-professional/get-list", options)
        .then((result) => {
          commit("setHealthProfessionals", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getStatus({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-status")
        .then((result) => {
          commit("setStatus", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getDepartmentResponsibilities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-department-responsibilities`)
        .then((result) => {
          commit("setDepartmentResponsibilities", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePolicies({ commit }, claim) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-insurance-policies/${claim}`)
        .then((result) => {
          commit("setInsurancePolicies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getSubStatusTracking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim-sub-status/get-list", { params: data })
        .then((result) => {
          commit("setSubStatusTracking", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getCheckStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-check-status/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  createTracking({ state }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/denial", state.trackingForm)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company/get-list")
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  setPermissionType({ commit }, roles) {
    let permissionType = {
      isSuperUser: false,
    };

    if (roles) {
      roles.find((role) => {
        if (role.name === "Super User") {
          permissionType.isSuperUser = true;
        }
      });
    }

    commit("setPermissionType", permissionType);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <svg
    id="CustomIconDenial"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.7 14.4"
  >
    <path
      id="quick_reference_all_FILL0_wght400_GRAD0_opsz48"
      data-name="quick reference all FILL0 wght400 GRAD0 opsz48"
      class="cls-1"
      d="M1.05,1.05h0ZM2.78,8.02h3.22c.13-.2.27-.38.42-.56.15-.18.32-.34.5-.49H2.77v1.04ZM2.78,10.98h2.72c-.03-.17-.06-.35-.08-.52-.02-.17-.02-.35,0-.52h-2.63v1.04ZM1.05,13.94c-.28,0-.54-.11-.73-.31-.2-.19-.31-.45-.31-.73V1.05c0-.28.11-.54.31-.73C.51.12.78,0,1.05.01h6.29l3.81,3.81v2.68c-.16-.08-.33-.15-.5-.21-.18-.06-.36-.1-.54-.14v-1.86h-3.29V1.06H1.06v11.84h5.48c.23.24.49.45.78.63.29.18.6.32.93.42H1.05ZM9.41,12.02c.51.01,1.01-.18,1.37-.55.36-.36.56-.86.55-1.37.01-.51-.18-1.01-.55-1.37-.36-.36-.86-.56-1.37-.55-.51-.01-1.01.18-1.37.55-.36.36-.56.86-.55,1.37-.01.51.18,1.01.55,1.37.36.36.85.56,1.37.55h0ZM12.96,14.39l-1.87-1.86c-.24.16-.51.3-.79.39-.29.1-.59.15-.9.15-.79.01-1.55-.3-2.1-.86-.57-.55-.88-1.31-.86-2.1-.02-.79.3-1.55.86-2.1.55-.56,1.31-.88,2.1-.86.79-.02,1.55.3,2.1.86.57.55.88,1.31.86,2.1,0,.31-.05.61-.15.9-.1.28-.23.54-.39.79l1.86,1.87-.73.73Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDenial",
};
</script>

<style scoped>
#CustomIconDenial {
  fill: currentColor;
}
</style>

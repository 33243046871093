<template>
  <svg
    version="1.1"
    id="CustomIconInsurancePlan"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 2 42 42"
    style="enable-background: new 0 0 48 48"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M35.5,24.9v-9.5L26,6H10.5C9.8,6,9.2,6.3,8.7,6.8C8.2,7.3,7.9,7.9,7.9,8.6v29.3c0,0.7,0.3,1.3,0.8,1.8
			c0.5,0.5,1.1,0.8,1.8,0.8h16c1.5,1.8,3.3,3,5.4,3.6c2.4-0.6,4.3-2,5.9-4.1c1.5-2.2,2.3-4.5,2.3-7.1v-6.1L35.5,24.9z M10.5,37.8
			V16.6v-8h14.2v8h8.1V24l-1-0.4l-3.3,1.2v-1.6H14.8v2.6h11.3l-2.3,0.9v3.9h-9v2.6h9c0,1.7,0.4,3.2,1.1,4.7L10.5,37.8L10.5,37.8z
			M38,32.8c0,2-0.6,3.9-1.7,5.5c-1.1,1.6-2.6,2.7-4.4,3.3c-0.9-0.3-1.7-0.7-2.4-1.2c-0.8-0.6-1.4-1.3-2-2.1
			c-0.1-0.2-0.2-0.3-0.3-0.5c-0.9-1.4-1.3-3-1.4-4.7c0-0.1,0-0.2,0-0.3v-2.3v-2.3l6-2.3l1,0.4l2.6,1l2.5,0.9L38,32.8L38,32.8z"
        />
        <polygon
          class="st0"
          points="35.1,30.2 31.3,34 29.5,32.2 28.2,33.5 31.3,36.6 36.4,31.5 		"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconInsurancePlan",
};
</script>

<style scoped>
#CustomIconInsurancePlan {
  fill: currentColor;
}
</style>

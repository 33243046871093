import axios from "@/plugins/axios";

const state = () => ({
  items: [],
  itemsUnits: [],
  company: {},
  unit: {},
  rooms: [],
  units: [],
  services: [],
  companies: [],
  billingCompaniesAll: [],
  billingCompaniesList: [],
  facilities: [],
  patients: [],
  companiesByBilling: [],
  suffix: [],
  rules: [],
  applyTo: [],
  when: [],
  insurance: [],
  typesContract: [],
  claimsFormat: [],
  measurementUnits: [],
  billingProviderHP: [],
  billingProvider: [],
  patientsUnit: [],
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },
  pivoteRequest: null,
});

const mutations = {
  setCompanies(state, companies) {
    state.items = [];

    companies.map((company) => {
      state.items.push({
        id: company.id,
        name: company.name,
        contacts: company.contacts,
        nicknames: company.nicknames,
        npi: company.npi,
        code: company.code,
        status: company.status,
      });
    });
  },
  setUnits(state, units) {
    state.itemsUnits = [];

    units.map((unit) => {
      state.itemsUnits.push({
        id: unit.id,
        name: unit.name,
        code: unit.code,
        status: unit.status,
      });
    });
  },

  addCompany(state, company) {
    state.items.push({
      id: company.id,
      name: company.name,
      nicknames: company.nicknames,
      npi: company.npi,
      code: company.code,
      status: company.status,
    });
  },
  setCompaniesbyBiling(state, companiesByBilling) {
    state.companiesByBilling = [];
    if (companiesByBilling.length > 0) {
      companiesByBilling.map((bill) => {
        state.companiesByBilling.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setCompany(state, company) {
    state.company = company;
  },
  setUnit(state, unit) {
    state.unit = unit;
  },
  setSuffix(state, suffix) {
    state.suffix = suffix;
  },
  setRules(state, rules) {
    state.rules = rules;
  },
  setApplyTo(state, applyTo) {
    state.applyTo = applyTo;
  },
  setWhen(state, when) {
    state.when = when;
  },
  setInsurance(state, insurance) {
    state.insurance = insurance;
  },
  setTypesContract(state, typesContract) {
    state.typesContract = [];
    if (typesContract.length > 0) {
      typesContract.map((type) => {
        state.typesContract.push({
          id: type.id,
          name: type.code ? `${type.code} - ${type.name}` : type.name,
          code: type.code ?? "",
        });
      });
    }
  },
  setPatients(state, patients) {
    state.patients = patients;
  },
  setMeasurementUnits(state, measurementUnits) {
    state.measurementUnits = measurementUnits;
  },
  setRooms(state, rooms) {
    if (rooms.name !== "") {
      state.rooms.push({
        code: rooms.code,
        ocupation: rooms.ocupation ? rooms.ocupation : 0,
        inpatientTotal: rooms.inpatientTotal ? rooms.inpatientTotal : "-",
        outpatientTotal: rooms.outpatientTotal ? rooms.outpatientTotal : "-",
        inpatient: rooms.inpatient,
        outpatient: rooms.outpatient,
        name: rooms.name,
        capacity: rooms.capacity,
        note: rooms.note,
        procedures: rooms.services.filter((item) => item.service !== ""),
      });
    }
  },
  setRoomsEdit(state, rooms) {
    var indice = -1;
    state.rooms.map((_, index) => {
      if (index === rooms.index) {
        indice = index;
      }
    });
    state.rooms.splice(indice, 1);

    state.rooms.push({
      id: rooms.id,
      code: rooms.code,
      index: rooms.index,
      ocupation: rooms.ocupation ? rooms.ocupation : 0,
      inpatientTotal: rooms.inpatientTotal ? rooms.inpatientTotal : "-",
      outpatientTotal: rooms.outpatientTotal ? rooms.outpatientTotal : "-",
      inpatient: rooms.inpatient,
      outpatient: rooms.outpatient,
      name: rooms.name,
      capacity: rooms.capacity,
      note: rooms.note,
      procedures: rooms.services
        ? rooms.services.filter((item) => item.service !== "")
        : [],
    });
  },
  deleteRoom(state, roomCode) {
    var indice = state.rooms.findIndex((obj) => obj.code == roomCode);
    state.rooms.splice(indice, 1);
  },
  cleanRoom(state) {
    state.rooms = [];
  },
  setServices(state, services) {
    state.services = services;
  },
  updateCompanies(state, data) {
    state.items.map((item) => {
      if (item.id === data.id) {
        item.nicknames = data.nicknames;
      }

      return item;
    });
  },

  changeStatus(state, companyId) {
    state.items.map((item) => {
      if (item.id === companyId) {
        item.status = !item.status;
      }
      return item;
    });
  },

  setBillingCompanies(state, billingCompaniesAll) {
    state.billingCompaniesAll = [];
    if (billingCompaniesAll.length > 0) {
      billingCompaniesAll.map((bill) => {
        state.billingCompaniesAll.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setBillingCompaniesByCompany(state, listBillingCompanies) {
    state.listBillingCompaniesList = [];
    state.billingCompaniesList = [];
    if (listBillingCompanies.length > 0) {
      listBillingCompanies.map((bill) => {
        state.billingCompaniesList.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setFacilities(state, facilities) {
    state.facilities = facilities;
  },
  setClaimsFormat(state, claimsFormat) {
    state.claimsFormat = claimsFormat;
  },
  setBillingProvider(state, billingProvider) {
    state.billingProvider = billingProvider;
  },
  setBillingProviderHP(state, billingProvider) {
    state.billingProviderHP = billingProvider;
  },
  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  cleanRoom({ commit }) {
    commit("cleanRoom");
  },
  deleteRoom({ commit }, roomCode) {
    commit("deleteRoom", roomCode);
  },
  openSearchApi(_, npi) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/npi/${npi}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPatientsUnits({ commit, state }) {
    let query = state.options.query ? state.options.query : "";
    let sortBy =
      state.options.options.sortBy[0] === "dob"
        ? "profile.date_of_birth"
        : state.options.options.sortBy;
    const url =
      state.options.options.sortDesc[0] === true
        ? `/patient/get-all-server?query=${query}&itemsPerPage=${state.options.itemsPerPage}&sortDesc=${state.options.options.sortDesc}&page=${state.options.options.page}&sortBy=${sortBy}`
        : `/patient/get-all-server?query=${query}&itemsPerPage=${state.options.itemsPerPage}&page=${state.options.options.page}&sortBy=${sortBy}`;
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(async (result) => {
          //const patients = await dataTable(result.data.data);
          // commit("setPatientsUnit", patient);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    });
  },
  searchCompanyByNpi(_, npi) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-by-npi/${npi}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  searchCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-by-name/" + data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  storeCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/company", data)
        .then((result) => {
          let resultData = result.data;
          resultData.nicknames = [];

          if (data.nickname) {
            resultData.nicknames.push({ nickname: data.nickname });
          }

          commit("addCompany", resultData);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company")
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getServices({ commit }, payload) {
    const url = payload.search
      ? `/procedure/get-list/${payload.companyId}?search=${payload.search}`
      : `/procedure/get-list/${payload.companyId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          commit("setServices", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getUnits({ commit }, params) {
    let query = params.query ? params.query : "";
    const url =
      params.options.sortDesc[0] === true
        ? `/unit?filter=company.id=${params.company_id}&query=${query}&itemsPerPage=${params.options.itemsPerPage}&sortDesc=${params.options.sortDesc}&page=${params.options.page}&sortBy=${params.options.sortBy}`
        : `/unit?filter=company.id=${params.company_id}&query=${query}&itemsPerPage=${params.options.itemsPerPage}&page=${params.options.page}&sortBy=${params.options.sortBy}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          commit("setUnits", result.data.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveRooms({ commit }, data) {
    commit("setRooms", data);
  },
  updateRooms({ commit }, data) {
    commit("setRoomsEdit", data);
  },
  getSuffix({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-list-name-suffix")
        .then((result) => {
          commit("setSuffix", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getRules({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-list-statement-rules")
        .then((result) => {
          commit("setRules", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getTypesContract({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-contract-fee-types `)
        .then((result) => {
          commit("setTypesContract", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getApplyTo({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-list-statement-apply-to")
        .then((result) => {
          commit("setApplyTo", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getWhen({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-list-statement-when")
        .then((result) => {
          commit("setWhen", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getInsurance({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company/get-list")
        .then((result) => {
          commit("setInsurance", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getInsuranceGroupBy({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = data.billing_company_id
        ? `/insurance-plan/get-list?groupBy=true&billing_company_id=${data.billing_company_id}&simple=${data.notSplit}`
        : `/insurance-plan/get-list?groupBy=true&simple=${data.notSplit}`;
      axios
        .get(url)
        .then((result) => {
          commit("setInsurance", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getInsuranceGroupByRules({ commit }, id) {
    return new Promise((resolve, reject) => {
      const url = `/insurance-plan/get-list?groupBy=true&billing_company_id=${id}&simple=${true}`;
      axios
        .get(url)
        .then((result) => {
          commit("setInsurance", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getPlanByInsCompany(_, payload) {
    return new Promise((resolve, reject) => {
      const url = `/insurance-plan/get-list?groupBy=true&billing_company_id=${
        payload.id
      }&simple=${true}`;
      axios
        .get(url, {
          params: {
            insurance_companies: payload.insurance_companies,
          },
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getPlanByInsCompanyNotGroup(_, payload) {
    return new Promise((resolve, reject) => {
      const url = payload.id
        ? `/insurance-plan/get-list?billing_company_id=${
            payload.id
          }&simple=${true}`
        : `/insurance-plan/get-list`;
      axios
        .get(url, {
          params: {
            insurance_companies: payload.insurance_companies,
          },
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getInsuranceFiltered(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/insurance-company/search/`, {
          params: payload.data.except_ids,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/company/change-status/${data.id}`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  changeStatusUnit(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/unit/${data.unit}/state`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  deleteUnit(_, unit) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/unit/${unit}`)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompanyById({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${companyId}`)
        .then((result) => {
          commit("setCompany", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getUnitById({ commit }, unitId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/unit/${unitId}`)
        .then((result) => {
          commit("setUnit", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  updateCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  storeAssignCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}`, data.update)
        .then((result) => {
          let resultData = result.data;
          resultData.nicknames = [];

          if (data.update.nickname) {
            resultData.nicknames.push({ nickname: data.update.nickname });
          }

          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  storeAssignCompanyData(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}/data`, data.update)
        .then((result) => {
          let resultData = result.data;
          resultData.nicknames = [];

          if (data.update.nickname) {
            resultData.nicknames.push({ nickname: data.update.nickname });
          }

          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  storeAssignCompanyContact(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}/contacts`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  storeAssignCompanyStatements(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}/statements`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  storeAssignCompanyExceptions(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}/exceptions`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  storeAssignCompanyNotes(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${data.id}/notes`, data.update)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateStatusCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/company/change-status/${data.id}`, data)
        .then((response) => {
          commit("changeStatus", data.id);
          resolve(response);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getListCompany({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/${companyId}`)
        .then((result) => {
          commit("setCompaniesbyBiling", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getPatients(_, billingCompany) {
    return new Promise((resolve, reject) => {
      if (billingCompany) {
        axios
          .get(`patient/get-list?billing_company_id=${billingCompany}`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => reject(error));
      } else {
        axios
          .get(`patient/get-list`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => reject(error));
      }
    });
  },
  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company")
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesByUnit({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company")
        .then((result) => {
          commit("setBillingCompaniesUnits", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesByCompanies({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/get-list-billing-companies?company_id=${payload.companyId}&edit=${payload.edit}`
        )
        .then((result) => {
          commit("setBillingCompaniesByCompany", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getMeasurement({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-list-measurement-units")
        .then((result) => {
          commit("setMeasurementUnits", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getFacilityByCompanyId(_, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/facility/get-all-by-company/${companyId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getFacilities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/facility")
        .then((result) => {
          commit("setFacilities", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getFacilitiesByBCAndCompany(_, data) {
    const url = data.billingCompany
      ? `/facility/get-list?billing_company_id=${data.billingCompany}&company_id=${data.company}`
      : `/facility/get-list?company_id=${data.company}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getClaimsFormat({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-list-type-formats`)
        .then((result) => {
          commit("setClaimsFormat", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getListBillingP({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `/company/get-list-billing-providers?billing_company_id=${data.billing_company_id}&company_id=${data.company_id}`;
      axios
        .get(url)
        .then((result) => {
          commit("setBillingProvider", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getListBillingPHealthP({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `/company/get-list-billing-providers?billing_company_id=${data.billing_company_id}&company_id=${data.company_id}&all_health_professional=true`;
      axios
        .get(url)
        .then((result) => {
          commit("setBillingProviderHP", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  addServiceToCompany(_, data, services) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/company/add-services-to-company/${data.company_id}`, {
          services: services,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addFacilityToCompany(_, data, facilities) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/company/add-facilities-to-company/${data}`, {
          facilities: facilities,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  removeFacilityToCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/facility/${data.facilityId}/remove-to-company/${data.companyId}`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },
  setPermissionType({ commit }, role) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };
    if (role === 1) {
      permissionType.isSuperUser = true;
    }

    if (role === 2) {
      permissionType.isBillingManager = true;
    }

    commit("setPermissionType", permissionType);
  },
  getAllCompanies(_, data) {
    let query = data.query ? data.query : "";

    const options = {
      params: {
        query: query,
        itemsPerPage: data.itemsPerPage,
        sortDesc: data.options.sortDesc,
        sortBy: data.options.sortBy,
        page: data.options.page,
        ...data.searchOn,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/company/get-all-server", options)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getBillingCompaniesByCompanyName(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/billing-company/get-all-server?query&itemsPerPage=${payload.itemsPerPage}&page=${payload.page}&sortBy=${payload.sortBy}&filter=companies="${payload.companyName}"`
        )
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  updateUnit(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/unit/${data.id}`, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveUnit(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/unit`, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const state = () => ({
  billingCompanies: [],
  pivoteRequest: null,
});

const mutations = {
  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  storeSubStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim-sub-status", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  searchSubStatusByName(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-sub-status/get-by-name/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getSubStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim-sub-status/get-all-server", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getSubStatusById(_, subStatusId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-sub-status/${subStatusId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getBillingCompany({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  changeStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim-sub-status/change-status/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getRecords(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/audit-all-by-entity/claim-sub-status/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  updateSubStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/claim-sub-status/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const state = () => ({
  items: [],
  billingCompanies: [],
  billingCompaniesList: [],
  currentDoctor: {},
  currentHealth: {},
  doctorAutorization: [],
  professionals: [],
  pagination: [],
  billingProvider: [],
  companies: [],
  companiesBillingC: [],
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },
  pivoteRequest: null,
});

const mutations = {
  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = [];
    if (billingCompanies.length > 0) {
      billingCompanies.map((bill) => {
        state.billingCompanies.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setBillingCompaniesByHealth(state, listBillingCompanies) {
    state.listBillingCompaniesList = [];
    if (listBillingCompanies.length > 0) {
      listBillingCompanies.map((bill) => {
        state.billingCompaniesList.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },

  setProfessionals(state, professionals) {
    state.professionals = professionals;
  },
  setDoctorAutorizations(state, doctorAutorization) {
    state.doctorAutorization = doctorAutorization;
  },
  /**
   * @function setDoctors
   * @description Set a doctors.
   * @param {Array} data Doctors.
   */
  setHealths(state, data) {
    state.items = data;
  },
  setHealthsPagination(state, data) {
    state.pagination = data;
  },
  setCurrentHealth(state, health) {
    state.currentHealth = health;
  },
  refreshDoctors(state, doctor) {
    state.items.map((item) => {
      if (item.id === doctor.id) {
        item.user.firstName = doctor.user.firstName;
        item.user.lastName = doctor.user.lastName;
        item.user.profile.ssn = doctor.user.profile.ssn;
        item.user.email = doctor.user.email;
      }

      return item;
    });
  },
  setBillingProvider(state, billingProvider) {
    state.billingProvider = [];
    state.billingProvider = billingProvider;
  },

  /**
   * @function updateStatusHealth
   * @description Update status a health professional.
   * @param {Number} data health professional ID.
   */
  updateStatusHealth(state, data) {
    state.items.map((item) => {
      if (item.id === data) {
        item.status = !item.status;
      }

      return item;
    });
  },
  setCompaniesBillingC(state, companies) {
    state.companiesBillingC = [];
    if (companies.length > 0) {
      companies.map((bill) => {
        state.companiesBillingC.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setCompanies(state, companies) {
    state.companies = [];
    if (companies.length > 0) {
      companies.map((bill) => {
        state.companies.push({
          id: bill.id,
          name: bill.abbreviation
            ? `${bill.abbreviation} - ${bill.name}`
            : bill.name,
        });
      });
    }
  },
  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  /**
   * @function storeHealth
   * @description Petition for create a Health Professional.
   * @param {Object} data Data for petition.
   */
  storeHealth(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/health-professional", data)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          console.log("ERR", error);
          reject(error.response.data);
        });
    });
  },
  /**
   * @function storeupdateHealth
   * @description Petition for update a Health Professional.
   * @param {Object} data Data for petition.
   */

  storeUpdateHealth(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/health-professional/${data.id}`, data.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getAllHealth({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/health-professional")
        .then((result) => {
          commit("setHealths", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getListHPByCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `/health-professional/get-list?billing_company_id=${data.billing_company_id}&company_id=${data.company_id}`;
      axios
        .get(url)
        .then((result) => {
          commit("setBillingProvider", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getCompaniesProviders(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/health-professional/${data}/company`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  storeCompaniesProviders(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/health-professional/${data.doctor}/company`, {
          companies: data.companies,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  /**
   * @function getHealthById
   * @description Petition for get one Health Professional.
   */
  getHealthById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/health-professional/${data}`)
        .then((result) => {
          commit("setCurrentHealth", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  /**
   * @function getBillingCompanies
   * @description Petition for get all billing companies.
   */
  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/billing-company")
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesByHealth({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/health-professional/get-list-billing-companies?health_professional_id=${payload.healthId}&edit=${payload.edit}`
        )
        .then((result) => {
          commit("setBillingCompaniesByHealth", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/health-professional/get-list-billing-companies`)
        .then((result) => {
          commit("setBillingCompaniesByHealth", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getListProfessionalTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/health-professional/get-list-health-professional-types")
        .then((result) => {
          commit("setProfessionals", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getListDoctorAutorization({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/health-professional/get-list-authorizations")
        .then((result) => {
          commit("setDoctorAutorizations", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getNpiByHealthProfessional(_, npi) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/health-professional/${npi}/get-by-npi`)

        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  },
  updateCompaniesProvider(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/health-professional/${data.id}/update-companies`, data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },
  getCompanyByBillingCompanyID({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/${billingCompanyId}`)
        .then((result) => {
          commit("setCompaniesBillingC", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getCompanyByBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/`)
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getAllHealths(_, data) {
    let query = data.query ? data.query : "";

    const options = {
      params: {
        query: query,
        itemsPerPage: data.itemsPerPage,
        sortDesc: data.sortDesc,
        sortBy: data.sortBy,
        page: data.page,
        ...data.searchOn,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/health-professional/get-all-server", options)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  /**
   * @function updateStatus
   * @description Petition for update status  to one health professional.
   * @param {Object} data Data for petition.
   */
  updateStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/health-professional/${data.id}/change-status`, data.update)
        .then((result) => {
          commit("updateStatusHealth", data.id);
          resolve(result);
        })
        .catch((error) => reject(error.response.data));
    });
  },
  setPermissionType({ commit }, roles) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };

    if (roles) {
      roles.find((role) => {
        if (role.name === "Super User") {
          permissionType.isSuperUser = true;
        }

        if (role.name === "Billing Manager") {
          permissionType.isBillingManager = true;
        }
      });
    }

    commit("setPermissionType", permissionType);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

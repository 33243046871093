import axios from "@/plugins/axios";
import { filterCode, orderAttributes } from "@/utils/tablesClaimRules.adapter";

const state = () => ({
  billingCompanies: [],
  rulesProfessional: {},
  rulesInstitutional: {},
  rulesGenerals: [],
  pivoteRequest: null,
});

const mutations = {
  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
    state.listBillingCompanies = billingCompanies.map((billing) => {
      billing.name = `${billing.abbreviation} - ${billing.name}`;
      return billing;
    });
  },

  setListRules(state, listRules) {
    state.rulesProfessional.file = filterCode(listRules.professional.file);
    state.rulesProfessional.json = filterCode(listRules.professional.json);

    state.rulesInstitutional.file = filterCode(listRules.institutional.file);
    state.rulesInstitutional.json = filterCode(listRules.institutional.json);

    state.rulesGenerals = listRules.generals;

    state.rulesProfessional.file.forEach((fi) => {
      orderAttributes(fi);
    });

    state.rulesProfessional.json.forEach((js) => {
      orderAttributes(js);
    });

    state.rulesInstitutional.file.forEach((fi) => {
      orderAttributes(fi);
    });

    state.rulesInstitutional.json.forEach((js) => {
      orderAttributes(js);
    });
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  getAllClaimRules(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/rules", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getClaimRule(_, claimRuleId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/rules/${claimRuleId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getListRules({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/rules/list`)
        .then((result) => {
          commit("setListRules", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing-company`)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getPreviewClaimRules(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/rules/show-preview/${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  saveRules(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/claim/rules", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateRules(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/claim/rules/${payload.id}`, payload.update)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  deleteRules(_, rule) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/claim/rules/${rule}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
